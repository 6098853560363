import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Col,
  Row,
  Label,
} from "reactstrap";

import DeleteConfirmModal from "../components/DeleteConfirmModal";
import moment from "moment";

class LoginRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      toggleSort: "desc",
      sort: "",
      deleteModal: false,
      dailyRecordDate: "",
      startDate: "",
      endDate: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sortTable = this.sortTable.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.onGetDailyRecordByDateRange =
      this.onGetDailyRecordByDateRange.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDateChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  toggleDeleteModal(index) {
    this.props.setCurrentLoginRecord(index);
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  }
  onDelete() {
    const { deleteLoginRecord, currentLoginRecord } = this.props;
    if (!currentLoginRecord) return;
    deleteLoginRecord(currentLoginRecord);
  }

  onSearch(e) {
    e.preventDefault();
    const { searchLoginRecords } = this.props;
    const { search } = this.state;
    let data = {
      search: search,
    };
    searchLoginRecords(data);
  }
  onGetDailyRecordByDate(e) {
    const { dailyRecordByDate } = this.state;
    e.preventDefault();
    this.props.onGetDailyRecordByDate({ dailyRecordByDate });
  }

  onGetDailyRecordByDateRange(e) {
    e.preventDefault();
    const { startDate, endDate } = this.state;
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    if (startDateMoment.isValid() && endDateMoment.isValid()) {
      this.props.getDailyRecordByDateRange({ startDate, endDate });
    }
  }

  sortTable(name) {
    const { sortLoginRecords } = this.props;
    const { toggleSort, search } = this.state;

    this.setState({
      toggleSort: toggleSort == "asc" ? "desc" : "asc",
      sort: name,
    });

    const order = toggleSort == "asc" ? "desc" : "asc";

    let data = {
      sort: name,
      order: order,
      search: search,
    };

    sortLoginRecords(data);
  }
  renderDailyRecordByDate() {
    const { dailyRecordByDate } = this.props;
    if (!dailyRecordByDate || !this.state.dailyRecordDate) return null;
    const averageMinutesPerUser =
      dailyRecordByDate.total_time_minutes / dailyRecordByDate.total_users;
    return (
      <div>
        <h6>
          Total Minutes of Usage:{" "}
          {dailyRecordByDate.total_time_minutes
            ? Math.trunc(dailyRecordByDate.total_time_minutes)
            : 0}
        </h6>
        <h6>
          Total Users Recorded:{" "}
          {dailyRecordByDate.total_users
            ? Math.trunc(dailyRecordByDate.total_users)
            : 0}
        </h6>
        <h6>
          Average minutes per user:{" "}
          {averageMinutesPerUser ? Math.trunc(averageMinutesPerUser) : 0}
        </h6>
      </div>
    );
  }

  renderDateRangeInformation() {
    const {
      dateRangeAverageDailyMinutes,
      dateRangeAverageDailyUsers,
      dateRangeList,
    } = this.props;

    if (_.isNil(dateRangeList)) return null;
    if (
      _.isNil(dateRangeAverageDailyMinutes) ||
      _.isNil(dateRangeAverageDailyUsers)
    )
      return (
        <div>
          <h7 style={{ color: "red" }}>
            No data found for selected date range
          </h7>
        </div>
      );
    let averagePerUser =
      dateRangeAverageDailyUsers && dateRangeAverageDailyUsers != 0
        ? Math.trunc(dateRangeAverageDailyMinutes / dateRangeAverageDailyUsers)
        : 0;
    if (averagePerUser > 1440) averagePerUser = 1440;
    return (
      <div>
        <h6>
          Average Daily Users:{" "}
          {dateRangeAverageDailyUsers
            ? Math.trunc(dateRangeAverageDailyUsers)
            : 0}
        </h6>
        <h6>
          Average Daily Time of Usage (All users):{" "}
          {dateRangeAverageDailyMinutes
            ? `${Math.trunc(dateRangeAverageDailyMinutes / 60)}h ${Math.trunc(
                dateRangeAverageDailyMinutes % 60
              )}m`
            : 0}
        </h6>
        <h6>
          Average Daily Time per user:{" "}
          {`${Math.trunc(averagePerUser / 60)}h ${Math.trunc(
            averagePerUser % 60
          )}m`}
        </h6>
      </div>
    );
  }

  renderPagination() {
    const { pagination, getLoginRecords } = this.props;
    const { search, sort, toggleSort } = this.state;
    if (!pagination) return null;
    const { page_number, total_pages } = pagination;
    let paginationItems = [];
    if (total_pages > 4)
      paginationItems.push(
        <PaginationItem key={"first"} active={false}>
          <PaginationLink
            first={true}
            onClick={() =>
              getLoginRecords({
                page: 1,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {"First"}
          </PaginationLink>
        </PaginationItem>
      );
    let end = page_number + 3 >= total_pages ? total_pages : page_number + 3;
    for (let i = page_number - 3 <= 1 ? 1 : page_number - 3; i <= end; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={page_number == i}>
          <PaginationLink
            onClick={() =>
              getLoginRecords({
                page: i,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    if (total_pages > 4)
      paginationItems.push(
        <PaginationItem key={"last"} active={false}>
          <PaginationLink
            last={true}
            onClick={() =>
              getLoginRecords({
                page: total_pages,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {"Last"}
          </PaginationLink>
        </PaginationItem>
      );
    return <Pagination>{paginationItems}</Pagination>;
  }
  render() {
    const {
      loginRecords,
      totalLoginRecords,
      className,
      averageDailyUsers,
      averageDailyMinutes,
      averageMonthlyUsers,
      averageMonthlyMinutes,
    } = this.props;
    const { deleteModal } = this.state;
    const averagePerUser =
      averageDailyUsers && averageDailyUsers != 0
        ? Math.trunc(averageDailyMinutes / averageDailyUsers)
        : 0;
    const averageMonthlyPerUser =
      averageMonthlyUsers && averageMonthlyUsers != 0
        ? Math.trunc(averageMonthlyMinutes / averageMonthlyUsers)
        : 0;

    return (
      <div className="animated fadeIn">
        <DeleteConfirmModal
          className={className}
          onDelete={this.onDelete}
          isOpen={deleteModal}
          toggle={this.toggleDeleteModal}
          name="Login Record"
        />

        <Card>
          <CardHeader>
            <h5>Login Records</h5>
          </CardHeader>

          <CardBody className="card-body">
            <Form onSubmit={this.onSearch}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        id="search"
                        value={this.state.search}
                        name="search"
                        placeholder="Search"
                        onChange={this.handleChange}
                      />
                      <Button color="disabled" onClick={this.onSearch}>
                        <i className="icon-magnifier"></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <h5>All time statistics:</h5>
            <h6>
              Login Records Total Count:{" "}
              {totalLoginRecords ? totalLoginRecords : 0}
            </h6>
            <h6>
              Average Daily Users:{" "}
              {averageDailyUsers ? Math.trunc(averageDailyUsers) : "---"}
            </h6>
            <h6>
              Average Daily Time of Usage (All Users):{" "}
              {averageDailyMinutes
                ? `${Math.trunc(averageDailyMinutes / 60)}h ${Math.trunc(
                    averageDailyMinutes % 60
                  )}m`
                : "---"}
            </h6>
            <h6>
              Average Daily Time per user:{" "}
              {`${Math.trunc(averagePerUser / 60)}h ${Math.trunc(
                averagePerUser % 60
              )}m`}
            </h6>

            <h5>Last 30 days statistics</h5>
            <h6>
              Average Monthly Users:{" "}
              {averageMonthlyUsers ? Math.trunc(averageMonthlyUsers) : "---"}
            </h6>
            <h6>
              Average Monthly Time of Usage (All Users):{" "}
              {averageMonthlyMinutes
                ? `${Math.trunc(averageMonthlyMinutes / 60)}h ${Math.trunc(
                    averageMonthlyMinutes % 60
                  )}m`
                : "---"}
            </h6>
            <h6>
              Average Monthly Time per user:{" "}
              {`${Math.trunc(averageMonthlyPerUser / 60)}h ${Math.trunc(
                averageMonthlyPerUser % 60
              )}m`}
            </h6>
            <br></br>
            <h5>Specific Date statistics: </h5>

            <Form onSubmit={this.onGetDailyRecordByDateRange}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="startDate">Start Date:</Label>

                    <InputGroup>
                      <Input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={this.state.startDate}
                        onChange={this.handleDateChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="endDate">End Date:</Label>

                    <InputGroup>
                      <Input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={this.state.endDate}
                        onChange={this.handleDateChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <FormGroup>
                  <Button
                    color="primary"
                    onClick={this.onGetDailyRecordByDateRange}
                  >
                    Get From Date Range
                  </Button>
                </FormGroup>
              </Row>
            </Form>
            {this.renderDateRangeInformation()}

            <Table responsive striped>
              <thead className="no-top-border">
                <tr>
                  <th
                    name="inserted_at"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("inserted_at")}
                  >
                    Timestamp
                  </th>
                  <th
                    name="email"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("email")}
                  >
                    Email
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loginRecords.map((loginRecord, i) => (
                  <tr key={loginRecord.id}>
                    <td>{loginRecord.inserted_at}</td>
                    <td>{loginRecord.email}</td>
                    <td className="actions list-actions">
                      <i
                        className="icon-trash"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.toggleDeleteModal(i)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {this.renderPagination()}
          </CardBody>
        </Card>
      </div>
    );
  }
}

LoginRecords.propTypes = {
  className: PropTypes.string,
  getLoginRecords: PropTypes.func,
  loginRecords: PropTypes.array,
  pagination: PropTypes.array,
  page: PropTypes.number,
  currentLoginRecord: PropTypes.object,
};

export default LoginRecords;
