import { connect } from "react-redux";
import Licenses from "../views/Licenses";
import { actions } from "../modules/licenses";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return {
    licenses: _.get(state, "licenses.list", []),
    pagination: _.get(state, "licenses.pagination", []),
    currentLicense: _.get(state, "licenses.current", null),
    usedLicenses: _.get(state, "licenses.usedLicenses", null),
    subscriptionList: _.get(state, "licenses.subscriptionList", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLicenses(payload) {
      dispatch(actions.list_request(payload));
    },
    setLicense(index) {
      dispatch(actions.set_license(index));
    },
    saveLicense(data) {
      data = _(data).omit(_.isUndefined).omit(_.isNull).value();
      let f;
      if (data.key) {
        f = actions.update_request;
      } else {
        f = actions.create_request;
      }
      dispatch(f(data));
    },
    deleteLicense(data) {
      dispatch(actions.delete_request(data));
    },
    bulkAddLicenses(data) {
      dispatch(actions.bulk_add_request(data));
    },
    bulkAddLicensesCsv(data) {
      dispatch(actions.bulk_add_csv_request(data));
    },
    downloadBatchCsv(data) {
      dispatch(actions.download_csv_request(data));
    },
    searchLicenses(data) {
      dispatch(actions.list_request(data));
    },
    sortLicenses(data) {
      dispatch(actions.list_request(data));
    },
    list_subscriptions(payload) {
      dispatch(actions.list_subscriptions_request(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Licenses);
