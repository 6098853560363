import { connect } from "react-redux";
import NewPassword from "../views/pages/NewPassword";
import { actions } from "../modules/auth";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return state.auth || {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword(payload) {
      dispatch(actions.change_password_request(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
