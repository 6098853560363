import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { NavLink } from "react-router-dom";

class Page404 extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <NavLink to={"/"}>
            <img
              width="100"
              className="login-logo"
              src="img/elite-lab-icon.svg"
              alt="Cirrus Logo"
            />
          </NavLink>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4">404</h1>
                <h4 className="pt-3">Oops! You're lost.</h4>
                <p className="text-muted float-left">
                  The page you are looking for was not found. Click on the
                  button below to redirect you to our Home Page
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <NavLink to={"/"}>
              <Button color="primary" className="mt-3" active>
                Home
              </Button>
            </NavLink>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page404;
