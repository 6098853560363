import { connect } from "react-redux";
import _ from "lodash";

import { getColor } from "../tools";
import Dashboard from "../views/Dashboard";
import * as actions from "../actions";
import { actions as meterActions, defaultMeter } from "../modules/meters";
import { actions as deviceActions } from "../modules/devices";
import { actions as logActions } from "../modules/logs";

import { actions as dashboardActions } from "../modules/dashboards";

/* NOT USED FOR NOW

import { actions as multilogActions } from '../modules/multilogs'
import { actions as bluetoothActions } from '../modules/bluetooth'

*/

import debounceRender from "react-debounce-render";

const mapStateToProps = (state = {}, otherProps = {}) => {
  let { name = "Dashboard" } = otherProps;

  const dashboards = _.get(state, "dashboards", {});
  let dashboard;
  if (_.size(dashboards) == 1) {
    dashboard = dashboards[_.keys(dashboards)[0]];
  } else {
    dashboard = _.find(dashboards, (d) => d.name == name) || {};
  }

  name = dashboard.name;
  const ramps = state.ramps || {};
  let deviceIndex = 0;
  const devicesIds = new Set();
  const meters = _.reduce(
    state.devices || {},
    (a, device, serialId) => {
      deviceIndex++;
      if (device.status != "active") return a;
      devicesIds.add(serialId);
      return _.reduce(
        device.meters || [],
        (acc, m, i) => {
          const rampName = _.get(ramps, [m.running_ramp, "name"]);
          const key = `${serialId}-${m.address}`;
          const meterColor = deviceIndex * (i + 1);
          const config = _.get(state, ["meters", key], {
            ...defaultMeter,
            color: getColor(meterColor).rgb().string(),
          });
          if (!config.color) {
            config.color = getColor(meterColor).rgb().string();
          }

          let active = config.activeDashboards && config.activeDashboards[name];
          active = _.isUndefined(active) ? true : active;
          const meter = {
            ...m,
            key,
            device,
            serialId,
            logData: _.get(state, ["log", key], {}),
            ...config,
            active,
            runningRampName: rampName,
          };
          return [...acc, meter];
        },
        a
      );
    },
    []
  );

  const sorted = _.chain(meters)
    .orderBy("model")
    .orderBy("key")
    .orderBy("units")
    .value();
  const loggingId = _.get(state, "multilogs.loggingId", null);

  return {
    dashboard,
    name,
    devicesIds: [...devicesIds],
    meters: sorted,
    loggingId,
    loggingLog: _.get(state, ["multilogs", "byId", loggingId], {
      id: loggingId,
    }),
    bluetooth: state.bluetooth || {},
    isTrial: _.get(state, "config.subscriptionName") === "Trial",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeColor: (payload) => {
      dispatch(meterActions.set_color(payload));
    },
    toggleMeter: (payload) => {
      dispatch(meterActions.toggle_active(payload));
    },
    toggleMeterCollapse: (payload) => {
      dispatch(meterActions.toggle_collapse(payload));
    },
    tick(payload) {
      dispatch(logActions.tick(payload));
    },
    onUpdateDevice: (payload) => {
      dispatch(actions.devices.single_request(payload));
    },
    onAddDashboard: (payload) => {
      dispatch(dashboardActions.add(payload));
    },
    onChangeNumGraphs: (payload) => {
      dispatch(dashboardActions.num_graphs(payload));
    },
    onUpdateConfig: (payload) => {
      dispatch(dashboardActions.update_graph_config(payload));
    },
    onToggleGraphMeter: (payload) => {
      dispatch(dashboardActions.toggle_graph_meter(payload));
    },
    onResync: (payload) => {
      dispatch(actions.devices.resync_device_request(payload));
    },
    refreshDevices() {
      dispatch(deviceActions.list_request());
    },
    listDevices() {
      dispatch(deviceActions.list_request());
    },
    /* NOT USED FOR NOW

    startLog: payload => {
      dispatch(multilogActions.start_request(payload))
    },
    stopLog: payload => {
      dispatch(multilogActions.stop_request(payload))
    },
    onAddComment: payload => {
      dispatch(multilogActions.add_comment_request(payload))
    },
    resyncBluetooth() {
      dispatch(bluetoothActions.resync_request())
    },

    */
  };
};

const debouncedDashboard = debounceRender(Dashboard, 250, {
  leading: true,
  trailing: true,
  maxWait: 250,
});
export default connect(mapStateToProps, mapDispatchToProps)(debouncedDashboard);
