import { takeEvery, put, call, select } from "redux-saga/effects";
import _ from "lodash";

import { createAction, createReducer, actionNames } from "../tools";
import { moduleLoadReducers, moduleSave } from "./configuration";
import api from "../api";

export const NAME = "meters";

export const actions = createAction(
  "cirrus/module/meters",
  "SET_COLOR",
  "TOGGLE_ACTIVE",
  "SELECT_RAMP",
  "TOGGLE_COLLAPSE",
  ...actionNames("CURRENT_VALUE")
);

function getKey(action) {
  const { serialId, meterId } = action.payload;
  return `${serialId}-${meterId}`;
}

export const defaultMeter = {};

export default createReducer(
  {
    ...moduleLoadReducers(NAME),
    [actions.SET_COLOR]: (state, action) => {
      const { value } = action.payload;
      const key = getKey(action);
      let meter = state[key] || defaultMeter;
      meter = { ...meter, color: value };
      return {
        ...state,
        [key]: meter,
      };
    },
    [actions.TOGGLE_ACTIVE]: (state, action) => {
      const key = getKey(action);
      let meter = state[key] || defaultMeter;
      const { name } = action.payload;
      const activeDashboards = meter.activeDashboards || { [name]: true };
      const active = !activeDashboards[name];
      meter = {
        ...meter,
        activeDashboards: { ...activeDashboards, [name]: active },
      };
      return {
        ...state,
        [key]: meter,
      };
    },
    [actions.TOGGLE_COLLAPSE]: (state, action) => {
      const key = getKey(action);
      let meter = state[key] || defaultMeter;
      let collapse = !meter.collapse;
      meter = { ...meter, collapse };
      return {
        ...state,
        [key]: meter,
      };
    },
    [actions.SELECT_RAMP]: (state, action) => {
      const key = getKey(action);
      let meter = state[key] || defaultMeter;
      const { value } = action.payload;
      meter = { ...meter, selectedRamp: parseInt(value, 10) };
      return {
        ...state,
        [key]: meter,
      };
    },
    [actions.CURRENT_VALUE_SUCCESS]: (state, action) => {
      const key = getKey(action);
      let meter = state[key] || defaultMeter;
      if (!meter) return state;
      const { result } = action.payload;

      meter = { ...meter, currentValue: result };
      return {
        ...state,
        [key]: meter,
      };
    },
  },
  {}
);

function* getCurrentValue({ payload }) {
  const { serialId } = payload;
  const syncing = yield select((s) =>
    _.get(s, ["devices", serialId, "syncing"])
  );
  const status = yield select((s) => _.get(s, ["devices", serialId, "status"]));
  if (syncing) {
    return;
  }

  try {
    const value = yield call(api.devices.getCurrentValue, payload);

    if (_.isNull(value)) {
      return;
    }

    yield put(actions.current_value_success({ ...payload, ...value }));
  } catch (e) {
    yield put(actions.current_value_error(e));
  }
}

const names = [
  actions.SET_COLOR,
  actions.TOGGLE_ACTIVE,
  actions.TOGGLE_COLLAPSE,
];

const save = moduleSave(NAME, 1000);

export function* mainSaga() {
  yield takeEvery(names, save);
  yield takeEvery(actions.CURRENT_VALUE_REQUEST, getCurrentValue);
}
