import { takeEvery, put, call } from "redux-saga/effects";
import { createAction, createReducer, actionNames } from "../tools";
import api from "../api";
import _ from "lodash";

export const actions = createAction(
  "cirrus/modules/debugLogs",
  ...actionNames("LIST"),
  ...actionNames("DELETE"),
  ...actionNames("LIST_SUBSCRIPTIONS"),
  ...actionNames("DOWNLOAD")
);

const defaultState = {
  list: [],
  params: {
    page: 1,
    per_page: 50,
  },
  current: null,
};

export default createReducer(
  {
    [actions.LIST_SUCCESS]: (state, action) => {
      const { pagination, data } = action.payload;
      return {
        ...state,
        list: data,
        pagination,
      };
    },
    [actions.DELETE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        current: { ...payload, open: false },
      };
    },
  },
  defaultState
);

function* deleteDebugLog({ payload }) {
  try {
    const { data } = yield call(api.debugLogs.delete, payload);
    yield put(actions.list_request());
    yield put(actions.delete_success(data));
  } catch (e) {
    yield put(actions.delete_error(e));
  }
}

function* listDebugLogs({ payload }) {
  try {
    const data = yield call(api.debugLogs.all, payload);
    yield put(actions.list_success(data));
  } catch (e) {
    yield put(actions.list_error(e));
  }
}

function* downloadDebugLog({ payload }) {
  try {
    const data = yield call(api.debugLogs.download, payload);
    yield put(actions.download_success(data));
  } catch (e) {
    yield put(actions.download_error(e));
  }
}

export const routes = {
  *"/debug-logs"() {
    yield put(actions.list_request());
  },
};

export function* mainSaga() {
  yield takeEvery(actions.LIST_REQUEST, listDebugLogs);
  yield takeEvery(actions.DELETE_REQUEST, deleteDebugLog);
  yield takeEvery(actions.DOWNLOAD_REQUEST, downloadDebugLog);
}
