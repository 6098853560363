import { delay } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";

import { createAction, createReducer } from "../tools";

import * as allActions from "../actions";

export const actions = createAction(
  "cirrus/modules/alerts",
  "ERROR",
  "SUCCESS",
  "WARNING",
  "ACK",
  "AUTO_ACK"
);

function newAlert(color, state, action) {
  const { message } = action.payload;
  let index = _.findIndex(state, (a) => a.message == message);
  if (index >= 0) {
    const alertFound = state[index];
    const alertToAdd = { ...alertFound, count: alertFound.count + 1 };
    return [...state.slice(0, index), alertToAdd, ...state.slice(index + 1)];
  }
  const alert = { color, message, count: 1 };
  return [alert, ...state];
}

export default createReducer(
  {
    [actions.ERROR]: newAlert.bind(null, "danger"),
    [allActions.ERROR]: newAlert.bind(null, "danger"),
    [actions.SUCCESS]: newAlert.bind(null, "success"),
    [actions.WARNING]: newAlert.bind(null, "warning"),
    [actions.ACK]: (state, action) => {
      const { index } = action.payload;
      return [...state.slice(0, index), ...state.slice(index + 1)];
    },
    [actions.AUTO_ACK]: (state, action) => {
      const { message } = action.payload;
      const index = _.findLastIndex(state, (a) => a.message == message);
      if (index < 0) return state;

      return [...state.slice(0, index), ...state.slice(index + 1)];
    },
  },
  []
);

const types = [
  actions.ERROR,
  allActions.ERROR,
  actions.SUCCESS,
  actions.WARNING,
];

function* closeAfter10s({ payload }) {
  yield call(delay, (payload.time || 20) * 1000);
  yield put(actions.auto_ack(payload));
}

export function* mainSaga() {
  yield takeEvery(types, closeAfter10s);
}
