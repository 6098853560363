import { takeEvery, put, call } from "redux-saga/effects";
import { createAction, createReducer, actionNames } from "../tools";
import api from "../api";

export const actions = createAction(
  "cirrus/modules/users",
  ...actionNames("LIST"),
  ...actionNames("ME"),
  "UPDATE_ME",
  "SET_USER",
  ...actionNames("CREATE"),
  ...actionNames("UPDATE"),
  ...actionNames("DELETE"),
  ...actionNames("DOWNLOAD_CSV")
);

const defaultState = {
  list: [],
  me: {},
  page: 1,
  current: null,
};

export default createReducer(
  {
    [actions.LIST_SUCCESS]: (state, action) => {
      const { pagination, data } = action.payload;
      const list = data.map((u) => ({
        ...u,
        inserted_at: u.inserted_at.split("T")[0],
      }));
      return {
        ...state,
        list,
        pagination,
      };
    },
    [actions.ME_SUCCESS]: (state, action) => {
      return {
        ...state,
        me: action.payload,
      };
    },
    [actions.SET_USER]: (state, action) => {
      const index = action.payload;
      let current = state.list[index] || null;
      if (current) {
        current = { ...current, open: true };
      }
      return {
        ...state,
        current,
      };
    },
    [actions.CREATE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        current: { ...payload, open: false },
      };
    },
    [actions.DELETE_SUCCESS]: (state, action) => {
      return {
        ...state,
      };
    },
    [actions.UPDATE_SUCCESS]: (state, action) => {
      const user = action.payload;
      const id = user.id;
      let { list } = state;
      user.inserted_at = user.inserted_at.split("T")[0];

      const index = _.findIndex(list, (l) => l.id == id);
      const newList = [
        ..._.slice(list, 0, index),
        user,
        ..._.slice(list, index + 1),
      ];
      list = newList;
      return {
        ...state,
        list,
        current: { ...user, open: false },
      };
    },
  },
  defaultState
);

function* create_user({ payload }) {
  try {
    const { data } = yield call(api.users.create, payload);
    yield put(actions.create_success(data));
  } catch (e) {
    yield put(actions.create_error(e));
  }
}

function* update_user({ payload }) {
  try {
    const { data } = yield call(api.users.update, payload);
    yield put(actions.update_success(data));
  } catch (e) {
    yield put(actions.update_error(e));
  }
}

function* delete_user({ payload }) {
  try {
    const { data } = yield call(api.users.delete, payload);
    yield put(actions.list_request());
    yield put(actions.delete_success(data));
  } catch (e) {
    yield put(actions.delete_error(e));
  }
}

function* me() {
  try {
    const { user } = yield call(api.users.me);
    yield put(actions.me_success(user));
  } catch (e) {
    yield put(actions.me_error(e));
  }
}

function* update_me({ payload }) {
  try {
    const { data } = yield call(api.users.update_me, payload);
    yield put(actions.me_success(data));
  } catch (e) {
    yield put(actions.me_error(e));
  }
}

function* list_users({ payload }) {
  try {
    const data = yield call(api.users.all, payload);
    yield put(actions.list_success(data));
  } catch (e) {
    yield put(actions.list_error(e));
  }
}

function* download_csv({ payload }) {
  try {
    yield call(api.users.downloadCsv);
    yield put(actions.download_csv_success());
  } catch (e) {
    yield put(actions.download_csv_error(e));
  }
}

export const routes = {
  *"/users"() {
    yield put(actions.list_request());
  },
};

export function* mainSaga() {
  yield takeEvery(actions.LIST_REQUEST, list_users);
  yield takeEvery(actions.ME, me);
  yield takeEvery(actions.UPDATE_ME, update_me);
  yield takeEvery(actions.CREATE_REQUEST, create_user);
  yield takeEvery(actions.UPDATE_REQUEST, update_user);
  yield takeEvery(actions.DELETE_REQUEST, delete_user);
  yield takeEvery(actions.DOWNLOAD_CSV_REQUEST, download_csv);
}
