import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer">
        <span>
          <a href="https://labsociety.com/elitelab/">EliteLab®</a> &copy; 2021
        </span>
        <span className="ml-auto powered-by-subtext">
          powered by{" "}
          <a href="https://labsociety.com">
            <img
              className="powered-by"
              src="img/lab-society-logo.svg"
              alt="Lab Society"
            />
          </a>
        </span>
      </footer>
    );
  }
}

export default Footer;
