import { connect } from "react-redux";
import Subscriptions from "../views/Subscriptions";
import { actions } from "../modules/subscriptions";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return {
    subscriptions: _.get(state, "subscriptions.list", []),
    currentSubscription: _.get(state, "subscriptions.current", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptions(payload) {
      dispatch(actions.list_request(payload));
    },
    deleteSubscription(data) {
      dispatch(actions.delete_request(data));
    },
    setSubscription(index) {
      dispatch(actions.set_subscription(index));
    },
    saveSubscription(data) {
      data = _(data).omit(_.isUndefined).omit(_.isNull).value();
      let f;
      if (data.id) {
        f = actions.update_request;
      } else {
        f = actions.create_request;
      }
      dispatch(f(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
