import { takeEvery } from "redux-saga/effects";
import _ from "lodash";
import { moduleLoadReducers, moduleSave } from "./configuration";
import {
  createAction,
  createReducer,
  getLogConfig,
  actionNames,
} from "../tools";

let DASH_NUMBER = 0;
let GRAPH_NUMBER = 0;

export const actions = createAction(
  "cirrus/module/dashboards",
  "ADD",
  "REMOVE",
  "NUM_GRAPHS",
  "UPDATE_GRAPH_CONFIG",
  "TOGGLE_GRAPH_METER",
  "MAKE_MAIN_GRAPH",
  ...actionNames("INIT")
);

export function getDefaultDashboard(name) {
  if (DASH_NUMBER == 0) {
    name = name || "Dashboard";
  }

  DASH_NUMBER++;
  const key = Math.random().toString(36).substring(2);
  const graph = getDefaultGraph(key);
  return {
    key,
    name: name || `Dashboard ${DASH_NUMBER}`,
    graphsOrdering: [graph.key],
    [graph.key]: graph,
  };
}

export function getDefaultGraph(dashboardKey) {
  GRAPH_NUMBER++;
  const name = `Graph ${GRAPH_NUMBER}`;
  const key = `_graph_${Math.random().toString(36).substring(2)}`;
  const config = getLogConfig(key);
  return {
    ...config,
    key,
    name,
    dashboardKey,
    metersToDisplay: {},
  };
}

export default createReducer(
  {
    ...moduleLoadReducers("dashboards"),
    [actions.ADD]: (state, action) => {
      const { name } = action.payload || {};

      const dashboard = getDefaultDashboard(name);
      return {
        ...state,
        [dashboard.key]: dashboard,
      };
    },
    [actions.NUM_GRAPHS]: (state, action) => {
      const { numGraphs, key } = action.payload;
      const dashboard = state[key];
      const size = dashboard.graphsOrdering.length;
      if (size == numGraphs) {
        return state;
      }

      if (size > numGraphs) {
        const diff = size - numGraphs;
        const graphsOrdering = _.slice(dashboard.graphsOrdering, diff);
        return {
          ...state,
          [key]: { ...dashboard, graphsOrdering },
        };
      }

      // may be we need to add new graphs or reuse others
      let graphNames = _.chain(dashboard)
        .keys()
        .filter((k) => k.startsWith("_graph_"))
        // .difference(dashboard.graphsOrdering)
        // .slice(0, diff)
        .value();

      const diff = numGraphs - size;
      const availGraphs = _.difference(graphNames, dashboard.graphsOrdering);
      const toReselect = _.slice(availGraphs, 0, diff);
      let graphsOrdering = [...toReselect, ...dashboard.graphsOrdering];
      if (graphsOrdering == numGraphs) {
        return {
          ...state,
          [key]: { ...dashboard, graphsOrdering },
        };
      }

      const newGraphs = {};
      _.range(numGraphs - graphsOrdering.length).reduce((acc) => {
        const graph = getDefaultGraph(key);
        acc[graph.key] = graph;
        return acc;
      }, newGraphs);

      const newNames = _.keys(newGraphs);
      graphsOrdering = [...newNames, ...graphsOrdering];
      return {
        ...state,
        [key]: {
          ...dashboard,
          ...newGraphs,
          graphsOrdering,
        },
      };
    },
    [actions.UPDATE_GRAPH_CONFIG]: (state, action) => {
      const { config } = action.payload || {};
      if (!config) return state;
      const { dashboardKey, key } = config;
      const dashboard = state[dashboardKey] || { key: dashboardKey };
      const oldConfig = dashboard[key] || {
        key,
        dashboardKey,
        metersToDisplay: {},
      };
      return {
        ...state,
        [dashboardKey]: {
          ...dashboard,
          [key]: { ...config, metersToDisplay: oldConfig.metersToDisplay },
        },
      };
    },
    [actions.MAKE_MAIN_GRAPH]: (state, action) => {
      const { dashboardKey, graphKey } = action.payload;
      const dashboard = state[dashboardKey];
      const ordering = dashboard.graphsOrdering;
      const current = _.last(ordering);
      const graphsOrdering = _.map(ordering, (key) => {
        if (key == current) return graphKey;
        if (key == graphKey) return current;
        return key;
      });
      return {
        ...state,
        [dashboardKey]: { ...dashboard, graphsOrdering },
      };
    },
    [actions.TOGGLE_GRAPH_METER]: (state, action) => {
      const { dashboardKey, graphKey, meterKey } = action.payload;
      const dashboard = state[dashboardKey];
      const config = dashboard[graphKey];
      let metersToDisplay = config.metersToDisplay;
      let toDisplay = metersToDisplay[meterKey];
      if (_.isUndefined(toDisplay)) {
        toDisplay = true;
      }

      metersToDisplay = { ...metersToDisplay, [meterKey]: !toDisplay };
      return {
        ...state,
        [dashboardKey]: {
          ...dashboard,
          [graphKey]: {
            ...config,
            metersToDisplay,
          },
        },
      };
    },
  },
  {}
);

const names = [
  actions.ADD,
  actions.REMOVE,
  actions.NUM_GRAPHS,
  actions.UPDATE_GRAPH_CONFIG,
  actions.TOGGLE_GRAPH_METER,
  actions.MAKE_MAIN_GRAPH,
];

const save = moduleSave("dashboards", 1000);

export function* mainSaga() {
  yield takeEvery(names, save);
}
