import { createHashHistory } from "redux-saga-router";
import { takeEvery } from "redux-saga/effects";

import { createAction } from "../tools";

export const history = createHashHistory();

export const actions = createAction("cirrus/modules/history", "PUSH");

export const { PUSH, push } = actions;

function changePath({ payload }) {
  history.push(payload);
}

export function* mainSaga() {
  yield takeEvery(PUSH, changePath);
}
