import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class NoMatch extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Redirect to="/404" />;
  }
}

export default NoMatch;
