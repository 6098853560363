import _ from "lodash";
import { connect } from "react-redux";

import LiveController from "../components/LiveController";
import * as actions from "../actions";
import { defaultMeter, actions as meterActions } from "../modules/meters";
import { actions as deviceActions } from "../modules/devices";

import { getLogKey, getLogConfig } from "../tools";
import debounceRender from "react-debounce-render";

const mapStateToProps = (
  state = {},
  { serialId, meterId = 1, logKey },
  otherProps = {}
) => {
  let { name } = otherProps;
  if (!name) {
    name = "Main Dashboard";
  }
  const meters = _.get(state, ["devices", serialId, "meters"], []);
  const meter = _.find(meters, (m) => m.address == meterId) || {};
  const key = `${serialId}-${meterId}`;
  const meterConfig = _.get(state, ["meters", key], defaultMeter);
  logKey = logKey || getLogKey("dashboard");
  const logConfig = _.get(
    state,
    ["config", logKey],
    getLogConfig({ key: logKey })
  );

  let currentValue = _.get(meterConfig, "currentValue", 0).toFixed(2);
  currentValue = parseFloat(currentValue);

  let active = logConfig.activeDashboards && logConfig.activeDashboards[name];
  active = _.isUndefined(active) ? true : active;

  return {
    device: _.get(state, ["devices", serialId], {}),
    meter,
    serialId,
    meterId,
    logConfig: logConfig || { interval: { value: 1, units: "seconds" } },
    setPoint: _.get(meter, "setPoint.value"),
    powerLimit: _.get(meter, "powerLimit.value", 0),
    currentValue,
    ...meterConfig,
    active,
    features: _.get(meter, "features", {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSetPoint: (serialId, meterId, value) => {
      dispatch(actions.setSetPoint({ serialId, meterId, value }));
    },
    setPowerLimit: (serialId, meterId, value) => {
      dispatch(
        deviceActions.set_power_limit_request({ serialId, meterId, value })
      );
    },
    onGetCurrentValue: (serialId, meterId) => {
      dispatch(meterActions.current_value_request({ serialId, meterId }));
    },
    onGetSetPoint: (payload) => {
      dispatch(actions.requestSetPoint(payload));
    },
    onResync: (payload) => {
      dispatch(deviceActions.resync_device_request(payload));
    },
  };
};

const debouncedLiveController = debounceRender(LiveController, 100, {
  leading: true,
  trailing: true,
  maxWait: 100,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(debouncedLiveController);
