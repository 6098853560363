import { takeEvery, takeLatest, select, call, put } from "redux-saga/effects";
import _ from "lodash";
import { createAction, createReducer, actionNames } from "../tools";
import api from "../api";

function parseRegisterError(d) {
  if (d.registerError && _.isString(d.registerError)) {
    try {
      d.registerError = JSON.parse(d.registerError);
    } catch (e) {
      d.registerError = "";
    }
  }
}

export const actions = createAction(
  "cirrus/module/devices",
  ...actionNames("LIST"),
  ...actionNames("SINGLE")
);

export default createReducer(
  {
    [actions.LIST_SUCCESS]: (state, action) => {
      /* Not returning the state because we need to replace all 
      devices that come from the server
    */
      const data = action.payload.data;
      return data.reduce((r, d) => {
        parseRegisterError(d);
        const device = r[d.serialId] || {};
        r[d.serialId] = { ...device, ...d };
        return r;
      }, {});
    },
    [actions.SINGLE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      let device = state[payload.serialId] || {};
      parseRegisterError(payload);
      device = { ...device, ...payload };
      return {
        ...state,
        [payload.serialId]: device,
      };
    },
    [actions.ACTIVE_DEVICE]: (state, action) => {
      const { serialId, meterId } = action.payload;
      return {
        serialId,
        meterId,
      };
    },
  },
  {}
);

export function* fetchDevices() {
  try {
    const data = yield call(api.devices.all);
    yield put(actions.list_success(data));
  } catch (e) {
    console.log(e);
    yield put(actions.list_error(e));
    yield put(alerts.error({ message: "Problem getting devices" }));
  }
}

export function* getDevice({ payload }) {
  const { serialId } = payload;
  const { data } = yield race({
    data: call(api.devices.getSingle, payload),
    cancel: take(actions.RESYNC_DEVICE_REQUEST),
  });

  const device = yield select((s) => _.get(s, ["devices", serialId]));

  if (device && device.syncing) {
    return;
  }

  if (data) {
    yield put(actions.single_success(data));
  }
}

export function* mainSaga() {
  yield takeLatest(actions.LIST_REQUEST, fetchDevices);
  yield takeEvery(actions.SINGLE_REQUEST, getDevice);
}
