import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  StripeProvider,
  Elements,
  CardElement,
  injectStripe,
} from "react-stripe-elements";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";

import Config from "Config";
import moment from "moment";

class CardSection extends Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
  }

  handleSave() {
    const { stripe, name, toggle, handleAdd } = this.props;

    stripe.createToken({ name }).then(({ token }) => {
      if (token) {
        toggle();
        handleAdd(token.id);
      }
    });
  }

  render() {
    const { isOpen, toggle } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Update Credit Card</ModalHeader>
        <ModalBody>
          <form>
            Card details
            <CardElement style={{ base: { fontSize: "18px" } }} />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSave}>
            Save
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const CardSectionInjected = injectStripe(CardSection);

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreditCardOpen: false,
      isAccountInfoOpen: false,
      isCancelSubscriptionOpen: false,
      confirmPaymentOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggleCreditCardOpen = this.toggleCreditCardOpen.bind(this);
    this.toggleCancelSubscriptionOpen =
      this.toggleCancelSubscriptionOpen.bind(this);
    this.toggleAccountInfoOpen = this.toggleAccountInfoOpen.bind(this);
    this.handleUserInfoSave = this.handleUserInfoSave.bind(this);
    this.handleChangePlan = this.handleChangePlan.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.toggleConfirmPayment = this.toggleConfirmPayment.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    this.setState({ [target.name]: target.value });
  }

  isValid() {
    return this.state.password && this.state.password == this.state.password2;
  }

  componentDidMount() {
    this.props.request_me_info();
    this.props.list_subscriptions();
    this.props.list_licenses();
    this.props.list_charges();
  }

  handleChangePlan() {
    const { set_subscription, current_license } = this.props;
    set_subscription({
      subscription: this.state.newPlan,
      licenseKey: current_license.key,
    });
    this.toggleConfirmPayment();
  }

  cancelSubscription() {
    const { cancel_subscription, current_license } = this.props;
    cancel_subscription(current_license.key);
    this.toggleCancelSubscriptionOpen();
  }

  toggleCreditCardOpen() {
    this.setState({
      isCreditCardOpen: !this.state.isCreditCardOpen,
    });
  }

  toggleConfirmPayment() {
    this.setState({
      confirmPaymentOpen: !this.state.confirmPaymentOpen,
    });
  }

  toggleCancelSubscriptionOpen() {
    this.setState({
      isCancelSubscriptionOpen: !this.state.isCancelSubscriptionOpen,
    });
  }

  toggleAccountInfoOpen() {
    this.setState({
      isAccountInfoOpen: !this.state.isAccountInfoOpen,
      name: "",
      email: "",
      password: "",
      password2: "",
    });
  }

  emptyPassword() {
    return !(this.state.password || this.state.password2);
  }

  handleUserInfoSave() {
    let user = _.pick(this.state, ["email", "name", "password"]);
    user = _.pickBy(user, (v) => v.trim());
    if (_.keys(user).length) {
      this.props.update_me_info(user);
    }
    this.toggleAccountInfoOpen();
  }

  renderCard() {
    const card = _.get(this, "props.stripe_customer.card");

    let buttonText = "Change card";
    let cardDetails = null;
    if (!card) {
      buttonText = "Add Card";
      cardDetails = <h6>Add Credit Card</h6>;
    } else {
      cardDetails = (
        <span>
          <h6>Current Credit Card</h6>
          {card.brand} ending in {card.last4}
          <br />
          Expires {card.exp_month}/{card.exp_year}
          <br />
        </span>
      );
    }

    return (
      <span>
        {cardDetails}
        <Button
          color="link"
          style={{ paddingLeft: 0 }}
          onClick={this.toggleCreditCardOpen}
        >
          {buttonText}
        </Button>
        <br />
        <hr></hr>
      </span>
    );
  }

  renderPlans() {
    let { subscriptions_list, current_license, lockButtons } = this.props;
    if (
      _.isEmpty(current_license) ||
      _.isNull(current_license) ||
      _.isUndefined(current_license)
    )
      return null;
    const card = _.get(this, "props.stripe_customer.card");

    subscriptions_list = subscriptions_list || [];

    let disabled =
      !this.state.newPlan ||
      this.state.newPlan == current_license.subscription_id ||
      lockButtons;
    const onChange = card ? this.handleChange : this.toggleCreditCardOpen;

    return (
      <CardBody style={{ width: "100%", marginTop: "0", paddingTop: "0" }}>
        <hr></hr>
        <h6>Change Plan</h6>
        <FormGroup check>
          {subscriptions_list.map((s, i) => (
            <div className="radio" key={s.id}>
              <Label check htmlFor={`radio${i}`}>
                <Input
                  type="radio"
                  id={`radio${i}`}
                  name="newPlan"
                  checked={
                    (this.state.newPlan || current_license.subscription_id) ==
                    s.id
                  }
                  value={s.id}
                  onChange={onChange}
                />
                <span>
                  {s.name} for {s.price}{" "}
                </span>
                {current_license.subscription_id == s.id ? (
                  <i className="icon-check"></i>
                ) : null}
              </Label>
            </div>
          ))}
        </FormGroup>
        <Button
          type="submit"
          size="sm"
          color="success"
          disabled={disabled}
          onClick={this.toggleConfirmPayment}
          style={{ marginLeft: "0" }}
        >
          Confirm
        </Button>
      </CardBody>
    );
  }

  hasPlan() {
    const { current_license, subscriptions_list } = this.props;
    return !!(
      current_license &&
      _.find(subscriptions_list, (s) => s.id == current_license.subscription_id)
    );
  }

  renderCancelButton() {
    const { lockButtons } = this.props;
    if (this.hasPlan()) {
      return (
        <Button
          type="submit"
          size="sm"
          color="danger"
          onClick={this.toggleCancelSubscriptionOpen}
          disabled={lockButtons}
        >
          End Subscription
        </Button>
      );
    }
    return null;
  }

  createLicenseOptions() {
    const { licenses, current_license } = this.props;
    if (!licenses) {
      return <option></option>;
    }
    let options = [];
    let defaultEmpty = true;
    let defaultLicense = false;

    for (let license of licenses) {
      if (license.key == current_license.key) {
        defaultLicense = true;
        defaultEmpty = false;
      }
      const expiration_date = moment(license.expiration_date);
      if (defaultLicense) {
        options.push(
          <option key={license.key} value={license.key} selected>
            {license.key} - Exp: {expiration_date.format("MM/DD/YYYY")}
          </option>
        );
      } else {
        options.push(
          <option key={license.key} value={license.key}>
            {license.key} - Exp: {expiration_date.format("MM/DD/YYYY")}
          </option>
        );
      }

      defaultLicense = false;
    }
    if (defaultEmpty) {
      options.unshift(
        <option key="" value="" selected>
          Select a license
        </option>
      );
    } else {
      options.unshift(
        <option key="" value="">
          Select a license
        </option>
      );
    }

    return options;
  }

  onSelectionChange(e) {
    const { update_current_license } = this.props;
    this.setState({
      selected: e.target.value,
    });
    update_current_license({ license: e.target.value });
  }

  renderLicenseList() {
    const { selected } = this.state;
    return (
      <CardBody>
        <h6> License</h6>
        <Input
          type="select"
          placeholder="Select a license"
          name="licenseSelect"
          ref={this.selectInput}
          value={selected}
          onChange={this.onSelectionChange}
        >
          {this.createLicenseOptions()}
        </Input>
      </CardBody>
    );
  }

  renderCharges() {
    let { charges } = this.props;
    if (!(charges && charges.length)) return null;

    charges = _.slice(charges, 0, 4);
    const last = charges[0];
    last.created = moment(last.created);

    return (
      <span>
        <h6>Billing History</h6>
        Your last payment for{" "}
        <span>
          <strong>{last.amount}</strong>
        </span>{" "}
        was made on{" "}
        <span>
          <i>{last.created.format("LL")}</i>
        </span>
        <br />
        {/*<Button color="link" style={{ paddingLeft: 0, marginLeft: 0 }}>View all Invoices</Button>*/}
        <br />
        <h6>Recent charges:</h6>
        <table>
          <tbody>
            {charges.map((c) => (
              <tr key={c.id}>
                <td>{moment(c.created).format("MMM Do YYYY HH:mm")}</td>
                <td>- {c.description} -</td>
                <td>{c.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr></hr>
        <table></table>
      </span>
    );
  }

  render() {
    let { current_license, subscriptions_list, email, name } = this.props;
    name = this.state.name || name;
    email = this.state.email || email;
    const password = this.state.password || "";
    const password2 = this.state.password2 || "";
    const empty = this.emptyPassword();
    const validText = empty || this.isValid() ? "" : "passwords do not match";
    subscriptions_list = subscriptions_list || [];

    if (!current_license) return;

    const planSelected =
      _.find(subscriptions_list, (s) => s.id == this.state.newPlan) || {};

    const expiration_date = moment(current_license.expiration_date).format(
      "MMM Do YYYY"
    );

    let currentPlan = (
      <React.Fragment>
        <h5>Current Plan: {current_license.subscription_name || "No Plan"}</h5>
        <i>Next payment on {expiration_date}</i>
      </React.Fragment>
    );

    if (current_license.subscription_name === "Cancel") {
      currentPlan = (
        <React.Fragment>
          <h5>Current Plan will end on {current_license.expiration_date}</h5>
          <i>you will no longer be charged.</i>
        </React.Fragment>
      );
    }
    if (
      _.isEmpty(current_license) ||
      _.isNull(current_license) ||
      _.isUndefined(current_license)
    ) {
      currentPlan = (
        <React.Fragment>
          <h5>No License is currently selected</h5>
          <i>Please select a license to view your subscription information.</i>
        </React.Fragment>
      );
    }

    return (
      <StripeProvider apiKey={Config.stripeKey}>
        <div className="animated fadeIn">
          <Modal
            isOpen={this.state.isAccountInfoOpen}
            toggle={this.toggleAccountInfoOpen}
          >
            <ModalHeader>User Information</ModalHeader>
            <ModalBody>
              <Form action="" method="post">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-envelope"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      placeholder="Email Address"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label>Update Password</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      id="password"
                      name="password"
                      value={password}
                      placeholder="Password"
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      id="password2"
                      name="password2"
                      value={password2}
                      placeholder="Re-type Password"
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <span style={{ float: "left" }}>{validText}</span>
              <Button
                color="primary"
                disabled={!(empty || this.isValid())}
                onClick={this.handleUserInfoSave}
              >
                Save
              </Button>
              <Button color="secondary" onClick={this.toggleAccountInfoOpen}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.isCancelSubscriptionOpen}
            toggle={this.toggleCancelSubscriptionOpen}
          >
            <ModalHeader>Confirm Subscription Cancellation</ModalHeader>
            <ModalBody>
              <div>Are you sure you want to cancel your subscription?</div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.cancelSubscription}>
                Confirm
              </Button>
              <Button
                color="secondary"
                onClick={this.toggleCancelSubscriptionOpen}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.confirmPaymentOpen}
            toggle={this.toggleConfirmPayment}
          >
            <ModalHeader>Confirm Subscription Plan</ModalHeader>
            <ModalBody>
              <div>
                Are you sure you'd like to update your plan?
                <br />
                you will be charged {planSelected.price} for "
                {planSelected.name}" Plan
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.handleChangePlan}>
                Confirm
              </Button>
              <Button color="secondary" onClick={this.toggleConfirmPayment}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Elements>
            <CardSectionInjected
              name={name}
              handleAdd={this.props.add_card}
              isOpen={this.state.isCreditCardOpen}
              toggle={this.toggleCreditCardOpen}
            />
          </Elements>
          <Row>
            <Col xs="12" md="8">
              <Card>
                <CardHeader>
                  <h5>My Account</h5>
                </CardHeader>
                <CardBody>
                  <span>
                    <h6>User Information</h6>
                    {this.props.name}
                    <br />
                    {this.props.email}
                    <br />
                    Password: •••••••••••
                    <br />
                    <Button
                      color="link"
                      style={{ paddingLeft: 0 }}
                      onClick={this.toggleAccountInfoOpen}
                    >
                      edit
                    </Button>
                    <br />
                    <hr />
                  </span>

                  {this.renderCard()}
                  {this.renderCharges()}
                  {this.renderCancelButton()}
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card>
                <CardHeader
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingBottom: "0",
                  }}
                >
                  {currentPlan}
                </CardHeader>
                {this.renderLicenseList()}
                {this.renderPlans()}
                {this.renderCancelButton()}
              </Card>
            </Col>
          </Row>
        </div>
      </StripeProvider>
    );
  }
}

Account.propTypes = {
  add_card: PropTypes.func,
  set_subscription: PropTypes.func,
  current_license: PropTypes.object,
  request_me_info: PropTypes.func,
  list_subscriptions: PropTypes.func,
  list_licenses: PropTypes.func,
  list_charges: PropTypes.func,
  cancel_subscription: PropTypes.func,
  update_me_info: PropTypes.func,
};

export default Account;
