import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);
    const { email, name } = props.currentUser || {};

    this.state = {
      email: email || "",
      name: name || "",
    };
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { email, name } = this.props.currentUser || {};
    const prevCurrentUser = prevProps.currentUser || {};

    if (email != prevCurrentUser.email || name != prevCurrentUser.name) {
      this.setState({
        email: email || "",
        name: name || "",
      });
    }
  }

  onConfirm() {
    const { onConfirm } = this.props;
    onConfirm();
    this.props.toggle();
  }

  render() {
    const { className, isOpen, toggle } = this.props;
    const { email, name } = this.state;
    if (!isOpen) return null;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}> Reset Password </ModalHeader>
        <ModalBody>
          Are you sure you want to send a Reset Password Email to User: {name}.
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
          <Button color="success" onClick={this.onConfirm}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ResetPasswordModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
  currentUser: PropTypes.object,
};

export default ResetPasswordModal;
