import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Col,
  Row,
  InputGroupText,
} from "reactstrap";

import DeleteConfirmModal from "../components/DeleteConfirmModal";

class SubscriptionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      price: 0.01,
      days: 1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentWillReceiveProps(props) {
    const { currentSubscription } = props;
    const price =
      currentSubscription && currentSubscription.price
        ? parseFloat(_.replace(currentSubscription.price, "$", ""))
        : 0.01;
    this.setState({
      name: (currentSubscription && currentSubscription.name) || "",
      price: price,
      days: (currentSubscription && currentSubscription.days) || 1,
    });
    if (props.isOpen) return;
  }
  onSave() {
    const { onSave, currentSubscription } = this.props;
    const { name, price, days } = this.state;
    const intPrice = Math.trunc(price * 100);
    let data = {
      params: {
        name: name,
        price: intPrice,
        days: days,
        render: true,
      },
      id: currentSubscription && currentSubscription.id,
    };

    onSave(data);
    this.props.toggle();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  renderShow() {
    const { className, isOpen, toggle, currentSubscription } = this.props;
    if (currentSubscription) {
      return (
        <Modal isOpen={isOpen} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>Show Subscription</ModalHeader>
          <ModalBody>
            <ul>
              <li>
                <strong>Name: </strong>
                {currentSubscription.name}
              </li>
              <li>
                <strong>Price: </strong>
                {currentSubscription.price}
              </li>
              <li>
                <strong>Days: </strong>
                {currentSubscription.days}
              </li>
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      );
    } else
      return (
        <Modal isOpen={isOpen} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>Show Subscription</ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter>
            <Button color="link" onClick={toggle}>
              Cancel
            </Button>
            <Button color="success" onClick={this.onSave}>
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      );
  }

  renderEdit() {
    const { className, isOpen, toggle, currentSubscription } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
          {currentSubscription ? "Edit Subscription" : "New Subscription"}
        </ModalHeader>
        <ModalBody>
          <Form action="" method="post">
            <FormGroup>
              <Label for="name">Name:</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-bubble-text"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </InputGroup>
              <br />
              <Label for="price">Price:</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-cash-dollar"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  id="price"
                  min="0.01"
                  step="0.01"
                  name="price"
                  value={this.state.price}
                  onChange={this.handleChange}
                />
              </InputGroup>
              <br />
              <Label for="days">Days:</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-calendar-full"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  id="days"
                  value={this.state.days}
                  name="days"
                  placeholder="Days"
                  onChange={this.handleChange}
                  step="1"
                  min="1"
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
          <Button color="success" onClick={this.onSave}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { isEdit } = this.props;

    if (isEdit) {
      return this.renderEdit();
    } else {
      return this.renderShow();
    }
  }
}

SubscriptionModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSave: PropTypes.func,
  currentSubscription: PropTypes.object,
  subscriptionList: PropTypes.array,
};

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      search: "",
      currentSubscription: null,
    };
    this.onDelete = this.onDelete.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleSubscriptionModal = this.toggleSubscriptionModal.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  toggleDeleteModal(index) {
    this.props.setSubscription(index);
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  }

  onDelete() {
    const { deleteSubscription, currentSubscription } = this.props;
    if (!currentSubscription) return;
    deleteSubscription(currentSubscription);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  toggleSubscriptionModal(index, isEdit) {
    this.props.setSubscription(index);
    this.setState({
      editModal: !this.state.editModal,
      isEdit: isEdit,
    });
  }
  onSave(data) {
    const { saveSubscription } = this.props;
    saveSubscription(data);
  }

  render() {
    const { className, subscriptions, currentSubscription } = this.props;
    const { deleteModal, isEdit, editModal } = this.state;
    return (
      <div className="animated fadeIn">
        <DeleteConfirmModal
          className={className}
          onDelete={this.onDelete}
          isOpen={deleteModal}
          toggle={this.toggleDeleteModal}
          name="Subscription"
        />
        <SubscriptionModal
          className={className}
          onSave={this.onSave}
          isOpen={editModal}
          toggle={this.toggleSubscriptionModal}
          currentSubscription={currentSubscription}
          isEdit={isEdit}
        />

        <Card>
          <CardHeader>
            <h5>Subscriptions</h5>
            <Button
              color="primary"
              className="right"
              onClick={() => this.toggleSubscriptionModal(null, true)}
            >
              {" "}
              Add Subscription
            </Button>
          </CardHeader>

          <CardBody className="card-body">
            <Table responsive striped>
              <thead className="no-top-border">
                <tr>
                  <th name="email">Name</th>
                  <th name="filename">Price</th>
                  <th name="upload_date">Days</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((subscription, i) => (
                  <tr key={subscription.id}>
                    <td>
                      <Button
                        size="sm"
                        color="link"
                        onClick={() => this.toggleSubscriptionModal(i, false)}
                      >
                        {subscription.name}
                      </Button>
                    </td>
                    <td>{subscription.price}</td>
                    <td>{subscription.days}</td>
                    <td className="actions list-actions">
                      <i
                        className="icon-eye"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.toggleSubscriptionModal(i, false)}
                      ></i>
                      <i
                        className="icon-pencil"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.toggleSubscriptionModal(i, true)}
                      ></i>
                      <i
                        className="icon-trash"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.toggleDeleteModal(i)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  }
}

Subscriptions.propTypes = {
  currentSubscription: PropTypes.object,
  className: PropTypes.string,
  getSubscriptions: PropTypes.func,
  setSubscription: PropTypes.func,
  saveSubscription: PropTypes.func,
  deleteSubscription: PropTypes.func,
  subscriptions: PropTypes.array,
  page: PropTypes.number,
  currentSubscription: PropTypes.object,
  usedSubscriptions: PropTypes.number,
  list_subscriptions: PropTypes.func,
  subscriptionList: PropTypes.array,
};

export default Subscriptions;
