import PropTypes from "prop-types";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import UserDetails from "../components/UserDetails";
import DeleteConfirmModal from "../components/DeleteConfirmModal";
import ResetPasswordModal from "../components/ResetPasswordModal";
import GitCommit from "../_git_commit";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
      deleteModal: false,
      resetPasswordModal: false,
      search: "",
      toggleSort: "desc",
      sort: "",
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleResetPasswordModal = this.toggleResetPasswordModal.bind(this);

    this.onDelete = this.onDelete.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
    this.onDownloadCsv = this.onDownloadCsv.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sortTable = this.sortTable.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.currentUser) return;

    const { open } = this.props.currentUser;
    if (!open) this.toggleEditModal();
  }

  toggleEditModal(index) {
    this.props.setUser(index);
    this.setState({
      editModal: !this.state.editModal,
    });
  }
  toggleDeleteModal(index) {
    this.props.setUser(index);
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  }
  toggleResetPasswordModal(index) {
    this.props.setUser(index);
    this.setState({
      resetPasswordModal: !this.state.resetPasswordModal,
    });
  }

  onDelete() {
    const { deleteUser, currentUser } = this.props;
    if (!currentUser) return;
    deleteUser(currentUser);
  }
  onResetPassword() {
    const { resetPassword, currentUser } = this.props;
    if (!currentUser) return;
    resetPassword(currentUser);
  }

  onDownloadCsv() {
    const { downloadCsv } = this.props;
    downloadCsv();
  }

  onSearch(e) {
    e.preventDefault();
    const { searchUsers } = this.props;
    const { search } = this.state;
    let data = {
      search: search,
    };
    searchUsers(data);
  }

  sortTable(name) {
    const { sortUsers } = this.props;
    const { toggleSort, search } = this.state;

    this.setState({
      toggleSort: toggleSort == "asc" ? "desc" : "asc",
      sort: name,
    });

    const order = toggleSort == "asc" ? "desc" : "asc";

    let data = {
      sort: name,
      order: order,
      search: search,
    };

    sortUsers(data);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  renderPagination() {
    const { pagination, getUsers } = this.props;
    const { search, sort, toggleSort } = this.state;
    if (!pagination) return null;
    const { page_number, total_pages } = pagination;
    let paginationItems = [];
    if (total_pages > 4)
      paginationItems.push(
        <PaginationItem key={"first"} active={false}>
          <PaginationLink
            first={true}
            onClick={() =>
              getUsers({
                page: 1,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {"First"}
          </PaginationLink>
        </PaginationItem>
      );
    let end = page_number + 3 >= total_pages ? total_pages : page_number + 3;
    for (let i = page_number - 3 <= 1 ? 1 : page_number - 3; i <= end; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={page_number == i}>
          <PaginationLink
            onClick={() =>
              getUsers({
                page: i,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    if (total_pages > 4)
      paginationItems.push(
        <PaginationItem key={"last"} active={false}>
          <PaginationLink
            last={true}
            onClick={() =>
              getUsers({
                page: total_pages,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {"Last"}
          </PaginationLink>
        </PaginationItem>
      );
    return <Pagination>{paginationItems}</Pagination>;
  }

  render() {
    const { className, users, pagination, getUsers, currentUser, saveUser } =
      this.props;
    const { editModal, deleteModal, resetPasswordModal } = this.state;
    return (
      <div className="animated fadeIn">
        <Modal
          isOpen={editModal}
          toggle={this.toggleEditModal}
          className={className}
        >
          <ModalHeader toggle={this.toggleEditModal}>
            {(currentUser && currentUser.name) || "New User"}
          </ModalHeader>
          <ModalBody>
            <UserDetails
              user={currentUser}
              requiresPassword={false}
              admin={true}
              handleClick={saveUser}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <DeleteConfirmModal
          className={className}
          onDelete={this.onDelete}
          isOpen={deleteModal}
          toggle={this.toggleDeleteModal}
          name="User"
        />
        <ResetPasswordModal
          className={className}
          onConfirm={this.onResetPassword}
          isOpen={resetPasswordModal}
          toggle={this.toggleResetPasswordModal}
          currentUser={currentUser}
        />
        <Card>
          <CardHeader>
            <h5>Users</h5>
            <Button
              color="primary"
              className="right"
              onClick={this.toggleEditModal}
            >
              Add User
            </Button>
            <Button color="primary" onClick={this.onDownloadCsv}>
              Download CSV
            </Button>
          </CardHeader>
          <CardBody className="card-body">
            <Form onSubmit={this.onSearch}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        id="search"
                        value={this.state.search}
                        name="search"
                        placeholder="Search"
                        onChange={this.handleChange}
                      />
                      <Button color="disabled" onClick={this.onSearch}>
                        <i className="icon-magnifier"></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Table responsive striped>
              <thead className="no-top-border">
                <tr>
                  <th
                    name="name"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("name")}
                  >
                    Name
                  </th>
                  <th
                    name="email"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("email")}
                  >
                    Email
                  </th>
                  <th
                    name="role"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("is_super")}
                  >
                    Role
                  </th>
                  <th
                    name="date_registered"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("inserted_at")}
                  >
                    Date Registered
                  </th>
                  <th name="licenses">Licenses</th>
                  <th
                    name="os"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("os")}
                  >
                    OS
                  </th>
                  <th
                    name="version"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("version")}
                  >
                    Version
                  </th>
                  <th
                    name="version"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("version")}
                  >
                    Verified
                  </th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.map((u, i) => (
                  <tr key={u.email}>
                    <td>
                      <NavLink to="/users" onClick={() => this.toggle(i)}>
                        {u.name}
                      </NavLink>
                    </td>
                    <td>{u.email}</td>
                    <td>{u.is_super ? "Admin" : "user"}</td>
                    <td>{u.inserted_at}</td>
                    <td>
                      {u.licenses
                        ? u.licenses.toString().replace(/,/g, "\r\n")
                        : ""}
                    </td>
                    <td>{u.os ? u.os : ""}</td>
                    <td>{u.version ? u.version : ""}</td>
                    <td>{u.verified ? "Yes" : "No"}</td>
                    <td className="actions list-actions">
                      <i
                        className="icon-pencil"
                        onClick={() => this.toggleEditModal(i)}
                      ></i>
                      <i
                        className="icon-trash"
                        onClick={() => this.toggleDeleteModal(i)}
                      ></i>
                      <i
                        className="icon-refresh"
                        onClick={() => this.toggleResetPasswordModal(i)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {this.renderPagination()}
            <span>{`Version: ${GitCommit.logMessage}`}</span>
          </CardBody>
        </Card>
      </div>
    );
  }
}

Users.propTypes = {
  currentUser: PropTypes.object,
  className: PropTypes.string,
  getUsers: PropTypes.func,
  setUser: PropTypes.func,
  saveUser: PropTypes.func,
  users: PropTypes.array,
  pagination: PropTypes.array,
  page: PropTypes.number,
  deleteUser: PropTypes.func,
  resetPassword: PropTypes.func,
  downloadCsv: PropTypes.func,
};

export default Users;
