import { connect } from "react-redux";
import ResetPassword from "../views/pages/ResetPassword";
import { actions } from "../modules/auth";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return state.auth || {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword(payload) {
      dispatch(actions.reset_password_request(payload));
    },
    newPassword(payload) {
      dispatch(actions.new_password_request(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
