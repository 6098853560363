import { connect } from "react-redux";
import Users from "../views/Users";
import { actions } from "../modules/users";
import { actions as authActions } from "../modules/auth";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return {
    users: _.get(state, "users.list", []),
    pagination: _.get(state, "users.pagination", []),
    currentUser: _.get(state, "users.current", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers(payload) {
      dispatch(actions.list_request(payload));
    },
    setUser(index) {
      dispatch(actions.set_user(index));
    },
    saveUser(data) {
      data = _(data).omit(_.isUndefined).omit(_.isNull).value();
      let f;
      if (data.id) {
        f = actions.update_request;
      } else {
        f = actions.create_request;
      }
      dispatch(f(data));
    },
    deleteUser(data) {
      dispatch(actions.delete_request(data));
    },
    resetPassword(payload) {
      dispatch(authActions.reset_password_request(payload));
    },
    downloadCsv() {
      dispatch(actions.download_csv_request());
    },
    searchUsers(data) {
      dispatch(actions.list_request(data));
    },
    sortUsers(data) {
      dispatch(actions.list_request(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
