import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import isExternal from "is-url-external";
import classNames from "classnames";
import navUser from "./_nav_user";
import navAdmin from "./_nav_admin";
import SidebarFooter from "./../SidebarFooter";
import SidebarForm from "./../SidebarForm";
import SidebarHeader from "./../SidebarHeader";
import SidebarMinimizer from "./../SidebarMinimizer";
import {
  Badge,
  Nav,
  NavItem,
  NavbarBrand,
  NavLink as RsNavLink,
} from "reactstrap";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      dropdownOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle("open");
  }

  activeRoute(routeName) {
    return location.pathname.indexOf(routeName) > -1
      ? "nav-item nav-dropdown open active"
      : "nav-item nav-dropdown";
  }

  renderBadge(badge) {
    if (!badge) return null;
    const classes = classNames(badge.class);
    return (
      <Badge className={classes} color={badge.variant}>
        {badge.text}
      </Badge>
    );
  }

  renderWrapper(item) {
    if (item.wrapper && item.wrapper.element) {
      return React.createElement(
        item.wrapper.element,
        item.wrapper.attributes,
        item.name
      );
    }
    return item.name;
  }

  renderTitle(title, key) {
    const classes = classNames("nav-title", title.class);
    return (
      <li key={key} className={classes}>
        {this.renderWrapper(title)}{" "}
      </li>
    );
  }

  renderNavItem(item, key) {
    const classes = classNames(item.class);
    const variant = classNames(
      "nav-link",
      item.variant ? `nav-link-${item.variant}` : ""
    );
    return (
      <NavItem key={key} className={classes}>
        {isExternal(item.url) ? (
          <RsNavLink href={item.url} className={variant}>
            <i className={item.icon}></i>
            {item.name}
            {this.renderBadge(item.badge)}
          </RsNavLink>
        ) : (
          <NavLink to={item.url} className={variant}>
            <i className={item.icon}></i>
            {item.name}
            {this.renderBadge(item.badge)}
          </NavLink>
        )}
      </NavItem>
    );
  }

  renderNavLink(item, idx) {
    const divider = (divider, key) => <li key={key} className="divider"></li>;
    return item.title
      ? this.renderTitle(item, idx)
      : item.divider
      ? divider(item, idx)
      : item.children
      ? this.renderNavDropdown(item, idx)
      : this.renderNavItem(item, idx);
  }

  renderNavLinks(items) {
    return items.map((item, index) => this.renderNavLink(item, index));
  }

  renderNavDropdown(item, key) {
    const activeRoute = this.activeRoute;
    return (
      <li key={key} className={activeRoute(item.url)}>
        <a
          className="nav-link nav-dropdown-toggle"
          href="#"
          onClick={this.handleClick}
        >
          <i className={item.icon}></i>
          {item.name}
        </a>
        <ul className="nav-dropdown-items">
          {this.renderNavLinks(item.children)}
        </ul>
      </li>
    );
  }

  renderUser() {
    const { user, logout } = this.props;

    if (!user) {
      return (
        <Nav navbar>
          <NavItem>
            <NavLink className="nav-link" to="/login">
              <i className="icon-user"></i>Login
            </NavLink>
          </NavItem>
        </Nav>
      );
    }

    return (
      <Nav navbar>
        <NavItem>
          <a
            className="nav-link"
            target="_blank"
            href="http://elitelab.labsociety.com/docs/"
          >
            <i className="icon-document2"></i> Docs
          </a>
        </NavItem>
        <NavItem>
          <RsNavLink href={"/#/account"}>
            <i className="icon-user"></i> {user.email}
          </RsNavLink>
        </NavItem>
        <NavItem
          className="nav-link"
          onClick={() => logout(user && user.token)}
        >
          <i className="icon-exit-left"></i> Logout
        </NavItem>
      </Nav>
    );
  }

  render() {
    // sidebar-nav root
    const { user } = this.props;
    const items = user.is_super ? navAdmin.items : navUser.items;
    return (
      <div className="sidebar">
        <SidebarHeader />
        <SidebarForm />
        <nav className="sidebar-nav" id="left-side-bar">
          <NavbarBrand href="#" className="d-flex">
            <span className="text">ELITE LAB</span>
          </NavbarBrand>
          <Nav>{this.renderNavLinks(items)}</Nav>
        </nav>
        {this.renderUser()}
        <SidebarFooter />
        <SidebarMinimizer />
      </div>
    );
  }
}

Sidebar.propTypes = {
  user: PropTypes.object,
};

export default Sidebar;
