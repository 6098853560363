import { takeEvery, put, call } from "redux-saga/effects";
import { createAction, createReducer, actionNames } from "../tools";
import api from "../api";
import _ from "lodash";
import moment from "moment";

export const actions = createAction(
  "cirrus/modules/daily-records",
  ...actionNames("LIST"),
  ...actionNames("GET_FROM_DATE"),
  ...actionNames("GET_FROM_DATE_RANGE"),
  ...actionNames("DELETE"),
  ...actionNames("GET_LAST_30_DAYS")
);

const defaultState = {
  list: [],
  currentDailyRecord: null,
};

export default createReducer(
  {
    [actions.LIST_SUCCESS]: (state, action) => {
      const { data, average_daily_minutes, average_daily_users } =
        action.payload;
      return {
        ...state,
        list: data,
        averageDailyMinutes: average_daily_minutes,
        averageDailyUsers: average_daily_users,
      };
    },
    [actions.GET_FROM_DATE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        dailyRecordByDate: payload,
      };
    },

    [actions.GET_FROM_DATE_RANGE_SUCCESS]: (state, action) => {
      const { data, average_daily_minutes, average_daily_users } =
        action.payload;
      return {
        ...state,
        dateRangeList: data,
        dateRangeAverageDailyMinutes: average_daily_minutes,
        dateRangeAverageDailyUsers: average_daily_users,
      };
    },
    [actions.GET_LAST_30_DAYS_SUCCESS]: (state, action) => {
      const { average_daily_minutes, average_daily_users } = action.payload;
      return {
        ...state,
        averageMonthlyMinutes: average_daily_minutes,
        averageMonthlyUsers: average_daily_users,
      };
    },
    [actions.DELETE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        currentDailyRecord: { ...payload, open: false },
      };
    },
  },
  defaultState
);

function* list_daily_records() {
  try {
    const data = yield call(api.dailyRecords.all);
    yield put(actions.list_success(data));
  } catch (e) {
    yield put(actions.list_error(e));
  }
}

function* get_from_date({ payload }) {
  try {
    const data = yield call(api.dailyRecords.get_from_date, payload);
    yield put(actions.get_from_date_success(data));
  } catch (e) {
    yield put(actions.get_from_date_error(e));
  }
}

function* get_from_date_range({ payload }) {
  try {
    const data = yield call(api.dailyRecords.get_from_date_range, payload);
    yield put(actions.get_from_date_range_success(data));
  } catch (e) {
    yield put(actions.get_from_date_range_error(e));
  }
}

function* last_30_days() {
  try {
    const format = "YYYY-MM-DD";
    const range = {
      startDate: moment().subtract(1, "months").format(format),
      endDate: moment().format(format),
    };
    const data = yield call(api.dailyRecords.get_from_date_range, range);
    yield put(actions.get_last_30_days_success(data));
  } catch (e) {
    yield put(actions.get_last_30_days_error(e));
  }
}

function* delete_daily_record({ payload }) {
  try {
    const { data } = yield call(api.dailyRecords.delete, payload);
    yield put(actions.list_request());
    yield put(actions.delete_success(data));
  } catch (e) {
    yield put(actions.delete_error(e));
  }
}

export const routes = {
  *"/daily-records"() {
    yield put(actions.list_request());
    yield put(actions.get_last_30_days_request());
  },
};

export function* mainSaga() {
  yield takeEvery(actions.LIST_REQUEST, list_daily_records);
  yield takeEvery(actions.GET_FROM_DATE_REQUEST, get_from_date);
  yield takeEvery(actions.GET_FROM_DATE_RANGE_REQUEST, get_from_date_range);
  yield takeEvery(actions.DELETE_REQUEST, delete_daily_record);
  yield takeEvery(actions.GET_LAST_30_DAYS_REQUEST, last_30_days);
}
