import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

function onlyNumbers(text) {
  const nonNumericRegex = /[^0-9]+/g;
  const number = parseInt(text.replace(nonNumericRegex, ""), 10);
  if (_.isNaN(number)) return "";
  return number;
}

class GraphSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: _.cloneDeep(props.config),
    };

    this.handleName = this.onChangeField.bind(this, "name", (x) => x);
    this.handleDurationValue = this.onChangeField.bind(
      this,
      "duration.value",
      onlyNumbers
    );
    this.handleDurationUnits = this.onChangeField.bind(
      this,
      "duration.units",
      (x) => x
    );
    this.handleIntervalValue = this.onChangeField.bind(
      this,
      "interval.value",
      onlyNumbers
    );
    this.handleIntervalUnits = this.onChangeField.bind(
      this,
      "interval.units",
      (x) => x
    );
    this.handleRangeMin = this.onChangeField.bind(
      this,
      "range.min",
      onlyNumbers
    );
    this.handleRangeMax = this.onChangeField.bind(
      this,
      "range.max",
      onlyNumbers
    );
    // this.handleRangeUnits = this.onChangeField.bind(this, 'range.units', x => x)
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentWillReceiveProps(props) {
    const currentName = _.get(this.state, "config.key", "");
    const nextName = _.get(props, "config.key", "");
    if (currentName != nextName) {
      this.setState({
        config: _.cloneDeep(props.config),
      });
    }
  }

  onChangeField(path, cleaner, e) {
    const { config } = this.state;
    const newValue = cleaner(e.target.value);
    const newConfig = _.set(_.cloneDeep(config), path, newValue);
    this.setState({ config: newConfig });
  }

  handleSave() {
    const { config } = this.state;
    this.props.onSave(config);
    this.props.toggle();
  }

  handleCancel() {
    const { config } = this.props;
    this.setState({ config });
    this.props.toggle();
  }

  renderButtons() {
    const { meters, isRenderingMeter, onMeterButtonClick } = this.props;
    // const sorted = _.chain(meters).orderBy('model').orderBy('units').value()
    const buttons = _.map(meters, (m) => {
      if (!m.active) return null;
      const { serialId, address, device } = m;
      const toRender = isRenderingMeter(m);
      const color = toRender ? "primary" : "secondary";
      return (
        <Row style={{ paddingBottom: 7 }}>
          <Col md={{ size: 12 }}>
            <Button
              color={color}
              size="sm"
              onClick={() => onMeterButtonClick(m)}
              block
            >
              {device.name || serialId} - {address} (Model: {device.model},{" "}
              {m.units})
            </Button>
          </Col>
        </Row>
      );
    });

    return <div>{buttons}</div>;
  }

  render() {
    const { isOpen, className } = this.props;
    const { config } = this.state;

    const duration = config.duration;
    const interval = config.interval;
    const range = config.range;
    return (
      <Modal isOpen={isOpen} toggle={this.handleCancel} className={className}>
        <ModalHeader toggle={this.handleCancel}>Graph Settings</ModalHeader>
        <ModalBody>
          <Form action="" method="post">
            <Label htmlFor="timeRangeMin">Graph Name</Label>
            <FormGroup className="row">
              <Col xs="12" md="12">
                <Input
                  type="text"
                  id="plot-name"
                  name="Plot Name"
                  placeholder="Graph name"
                  value={config.name || ""}
                  onChange={this.handleName}
                />
              </Col>
            </FormGroup>

            <Label htmlFor="timeRangeMin">X Time Range</Label>
            <FormGroup className="row">
              <Col xs="12" md="6">
                <Input
                  type="text"
                  id="timeRangeMin"
                  name="Time Min."
                  placeholder="Duration"
                  value={duration.value}
                  onChange={this.handleDurationValue}
                />
              </Col>
              <Col xs="12" md="6">
                <Input
                  type="select"
                  name="Duration Select"
                  id="durationSelect"
                  value={duration.units}
                  onChange={this.handleDurationUnits}
                >
                  <option value="second" selected>
                    Seconds
                  </option>
                  <option value="minute">Minutes</option>
                  <option value="hour">Hours</option>
                </Input>
              </Col>
            </FormGroup>
            {/*
            <Label htmlFor="timeRangeMax">Refresh Value every:</Label>
            <FormGroup className="row">
              <Col xs="12" md="6">
                <Input type="text" id="timeRangeMax" name="Time Max." placeholder="Interval" value={ interval.value } onChange={this.handleIntervalValue}/>
              </Col>
              <Col xs="12" md="6">
                <Input type="select" name="Interval Select" id="intervalSelect" value={ interval.units } onChange={this.handleIntervalUnits}>
                  <option value="second" selected>Seconds</option>
                  <option value="minute">Minutes</option>
                  <option value="hour">Hours</option>
                </Input>
              </Col>
            </FormGroup>
            */}
            <Label htmlFor="timeRangeMin">Y Range (min - max)</Label>
            <FormGroup className="row">
              <Col xs="12" md="6">
                <Input
                  type="text"
                  id="tempRangeMin"
                  name="Temp Min."
                  placeholder="Temp Min."
                  value={range.min}
                  onChange={this.handleRangeMin}
                />
              </Col>
              <Col xs="12" md="6">
                <Input
                  type="text"
                  id="tempRangeMax"
                  name="Temp Max"
                  placeholder="Temp Max."
                  value={range.max}
                  onChange={this.handleRangeMax}
                />
              </Col>
            </FormGroup>
          </Form>
          <h6>Meters to Display</h6>
          {this.renderButtons()}
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button color="success" onClick={this.handleSave}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

GraphSettings.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  config: PropTypes.object,
  onSave: PropTypes.func,
  meters: PropTypes.array,
  isRenderingMeter: PropTypes.func,
  onMeterButtonClick: PropTypes.func,
};

export default GraphSettings;
