import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  FormGroup,
  InputGroupAddon,
  Form,
  FormFeedback,
  InputGroupText,
} from "reactstrap";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password2: "",
    };
    this.handlePassword = this.handleChangeValue.bind(this, "password");
    this.handlePassword2 = this.handleChangeValue.bind(this, "password2");
    this.handleEmail = this.handleChangeValue.bind(this, "email");
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
  }

  handleChangeValue(name, e) {
    this.setState({
      [name]: e.target.value,
    });
  }

  isPasswordOK() {
    return this.state.password == this.state.password2;
  }

  isEmailValid() {
    const { email } = this.state;
    return !!(email && email.includes("@"));
  }

  isPasswordValid() {
    const { password, password2 } = this.state;
    return !!(password && password2 && this.isEmailValid());
  }

  handleEmailSubmit(e) {
    e.preventDefault();
    const { email } = this.state;
    this.props.resetPassword({ email });
  }

  emailInputRender() {
    const { email } = this.state;

    const isValid = this.isEmailValid();
    const errors = this.props.errors || {};
    const emailError = _.get(errors, "messages.email");

    return (
      <CardBody className="card-body p-4">
        <h1>Reset Password</h1>
        <p className="text-muted">Please enter your email</p>
        <Form>
          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-at-sign"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Email"
                value={email}
                onChange={this.handleEmail}
              />
            </InputGroup>
            <FormFeedback className="text-danger">{emailError}</FormFeedback>
          </FormGroup>
          <Button
            type="submit"
            color="success"
            disabled={!isValid}
            block
            onClick={this.handleEmailSubmit}
          >
            Submit{" "}
          </Button>
        </Form>
      </CardBody>
    );
  }

  emailSentRender() {
    return (
      <CardBody className="card-body p-4">
        <h3>
          An email has been sent with instructions for resetting your password.
        </h3>
        <NavLink to={"/login"}>
          <Button color="success">Login</Button>
        </NavLink>
      </CardBody>
    );
  }

  render() {
    let body;
    if (this.props.emailAccepted) {
      body = this.emailSentRender();
    } else {
      body = this.emailInputRender();
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <NavLink to={"/"}>
            <img
              width="100"
              className="login-logo"
              src="img/elite-lab-icon.svg"
              alt="EliteLab® Logo"
            />
          </NavLink>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">{body}</Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func,
  newPassword: PropTypes.func,
  errors: PropTypes.object,
  emailAccepted: PropTypes.bool,
};

export default ResetPassword;
