import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";

import Config from "Config";

import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";

const fileUrl = Config.fileUrl;

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
  }

  emailVerifiedError() {
    return (
      <CardBody className="card-body p-4 text-center">
        <h3>There was an error processing your request.</h3>
      </CardBody>
    );
  }
  loadingRender() {
    return (
      <CardBody className="card-body p-4">
        <h1>Verify Email</h1>
        <p className="text-muted">Loading, please wait</p>
      </CardBody>
    );
  }

  emailVerifiedRender() {
    return (
      <CardBody className="card-body p-4, text-center">
        <h3>
          Thank you, your email has been verified. You can now close this
          window.
        </h3>
        <Button href={`${fileUrl}/installers/windows`} color="primary">
          Download App
        </Button>
      </CardBody>
    );
  }

  render() {
    let body;
    if (this.props.loading) {
      body = this.loadingRender();
    } else if (this.props.verified) {
      body = this.emailVerifiedRender();
    } else {
      body = this.emailVerifiedError();
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <NavLink to={"/"}>
            <img
              width="100"
              className="login-logo"
              src="img/elite-lab-icon.svg"
              alt="EliteLab® Logo"
            />
          </NavLink>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">{body}</Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

VerifyEmail.propTypes = {
  resetPassword: PropTypes.func,
  VerifyEmail: PropTypes.func,
  verified: PropTypes.bool,
  loading: PropTypes.bool,
};

export default VerifyEmail;
