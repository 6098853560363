import { connect } from "react-redux";
import Downloads from "../views/pages/Downloads";
import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Downloads);
