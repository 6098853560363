import auth, { mainSaga as authSaga, routes as authRoutes } from "./auth";
import { mainSaga as historySaga } from "./history";
import users, { mainSaga as usersSaga, routes as userRoutes } from "./users";
import licenses, {
  mainSaga as licenseSaga,
  routes as licenseRoutes,
} from "./licenses";
import config, { mainSaga as configSaga } from "./configuration";
import dashboards, { mainSaga as dashboardSaga } from "./dashboards";
import meters, { mainSaga as metersSaga } from "./meters";
import logReducers, { mainSaga as logSaga, routes as logRoutes } from "./logs";
import alerts, { mainSaga as alertsSaga } from "./alerts";
import devices, { mainSaga as devicesSaga } from "./devices";
import debugLogs, {
  mainSaga as debugLogsSaga,
  routes as debugLogsRoutes,
} from "./debugLogs";
import account, { mainSaga as accountSaga } from "./account";
import subscriptions, {
  mainSaga as subscriptionsSaga,
  routes as subscriptionRoutes,
} from "./subscriptions";
import loginRecords, {
  mainSaga as loginRecordsSaga,
  routes as loginRecordsRoutes,
} from "./loginRecords";
import dailyRecords, {
  mainSaga as dailyRecordsSaga,
  routes as dailyRecordsRoutes,
} from "./dailyRecords";
import billing, {
  mainSaga as billingSaga,
  routes as billingRoutes,
} from "./billing";

export const reducers = {
  auth,
  users,
  licenses,
  config,
  dashboards,
  ...logReducers,
  meters,
  alerts,
  devices,
  debugLogs,
  account,
  subscriptions,
  loginRecords,
  dailyRecords,
  billing,
};

export const routes = {
  ...userRoutes,
  ...licenseRoutes,
  ...authRoutes,
  ...logRoutes,
  ...debugLogsRoutes,
  ...subscriptionRoutes,
  ...loginRecordsRoutes,
  ...dailyRecordsRoutes,
  ...billingRoutes,
};

export function* sagas() {
  yield* authSaga();
  yield* historySaga();
  yield* usersSaga();
  yield* licenseSaga();
  yield* configSaga();
  yield* dashboardSaga();
  yield* metersSaga();
  yield* logSaga();
  yield* alertsSaga();
  yield* devicesSaga();
  yield* debugLogsSaga();
  yield* accountSaga();
  yield* subscriptionsSaga();
  yield* loginRecordsSaga();
  yield* dailyRecordsSaga();
  yield* billingSaga();
}
