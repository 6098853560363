import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Table,
  CardHeader,
} from "reactstrap";
import { NavLink } from "react-router-dom";

import Config from "Config";
const BASE_URL = Config.baseUrl;

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <NavLink to={"/"}>
            <img
              width="100"
              className="login-logo"
              src="img/elite-lab-icon.svg"
              alt="EliteLab® Logo"
            />
          </NavLink>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup className="mb-0">
                <Card className="p-4">
                  <CardBody className="card-body">
                    {/* <Row>
                      <Col>
                        <NavLink to={'/'}>
                          <Button color="link" className="px-3">&lt; Back to Login</Button>
                        </NavLink>
                      </Col>
                    </Row> */}
                    <Table>
                      <thead>
                        <tr>
                          <th className="downloads-page-header">
                            <h1>Downloads</h1>
                          </th>
                          <th className="downloads-page-header"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4 className="downloads-page-rows">
                              Windows 64-bit
                            </h4>
                          </td>
                          <td className="downloads-page-buttons">
                            <Button
                              name="windows"
                              href={`${BASE_URL}/installers/windows`}
                              color="primary"
                              className="px-4"
                              style={{ position: "right" }}
                            >
                              Download
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="downloads-page-rows">
                              Windows 64-bit Kiosk
                            </h4>
                          </td>
                          <td className="downloads-page-buttons">
                            <Button
                              name="windows"
                              href={`${BASE_URL}/installers/windows-kiosk`}
                              color="primary"
                              className="px-4"
                              style={{ position: "right" }}
                            >
                              Download
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="downloads-page-rows">Mac OS</h4>
                          </td>
                          <td className="downloads-page-buttons">
                            <Button
                              href={`${BASE_URL}/installers/mac`}
                              name="mac"
                              color="primary"
                              className="px-4"
                            >
                              Download
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="downloads-page-rows">
                              Ubuntu/Debian (Coming soon)
                            </h4>
                          </td>
                          <td className="downloads-page-buttons">
                            <Button
                              href={`${BASE_URL}/installers/linux64`}
                              name="linux64"
                              color="primary"
                              disabled
                              className="px-4"
                            >
                              Download
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <br />
        </Container>
      </div>
    );
  }
}

Downloads.propTypes = {};

export default Downloads;
