import { connect } from "react-redux";
import Lab from "../components/Lab";
import { actions } from "../modules/auth";
import { actions as deviceActions } from "../modules/devices";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return state.auth || {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadToken() {
      dispatch(actions.load_token());
    },
    logout(payload) {
      dispatch(actions.logout_request(payload));
    },
    refreshDevices() {
      dispatch(deviceActions.list_request());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lab);
