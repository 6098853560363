import { connect } from "react-redux";
import LoginRecords from "../views/LoginRecords";
import { actions } from "../modules/loginRecords";
import { actions as dailyRecordActions } from "../modules/dailyRecords";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return {
    loginRecords: _.get(state, "loginRecords.list", []),
    pagination: _.get(state, "loginRecords.pagination", []),
    totalLoginRecords: _.get(state, "loginRecords.totalLoginRecords", null),
    currentLoginRecord: _.get(state, "loginRecords.current", null),
    dailyRecords: _.get(state, "dailyRecords.list", null),
    averageDailyMinutes: _.get(state, "dailyRecords.averageDailyMinutes", null),
    averageDailyUsers: _.get(state, "dailyRecords.averageDailyUsers", null),
    averageMonthlyMinutes: _.get(
      state,
      "dailyRecords.averageMonthlyMinutes",
      null
    ),
    averageMonthlyUsers: _.get(state, "dailyRecords.averageMonthlyUsers", null),
    dailyRecordByDate: _.get(state, "dailyRecords.fromDate", null),
    dateRangeList: _.get(state, "dailyRecords.dateRangeList", null),
    dateRangeAverageDailyMinutes: _.get(
      state,
      "dailyRecords.dateRangeAverageDailyMinutes",
      null
    ),
    dateRangeAverageDailyUsers: _.get(
      state,
      "dailyRecords.dateRangeAverageDailyUsers",
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginRecords(payload) {
      dispatch(actions.list_request(payload));
    },
    searchLoginRecords(data) {
      dispatch(actions.list_request(data));
    },
    sortLoginRecords(data) {
      dispatch(actions.list_request(data));
    },
    deleteLoginRecord(data) {
      dispatch(actions.delete_request(data));
    },
    setCurrentLoginRecord(index) {
      dispatch(actions.set_current_login_record(index));
    },
    getDailyRecordByDate(data) {
      dispatch(dailyRecordActions.get_from_date_request(data));
    },
    getDailyRecordByDateRange(data) {
      dispatch(dailyRecordActions.get_from_date_range_request(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginRecords);
