import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import { Card, CardHeader, CardBody } from "reactstrap";

class LiveController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      value: null,
      inputFocus: false,
    };
  }

  componentDidMount() {
    const { serialId, meterId, onGetSetPoint } = this.props;
    onGetSetPoint({ serialId, meterId });

    let lastTime;
    const timer = setInterval(() => {
      const {
        serialId,
        meterId,
        onGetCurrentValue,
        active,
        logConfig,
        device,
      } = this.props;
      if (device.status !== "active") return;
      if (!active) return;

      const now = moment();
      const { interval } = logConfig;
      const duration = moment.duration(interval.value, interval.units);
      if (lastTime && now.isBefore(moment(lastTime).add(duration))) {
        return;
      }

      lastTime = now;
      onGetCurrentValue(serialId, meterId);
    }, 300);

    this.setState({
      timer,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  render() {
    const { currentValue, meter, device } = this.props;
    if (!meter) return null;

    const defaultName = `${meter.address} (${device.serialId})`;
    const meterName = `${device.name || device.model} - ${
      meter.name || defaultName
    }`;
    const hasValue = _.isFinite(currentValue);

    let theValue = "---";
    if (hasValue) {
      const decimals = meter.units == "mTorr" ? 0 : 2;
      theValue = parseFloat(currentValue.toFixed(decimals)).toString();
    }

    return (
      <Card className="live-controller">
        <div
          className="selected-color"
          style={{ backgroundColor: this.props.color }}
        ></div>
        <CardHeader>
          <div className="meter-name">{meterName}</div>
        </CardHeader>
        <CardBody className="quick-controller">
          <div className="screen" style={{ cursor: "pointer" }}>
            <span className={"current-reading font-4xl text-center"}>
              {theValue}{" "}
              <span className="meter-units">{hasValue ? meter.units : ""}</span>
            </span>
          </div>
        </CardBody>
      </Card>
    );
  }
}

LiveController.propTypes = {
  currentValue: PropTypes.number,
  setPoint: PropTypes.number,
  serialId: PropTypes.string,
  meterId: PropTypes.number,
  color: PropTypes.string,
  active: PropTypes.bool,
  onGetCurrentValue: PropTypes.func,
  logConfig: PropTypes.object,
  meter: PropTypes.object,
  device: PropTypes.object,
};

export default LiveController;
