import axios from "axios";
import FileDownload from "js-file-download";

import _ from "lodash";
import Config from "Config";

const BASE_URL = Config.baseUrl;
const API_URL = `${BASE_URL}${Config.apiPath}`;
let apiHttp = newHttp(API_URL);
let siteHttp = newHttp(BASE_URL);

function newHttp(base, headers) {
  const defaults = {
    baseURL: base,
  };

  if (headers) {
    defaults.headers = headers;
  }

  return axios.create(defaults);
}

export function setToken(token) {
  const headers = {
    Authorization: `Token ${token}`,
  };

  apiHttp = newHttp(API_URL, headers);
  siteHttp = newHttp(BASE_URL, headers);
}

export function removeToken() {
  apiHttp = newHttp(API_URL);
  siteHttp = newHttp(BASE_URL);
}

export function getHttp() {
  return apiHttp;
}

const api = {
  auth: {
    create_user(user) {
      return apiHttp.post("/users", { user }).then((d) => d.data);
    },
    create_user_with_license(params = {}) {
      const { key } = params;
      return apiHttp
        .post(`/licenses/${key}/user`, { user: params })
        .then((d) => d.data);
    },
    login(session) {
      return apiHttp.post("/sessions", { session }).then((d) => d.data);
    },
    logout(token) {
      return apiHttp.delete(`/sessions/${token}`);
    },
    reset_password(email) {
      return apiHttp.post("/reset-password/", { user: { email } });
    },
    check_token(token) {
      return apiHttp.get(`/reset-password/${token}`).then((d) => d.data);
    },
    change_password(payload) {
      const { password, password2, token } = payload;
      return apiHttp
        .post(`/new-password/${token}`, {
          user: { password: password, password_confirmation: password2 },
        })
        .then((d) => d.data);
    },
    verify_email(token) {
      return apiHttp.get(`/verify-email/${token}`);
    },
  },

  users: {
    all({ page = 1, search, sort, order } = {}) {
      const params = { page, search, sort, order };
      return apiHttp.get("/users", { params }).then((d) => d.data);
    },
    me() {
      return apiHttp.get("/me").then((d) => d.data);
    },
    update_me(data) {
      return apiHttp.put("/me", { user: data }).then((d) => d.data);
    },
    update(data = {}) {
      const { id } = data;
      return apiHttp.put(`/users/${id}`, { user: data }).then((d) => d.data);
    },
    create(data = {}) {
      return apiHttp.post("/users", { user: data }).then((d) => d.data);
    },
    delete(data = {}) {
      const { id } = data;
      return apiHttp.delete(`/users/${id}`).then((d) => d.data);
    },
    downloadCsv() {
      return siteHttp.get("/files/users").then((response) => {
        FileDownload(response.data, `users.csv`);
      });
    },
  },

  licenses: {
    all({ page = 1, search, sort, order } = {}) {
      const params = { page, search, sort, order };
      return apiHttp.get("/licenses", { params }).then((d) => d.data);
    },
    create(data = {}) {
      const { params } = data;
      return apiHttp.post("/licenses", { license: params }).then((d) => d.data);
    },
    update(data = {}) {
      const { key, params } = data;
      return apiHttp
        .post(`/licenses/${key}`, { license: params })
        .then((d) => d.data);
    },
    delete(data = {}) {
      const { key } = data;
      return apiHttp
        .delete(`/licenses/${key}`, { license: data })
        .then((d) => d.data);
    },
    bulkAdd(data = {}) {
      const { amount, batch } = data;
      return apiHttp
        .post("/licenses/bulk", { amount, batch })
        .then((d) => d.data);
    },
    bulkAddCsv(data = {}) {
      const { file, batch } = data;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("batch", batch);
      const headers = apiHttp.defaults.headers;
      const fileHtpp = newHttp(BASE_URL, {
        ...headers,
        "content-type": "multipart/form-data",
      });
      return fileHtpp.post("/files/licenses/", formData).then((d) => d.data);
    },
    downloadCsv(data = {}) {
      const { batch } = data;
      return siteHttp
        .get("/files/licenses", { params: { batch } })
        .then((response) => {
          FileDownload(response.data, `batch_${batch}.csv`);
        });
    },
    countUsed() {
      return apiHttp.get("/licenses/count").then((d) => d.data);
    },
    list_subscriptions() {
      return apiHttp.get("/subscriptions/admin").then((d) => d.data);
    },
  },

  config: {
    all() {
      return apiHttp.get("/config").then((d) => d.data);
    },
    post(payload) {
      return apiHttp.post("/config", payload).then((d) => d.data);
    },
    delete({ name }) {
      return apiHttp.delete(`/config/${name}`);
    },
    check_license({ license }) {
      return apiHttp.patch(`/licenses/${license}`);
    },
    get_licenses_from_user() {
      return apiHttp.get("/licenses/user").then((d) => d.data);
    },
    get_platform() {
      return apiHttp.get("/platform").then((d) => d.data);
    },
  },
  devices: {
    all() {
      return apiHttp.get("/devices").then((d) => d.data);
    },
    refresh() {
      return apiHttp.put("/devices").then((d) => d.data);
    },
    create(payload = {}) {
      return apiHttp.post("/devices", payload).then((d) => d.data);
    },
    update(payload = {}) {
      const { serialId } = payload;
      return apiHttp.put(`/devices/${serialId}`, payload).then((d) => d.data);
    },
    delete(serialId) {
      return apiHttp.delete(`/devices/${serialId}`);
    },
    getSingle({ serialId }) {
      return apiHttp.get(`/devices/${serialId}`).then((d) => d.data);
    },
    getStatus({ serialId }) {
      return apiHttp.get(`/devices/${serialId}/status`).then((d) => d.data);
    },
    getTemperature({ serialId, meterId }) {
      return apiHttp
        .get(`/devices/${serialId}/meters/${meterId}/temperature`)
        .then((d) => d.data);
    },
    getCurrentValue({ serialId, meterId }) {
      return apiHttp
        .get(`/devices/${serialId}/meters/${meterId}/current-value`)
        .then((d) => d.data);
    },
    getCurrentSetPoint({ serialId, meterId }) {
      return apiHttp
        .get(`/devices/${serialId}/meters/${meterId}/current-set-point`)
        .then((d) => d.data);
    },
    putCurrentSetPoint({ serialId, meterId, value }) {
      return apiHttp
        .put(`/devices/${serialId}/meters/${meterId}/current-set-point`, {
          value,
        })
        .then((d) => d.data);
    },
    putFeature({ serialId, meterId, value, featureName }) {
      return apiHttp
        .put(`/devices/${serialId}/meters/${meterId}/${featureName}`, { value })
        .then((d) => d.data);
    },
    getFeature({ serialId, meterId, featureName }) {
      return apiHttp
        .get(`/devices/${serialId}/meters/${meterId}/${featureName}`)
        .then((d) => d.data);
    },
    getSetPoint({ serialId, meterId }) {
      return apiHttp
        .get(`/devices/${serialId}/meters/${meterId}/set-point`)
        .then((d) => d.data);
    },
    putSetPoint({ serialId, meterId, value }) {
      return apiHttp
        .put(`/devices/${serialId}/meters/${meterId}/set-point`, { value })
        .then((d) => d.data);
    },
    putPowerLimit({ serialId, meterId, value }) {
      return apiHttp
        .put(`/devices/${serialId}/meters/${meterId}/power-limit`, { value })
        .then((d) => d.data);
    },
    startRamp({ serialId, meterId, rampId }) {
      return apiHttp.put(
        `/devices/${serialId}/meters/${meterId}/running-ramp`,
        { value: rampId }
      );
    },
    changeRampStatus({ serialId, meterId, status }) {
      return apiHttp.patch(
        `/devices/${serialId}/meters/${meterId}/running-ramp`,
        { status }
      );
    },
    getStirRate({ serialId, meterId }) {
      return apiHttp
        .get(`/devices/${serialId}/meters/${meterId}/stir-rate`)
        .then((d) => d.data);
    },
    getStirRateSetPoint({ serialId, meterId }) {
      return apiHttp
        .get(`/devices/${serialId}/meters/${meterId}/stir-rate-sp`)
        .then((d) => d.data);
    },
    putStirRate({ serialId, meterId, value }) {
      return apiHttp
        .get(`/devices/${serialId}/meters/${meterId}/stir-rate`, { value })
        .then((d) => d.data);
    },
    resync({ serialId }) {
      return apiHttp.patch(`/devices/${serialId}`);
    },
    exists_serial(number) {
      // NOTE: Goes to Web back-end
      return apiHttp.get(`/serial_number/${number}`);
    },
    register(number) {
      return apiHttp.get(`/devices/${number}`);
    },
  },

  debugLogs: {
    all({ page = 1, search, sort, order } = {}) {
      const params = { page, search, sort, order };
      return apiHttp.get("/debug-logs", { params }).then((d) => d.data);
    },
    delete(data = {}) {
      const { id } = data;
      return apiHttp.delete(`/debug-logs/${id}`).then((d) => d.data);
    },
    download(data) {
      const { id } = data;
      return siteHttp.get(`/files/debug-logs/${id}`).then((response) => {
        window.location = response.data;
      });
    },
  },
  payments: {
    list_subscriptions() {
      return apiHttp.get("/subscriptions").then((d) => d.data);
    },
    set_subscription({ subscription, licenseKey }) {
      return apiHttp
        .post(`/stripe/subscriptions/${subscription}`, { key: licenseKey })
        .then((d) => d.data);
    },
    rm_subscription(licenseKey) {
      return apiHttp.delete(`/stripe/subscriptions/${licenseKey}`);
    },
    add_customer(token) {
      return apiHttp.post(`/stripe/customers/${token}`);
    },
    charges() {
      return apiHttp.get("/stripe/charges").then((d) => d.data.data);
    },
  },

  subscriptions: {
    list() {
      return apiHttp.get("/subscriptions").then((d) => d.data);
    },
    create(data = {}) {
      const { params } = data;
      return apiHttp
        .post("/subscriptions", { subscription: params })
        .then((d) => d.data);
    },
    update(data = {}) {
      const { id, params } = data;
      return apiHttp
        .post(`/subscriptions/${id}`, { subscription: params })
        .then((d) => d.data);
    },
    delete(data = {}) {
      const { id } = data;
      return apiHttp.delete(`/subscriptions/${id}`).then((d) => d.data);
    },
  },
  loginRecords: {
    all({ page = 1, search, sort, order } = {}) {
      const params = { page, search, sort, order };
      return apiHttp.get("/login-records", { params }).then((d) => d.data);
    },
    count() {
      return apiHttp.get("/login-records/count").then((d) => d.data);
    },
    delete(data = {}) {
      const { id } = data;
      return apiHttp.delete(`/login-records/${id}`).then((d) => d.data);
    },
  },
  dailyRecords: {
    all() {
      return apiHttp.get("/daily-records").then((d) => d.data);
    },
    get_from_date({ date }) {
      return apiHttp.get(`/daily-records/${date}`).then((d) => d.data);
    },
    get_from_date_range({ startDate, endDate }) {
      return apiHttp
        .get(`/daily-records/range/?startDate=${startDate}&endDate=${endDate}`)
        .then((d) => d.data);
    },
    delete(data = {}) {
      const { id } = data;
      return apiHttp.delete(`/daily-records/${id}`).then((d) => d.data);
    },
  },
  billing: {
    list_all_stripe_charges({ page = 1, search, sort, order } = {}) {
      const params = { page, search, sort, order };
      return apiHttp.get("/stripe/charges/all", { params }).then((d) => d.data);
    },
  },
};

export default api;
