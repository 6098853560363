import _ from "lodash";
import React from "react";
import { takeEvery, put, call, select, take } from "redux-saga/effects";
import { delay } from "redux-saga";
import moment from "moment";

import { createAction, createReducer, actionNames } from "../tools";
import { actions as alerts } from "./alerts";
import { history } from "./history";
import api from "../api";

export const actions = createAction(
  "cirrus/modules/config",
  "REQUEST",
  "REQUEST_SUCCESS",
  "REQUEST_ERROR",
  "CHANGE",
  "CHANGE_SUCCESS",
  ...actionNames("LICENSE_CHECK"),
  ...actionNames("GET_LICENSES"),
  ...actionNames("DELETE"),
  ...actionNames("LOCAL_LICENSE_CHECK"),
  ...actionNames("CHECK_VERSION"),
  "LICENSE_GOOD",
  "LICENSE_EXPIRED",
  "UPDATE_CURRENT_LICENSE"
);

function checkingLicenseStatus(status, state) {
  return {
    ...state,
    checkingLicense: status,
  };
}

export default createReducer(
  {
    [actions.REQUEST_SUCCESS]: (state, action) => {
      const { items } = action.payload;

      const old = {
        configRetrieved: true,
        licenses: state.licenses,
      };

      return _.reduce(
        items,
        (r, i) => {
          r[i.name] = i.value;
          return r;
        },
        old
      );
    },
    [actions.REQUEST]: (state) => {
      return {
        configRetrieved: false,
        ...state,
      };
    },
    [actions.CHANGE_SUCCESS]: (state, action) => {
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value,
      };
    },
    [actions.DELETE_SUCCESS]: (state) => {
      return {
        ...state,
      };
    },
    [actions.GET_LICENSES_REQUEST]: (state) => {
      return {
        ...state,
        licensesRetrieved: false,
        licenseExpired: false,
      };
    },
    [actions.GET_LICENSES_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        licenses: data,
        licensesRetrieved: true,
      };
    },
    [actions.LICENSE_EXPIRED]: (state) => {
      return {
        ...state,
        licenseExpired: true,
      };
    },

    [actions.LICENSE_CHECK_REQUEST]: checkingLicenseStatus.bind(null, true),
    [actions.LICENSE_CHECK_ERROR]: checkingLicenseStatus.bind(null, false),
    [actions.LICENSE_CHECK_SUCCESS]: checkingLicenseStatus.bind(null, false),
    [actions.LICENSE_GOOD]: checkingLicenseStatus.bind(null, false),
  },
  {}
);

function* licenseGood() {
  yield put(actions.license_check_success());
  if (localStorage.getItem("auth")) {
    history.push("/dashboard");
  } else {
    history.push("/login");
  }
}

function* saveLicenseDetails({ key, expiration_date, subscription_name }) {
  yield call(api.config.post, {
    name: "license",
    section: "program",
    value: key,
  });
  yield call(api.config.post, {
    name: "licenseExpiration",
    section: "program",
    value: moment(expiration_date),
  });
  yield call(api.config.post, {
    name: "subscriptionName",
    section: "program",
    value: subscription_name,
  });
}

function* licenseCheck({ payload }) {
  const { license } = payload;
  yield put(
    alerts.warning({
      message: "Checking license, it may take a moment",
      time: 5,
    })
  );
  try {
    const { data } = yield call(api.config.check_license, payload);
    const { expiration_date } = data;
    yield* saveLicenseDetails(data);
    yield put(actions.request());
    if (moment().diff(expiration_date, "days") >= 0) {
      //License has expired
      yield put(actions.license_expired());
      history.push("/account");
      return;
    }
    yield put(alerts.success({ message: "Thank you, valid license", time: 3 }));
    yield delay(1000);
    yield put(actions.license_good());
    yield put(actions.license_check_success());
  } catch (e) {
    yield put(actions.license_check_error());
    const status = _.get(e, "response.status");
    if (status == 404) {
      yield put(alerts.error({ message: `${license} does not exist!` }));
    } else {
      yield put(
        alerts.error({
          message: "Some error ocurred on the server, please retry later",
        })
      );
    }
    console.log("ERROR ---> license", e, license);
  }
}

function* update_current_license({ payload }) {
  const state = yield select((s) => s.config);
  let current = _.find(payload, (l) => l.key === state.license);
  if (!current) {
    yield put(alerts.error({ message: "Your license doesn't exist" }));
    //history.push('/license')
    // TODO: we need to remove license
    return;
  }

  try {
    yield* saveLicenseDetails(current);
    yield put(actions.request());
  } catch (e) {
    console.log("Error while updating license", e);
  }
}

function* list() {
  try {
    const data = yield call(api.config.all);
    yield put(actions.request_success(data));
  } catch (e) {
    yield put(actions.request_error());
  }
}

function* change({ payload }) {
  try {
    const data = yield call(api.config.post, payload);
    yield put(actions.change_success(data));
  } catch (e) {
    yield put(actions.error({ message: "unable to change temperature" }));
  }
}

function* delete_request({ payload }) {
  try {
    yield call(api.config.delete, payload);
    yield put(actions.delete_success());
  } catch (e) {
    yield put(actions.delete_error({ message: "error deleting config" }));
  }
}

function* getLicenses() {
  yield put(actions.request());
  yield take(actions.REQUEST_SUCCESS);
  const configState = (state) => state.config;
  const { licenseExpiration } = yield select(configState);
  if (licenseExpiration) {
    const licenseExpired = moment().diff(licenseExpiration, "days");
    if (licenseExpired < 0) {
      yield put(actions.license_good());
      return;
    } else {
      yield put(actions.license_expired());
    }
  }
  try {
    const data = yield call(api.config.get_licenses_from_user);
    yield put(actions.get_licenses_success(data));
  } catch (e) {
    yield put(actions.get_licenses_error(e));
  }
}

function* licenseExpired() {
  yield put(actions.license_check_error());
  yield put(
    alerts.error({ message: "License expired. Please renew to continue" })
  );
}

function* checkVersion() {
  try {
    const data = yield call(api.config.get_platform);
    const payload = yield call(api.installers.check_version, data);
    const installer = payload.data;
    if (installer) {
      //TODO: Add links to alerts to change this span below
      yield put(
        alerts.warning({
          message: (
            <span>
              There is a newer version available:{" "}
              <a href={installer.link}>Download</a>
            </span>
          ),
          time: 300,
        })
      );
    }
    yield put(actions.check_version_success());
  } catch (e) {
    console.log("Error while getting version", e);
    yield put(actions.check_version_error());
  }
}

export function moduleSave(moduleName, timeDelay) {
  return function* _moduleSave() {
    if (timeDelay) {
      yield delay(timeDelay);
    }
    const module = yield select((s) => _.get(s, moduleName));
    try {
      yield call(api.config.post, {
        name: moduleName,
        section: "hidden",
        value: JSON.stringify(module),
      });
    } catch (e) {
      console.log("Error saving module", e);
    }
  };
}

/* This function is made to be used together with createReducer
 * and it need to be used like this
 *
 * export default createReducer({
 *   ...moduleLoadReducers('dashboards'),
 * }, {})
 *
 * this will create the reducers required
 * */
export function moduleLoadReducers(moduleName) {
  return {
    [actions.REQUEST_SUCCESS]: (state, action) => {
      const { items } = action.payload;
      const config = _.find(items, (i) => i.name == moduleName);
      if (!config) {
        return state;
      }
      const value = JSON.parse(config.value);
      return (config.value && value) || state;
    },
  };
}

export function* mainSaga() {
  yield takeEvery(actions.REQUEST, list);
  yield takeEvery(actions.CHANGE, change);
  yield takeEvery(actions.LICENSE_GOOD, licenseGood);
  yield takeEvery(actions.LICENSE_CHECK_REQUEST, licenseCheck);
  yield takeEvery(actions.GET_LICENSES_REQUEST, getLicenses);
  yield takeEvery(actions.DELETE_REQUEST, delete_request);
  yield takeEvery(actions.LICENSE_EXPIRED, licenseExpired);
  yield takeEvery(actions.CHECK_VERSION_REQUEST, checkVersion);
  yield takeEvery(actions.UPDATE_CURRENT_LICENSE, update_current_license);
}
