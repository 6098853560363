import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import Footer from "./Footer/";
import Sidebar from "./Sidebar";
import { Redirect } from "react-router-dom";

class Lab extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { refreshDevices } = this.props;
    // this.timer = setInterval(() => {
    //   refreshDevices()
    // }, 10000)
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { children, user, logout, location } = this.props;

    let pathFound = false;
    for (let child of children) {
      if (child.props.path == location.pathname) {
        pathFound = true;
        break;
      }
    }
    if (!pathFound && location.pathname != "/") {
      return <Redirect to="/404" />;
    }

    if (!user) {
      return <Redirect to="/login" />;
    } else {
      return (
        <React.Fragment>
          <div className="app">
            <div className="app-body">
              <Sidebar user={user} logout={logout} />
              <main className="main">
                <Container fluid>{children}</Container>
              </main>
            </div>
            <Footer />
          </div>
        </React.Fragment>
      );
    }
  }
}

Lab.propTypes = {
  children: PropTypes.array,
  user: PropTypes.object,
  loadToken: PropTypes.func,
  logout: PropTypes.func,
  refreshDevices: PropTypes.func,
};

export default Lab;
