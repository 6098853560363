import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  FormGroup,
  InputGroupAddon,
  Form,
  FormFeedback,
  InputGroupText,
  Tooltip,
} from "reactstrap";

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password2: "",
      tooltipOpen: false,
    };
    this.handlePassword = this.handleChangeValue.bind(this, "password");
    this.handlePassword2 = this.handleChangeValue.bind(this, "password2");
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  handleChangeValue(name, e) {
    this.setState({
      [name]: e.target.value,
    });
  }

  isPasswordOK() {
    return this.state.password == this.state.password2;
  }

  isPasswordValid() {
    const { password, password2 } = this.state;
    return !!(password && password2);
  }

  handlePasswordSubmit(e) {
    e.preventDefault();
    const token = this.props.token;
    const { password, password2 } = this.state;
    this.props.changePassword({ password, password2, token });
  }
  renderErrors(errors) {
    if (!errors) return;
    let bundled = [];
    for (let error of errors) {
      bundled.push(
        <div className="text-danger form-control-feedback">{error}</div>
      );
    }
    return bundled;
  }
  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  newPasswordRender() {
    const { password, password2 } = this.state;

    let passwordColor = this.isPasswordOK() ? "text-success" : "text-danger";

    const isValid = this.isPasswordValid();
    const errors = this.props.errors || {};
    const passwordError = _.get(errors, "messages.password");
    const passwordConfirmationError = _.get(
      errors,
      "messages.password_confirmation"
    );

    return (
      <CardBody className="card-body p-4">
        <h1>Reset Password</h1>
        <p className="text-muted">Please enter your new password</p>
        <Form>
          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                id="password"
                className={passwordColor}
                type="password"
                placeholder="New Password"
                value={password}
                onChange={this.handlePassword}
              />
            </InputGroup>
            {this.renderErrors(passwordError)}
            <Tooltip
              placement="right"
              isOpen={this.state.tooltipOpen}
              target="password"
              toggle={this.toggleTooltip}
            >
              Password should be at least 8 characters long and have one
              upper-case letter, one lower-case letter and one number.
            </Tooltip>
            <FormFeedback className="text-danger">{passwordError}</FormFeedback>
          </FormGroup>
          <InputGroup className="mb-4">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className={passwordColor}
              type="password"
              placeholder="Repeat password"
              value={password2}
              onChange={this.handlePassword2}
            />
          </InputGroup>
          {this.renderErrors(passwordConfirmationError)}
          <Button
            type="submit"
            color="success"
            disabled={!isValid}
            onClick={this.handlePasswordSubmit}
            block
          >
            Submit
          </Button>
        </Form>
      </CardBody>
    );
  }
  loadingRender() {
    return (
      <CardBody className="card-body p-4">
        <h1>Reset Password</h1>
        <p className="text-muted">Loading, please wait</p>
      </CardBody>
    );
  }

  passwordChangedRender() {
    return (
      <CardBody className="card-body p-4">
        <h3>
          Password successfully changed! Please log in with your new password on
          your EliteLab app.
        </h3>
        <NavLink to={"/downloads"}>
          <Button color="success">Downloads</Button>
        </NavLink>
      </CardBody>
    );
  }

  render() {
    let body;
    if (this.props.passwordChanged) {
      body = this.passwordChangedRender();
    } else if (this.props.tokenAccepted) {
      body = this.newPasswordRender();
    } else {
      body = this.loadingRender();
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">{body}</Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

NewPassword.propTypes = {
  resetPassword: PropTypes.func,
  newPassword: PropTypes.func,
  errors: PropTypes.object,
  tokenAccepted: PropTypes.bool,
  token: PropTypes.string,
};

export default NewPassword;
