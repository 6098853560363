export default {
  items: [
    /*  {
      name: 'My Lab',
      url: '/controllers',
      icon: 'icon-beaker',
    },
    {
      name: 'Temp. Control',
      url: '/temperature/dashboard',
      icon: 'icon-thermometer',
      children: [
        {
          name: 'Dashboard',
          url: '/temperature'
        },
        {
          name: 'Controllers',
          url: '/controllers'
        },
        {
          name: 'Ramps',
          url: '/ramps'
        },
        {
          name: 'Logs',
          url: '/logs'
        }
      ]
    },
    {
      name: 'Config',
      url: '/config',
      icon: 'icon-cog',
    },
    {
      name: 'Device Manager',
      url: '/device-manager',
      icon: 'icon-laptop-phone'
    },
    */
    {
      name: "Administration",
      url: "/admin",
      icon: "icon-key",
      children: [
        {
          name: "Users",
          url: "/users",
        },
        {
          name: "License Keys",
          url: "/licenses",
        },
        {
          name: "Debug Logs",
          url: "/debug-logs",
        },
        {
          name: "Susbcriptions",
          url: "/subscriptions",
        },
        {
          name: "Login Records",
          url: "/login-records",
        },
        {
          name: "Billing",
          url: "/billing",
        },
      ],
    },
  ],
};
