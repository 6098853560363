import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import LiveController from "../containers/LiveController";

class ControllerAndRamp extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { meter, toggleCollapse } = this.props;
    const { serialId, address, name } = meter;
    toggleCollapse({ serialId, meterId: address, name });
  }

  render() {
    const { meter } = this.props;
    const { serialId, address, name, key } = meter;

    return (
      <span className="controller-container d-flex">
        <LiveController
          key={key}
          serialId={serialId}
          meterId={address}
          collapse={meter.collapse}
          toggleCollapse={this.toggle}
          name={name}
        />
      </span>
    );
  }
}

ControllerAndRamp.propTypes = {
  meter: PropTypes.obj,
  toggleCollapse: PropTypes.func,
};

export default ControllerAndRamp;
