import { connect } from "react-redux";
import DebugLogs from "../views/DebugLogs";
import { actions } from "../modules/debugLogs";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return {
    debugLogs: _.get(state, "debugLogs.list", []),
    pagination: _.get(state, "debugLogs.pagination", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDebugLogs(payload) {
      dispatch(actions.list_request(payload));
    },
    deleteDebugLog(data) {
      dispatch(actions.delete_request(data));
    },
    searchDebugLogs(data) {
      dispatch(actions.list_request(data));
    },
    downloadDebugLog(data) {
      dispatch(actions.download_request(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DebugLogs);
