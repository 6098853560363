import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import _ from "lodash";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      password: "",
    };
    this.handleEmailChange = this.handleChange.bind(this, "email");
    this.handlePasswordChange = this.handleChange.bind(this, "password");
    this.onClick = this.onClick.bind(this);
  }

  handleChange(name, e) {
    this.setState({
      [name]: e.target.value,
    });
  }

  onClick(e) {
    e.preventDefault();
    this.props.login(this.state);
  }

  renderErrors(errors) {
    if (!errors) return;
    let bundled = [];
    for (let error of errors) {
      bundled.push(
        <div className="text-danger form-control-feedback">{error}</div>
      );
    }
    return bundled;
  }

  render() {
    const { email, password } = this.state;
    const errors = _.get(this.props, "errors.messages", {});
    const passwordError = _.get(errors, "password");
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <NavLink to={"/"}>
            <img
              width="100"
              className="login-logo"
              src="img/elite-lab-icon.svg"
              alt="EliteLab® Logo"
            />
          </NavLink>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup className="mb-0">
                <Card className="p-4">
                  <CardBody className="card-body">
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <Form onSubmit={this.onClick}>
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Email"
                            value={email || ""}
                            onChange={this.handleEmailChange}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Password"
                            value={password || ""}
                            onChange={this.handlePasswordChange}
                          />
                        </InputGroup>
                        {this.renderErrors(passwordError)}
                      </FormGroup>
                      <Row>
                        <Col xs="6" className="text-left">
                          <Button
                            type="submit"
                            color="primary"
                            className="px-4"
                          >
                            Login
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <NavLink to={"/reset-password"}>
                            <Button color="link" className="px-0">
                              Forgot password?
                            </Button>
                          </NavLink>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card
                  className="text-white bg-primary py-5 d-md-down-none"
                  style={{ width: 44 + "%" }}
                >
                  <CardBody className="card-body text-center">
                    <div>
                      <h2>Download App</h2>
                      <p>
                        Click on the link below to redirect you to our downloads
                        page:{" "}
                      </p>
                      <NavLink to={"/downloads"}>
                        <Button color="primary" className="px-4" active>
                          Downloads
                        </Button>
                      </NavLink>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <br />
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func,
  errors: PropTypes.object,
};

export default Login;
