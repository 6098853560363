import { takeEvery, put, call } from "redux-saga/effects";
import moment from "moment";
import _ from "lodash";

import { createAction, createReducer, actionNames } from "../tools";
import { actions as meterActions } from "./meters";
import * as allActions from "../actions";
import api from "../api";

export const routes = {
  *"/dashboard"() {
    yield put(allActions.requestDevices());
  },
};

export const actions = createAction(
  "cirrus/module/log",
  "TICK",
  ...actionNames("LIST"),
  ...actionNames("SINGLE"),
  ...actionNames("DELETE"),
  ...actionNames("START"),
  ...actionNames("STOP")
);

function secondsToHuman(seconds) {
  let minutes = Math.trunc(seconds / 60);
  seconds = seconds % 60;
  let hours = Math.trunc(minutes / 60);
  minutes = minutes % 60;
  // if (hours && seconds) return `${hours}h${minutes}min ${seconds}s`
  if (hours && minutes) return `${hours}h ${minutes}min`;
  if (hours) return `${hours}h`;
  if (minutes && seconds) return `${minutes}min ${seconds}s`;
  if (minutes) return `${minutes}min`;
  return `${seconds}s`;
}

function convertPoints(points, name) {
  const firstTime = new Date(points && points[0] && points[0].datetime);
  return _.map(points || [], (p) => {
    const seconds = (new Date(p.datetime) - firstTime) / 1000;
    const minutes = seconds / 60;
    const x = parseFloat(minutes.toFixed(2), 10);
    return {
      y: p[name],
      x,
      comments: p.comments,
    };
  });
}

function toMinutes(ms) {
  return toSeconds(ms) / 60;
}

function toSeconds(ms) {
  return ms / 1000;
}

function toHours(ms) {
  return toMinutes(ms) / 60;
}

const TIME_TRANSFORM_FUNCTIONS = {
  second: toSeconds,
  minute: toMinutes,
  hour: toHours,
};

function pointsToXY(points, units) {
  if (!points.length) return points;
  const f = TIME_TRANSFORM_FUNCTIONS[units];
  const firstTime = points[0].time;
  return _.map(points, (p) => {
    if (!p) return null;
    const ms = p.time - firstTime;
    return {
      y: p.value,
      x: f(ms),
      time: p.time,
      comments: p.comments,
    };
  });
}

function getCurrentPoints(points, duration) {
  if (!duration) return [];
  if (!(points && points.length)) return [];
  if (points.length < 2) return points;

  const originalPoints = points;
  const firstTime = moment().subtract(duration.value, duration.units);
  let firstIndex = _.findIndex(points, (p) => {
    return firstTime.isBefore(p.time);
  });

  // All the points are visible in the plot
  if (points.length && firstIndex < 0) {
    firstIndex = 0;
  }

  // pick all the visible points
  points = _.slice(points, firstIndex);
  // if we have only one point after left border
  // try to pick the time before that one
  const firstPoint = points[0];
  if (points.length == 1 && originalPoints.length > 1) {
    // if there is only 1 point, we must repeat the same value, in order
    // to keep an strigth line
    points = [firstPoint, firstPoint];
  }

  const beforeFirst = originalPoints[firstIndex - 1];
  let fakePoint = { value: firstPoint.value, time: firstTime };
  let fakePoints;

  if (beforeFirst) {
    // Calculate slope if different before the not visible part
    // and the visible part
    if (beforeFirst.value != firstPoint.value) {
      // Get the slope of the last point and the next one
      const slope =
        (firstPoint.value - beforeFirst.value) /
        (firstPoint.time - beforeFirst.time);
      // Calculate the current point using the slope
      fakePoint.value =
        beforeFirst.value + (firstTime - beforeFirst.time) * slope;
    }

    fakePoints = [fakePoint];
  } else {
    // There are no points before the first Index
    // then we need a blank space
    fakePoints = [fakePoint, null];
  }

  return [...fakePoints, ...points];
}

function getMinMaxValues(points, range) {
  const yTicks = {
    min: undefined,
    max: undefined,
  };

  let min = 0,
    max = 1;
  // Y Min and Max
  if ((points.length && _.isUndefined(range.min)) || range.min == "auto") {
    min = yTicks.suggestedMin = (_.minBy(points, "y") || {}).y;
  } else {
    min = yTicks.min = range.min;
  }

  if ((points.length && _.isUndefined(range.max)) || range.max == "auto") {
    max = yTicks.suggestedMax = (_.maxBy(points, "y") || {}).y;
  } else {
    max = yTicks.max = range.max;
  }

  let pctDiff = (max - min) * 0.1;
  // pctDiff = (pctDiff == 0) ? 1 : pctDiff

  if (pctDiff < 1) {
    pctDiff = 1;
  }

  if (!_.isUndefined(yTicks.suggestedMin)) {
    yTicks.suggestedMin -= pctDiff;
  }

  if (!_.isUndefined(yTicks.suggestedMax)) {
    yTicks.suggestedMax += pctDiff;
  }

  const minX = ((points && points[0]) || {}).x || 0;
  const maxX = ((points && _.last(points)) || {}).x || 1;

  return {
    yTicks,
    minX,
    maxX,
  };
}

export function toPlotData(points, duration, range) {
  points = getCurrentPoints(points, duration);
  points = pointsToXY(points, duration.units);
  const minMax = getMinMaxValues(points, range);

  return {
    minX: 0,
    maxX: 1,
    points,
    xUnits: duration.units,
    ...minMax,
  };
}

export function prepareLog(log) {
  log.values = convertPoints(log.entries, "value");
  log.setPoints = convertPoints(log.entries, "set_point");
  log.min =
    _.min([_.minBy(log.values, "y"), _.minBy(log.setPoints, "y")]).y || 0;
  log.max =
    _.max([_.maxBy(log.values, "y"), _.maxBy(log.setPoints, "y")]).y || 0;
  return log;
}

const defaultState = {};

const log = createReducer(
  {
    [meterActions.CURRENT_VALUE_SUCCESS]: (state, action) => {
      const { serialId, meterId, result } = action.payload;
      const value = result;
      const key = `${serialId}-${meterId}`;
      let points = _.get(state, [key, "points"], []);

      let olderPoints = [];
      let newerPoints = points;

      if (points.length > 6) {
        olderPoints = _.slice(points, 0, points.length - 5);
        newerPoints = _.slice(points, points.length - 5);
      }

      points = _.filter(newerPoints, (p) => p.keep);
      //
      const last = _.last(points);
      const lastBefore = _.nth(points, -2);

      const sameLast = last && last.value == value;
      const sameLastBefore = lastBefore && lastBefore.value == value;
      const hasComments = !!(last && last.comments);
      let deleted = false;

      // Check if the last two values are different
      if (
        last &&
        ((sameLast && sameLastBefore && !hasComments) || !last.keep)
      ) {
        points.pop();
        deleted = true;
      }

      // do we need this?
      if (!deleted && lastBefore && !lastBefore.keep && !hasComments) {
        points.pop();
        deleted = true;
      }

      // we remove if the diff angle is lees thant 5 deg
      if (!deleted && last && lastBefore && !hasComments) {
        const degrees = Math.atan(value - last.value) * (180 / Math.PI);
        const lastDegrees =
          Math.atan(last.value - lastBefore.value) * (180 / Math.PI);
        const diff = Math.abs(degrees - lastDegrees);
        const sameDirection = !(
          (degrees ? degrees / degrees : 0) -
          (lastDegrees ? lastDegrees / lastDegrees : 0)
        );
        if (sameDirection && diff <= 5 && !lastBefore.comments) {
          points.pop();
        }
      }

      const oldData = _.get(state, key, {});
      const min = (_.minBy(points, "value") || {}).value;
      const max = (_.maxBy(points, "value") || {}).value;

      let newPoints = [
        ...olderPoints,
        ...points,
        { time: new Date(), value, keep: true },
      ];

      const data = {
        ...oldData,
        min,
        max,
        points: newPoints,
      };

      return {
        ...state,
        [key]: data,
      };
    },
    [actions.TICK]: (state, action) => {
      /* TICK deletes the last fake point and adds a new one with current date
        to simulate the plot is moving forward in time and avoid having unnecessary
        points
    */
      const { serialId, meterId } = action.payload;
      const key = `${serialId}-${meterId}`;
      const oldData = _.get(state, key, {});
      const oldPoints = _.get(oldData, "points", []);
      if (!oldPoints.length) return state;
      const last = _.last(oldPoints);
      // const lastBefore = _.nth(oldPoints, -2)
      if (!last) return state;
      if (!last.keep) {
        oldPoints.pop();
      }

      const points = [...oldPoints, { time: new Date(), value: last.value }];

      const data = {
        ...oldData,
        points,
      };

      return {
        ...state,
        [key]: data,
      };
    },
    [actions.SINGLE_SUCCESS]: (state, action) => {
      const log = prepareLog(_.get(action, "payload", {}));
      return {
        ...state,
        log,
      };
    },
  },
  defaultState
);

const logs = createReducer(
  {
    [actions.LIST_SUCCESS]: (state, action) => {
      const items = _.map(_.get(action, "payload.items", []), (l) => {
        l.human_total_time = secondsToHuman(l.total_time);
        l.start_datetime = moment(new Date(l.start_datetime));
        return l;
      });

      return {
        ...state,
        ...action.payload,
        items,
      };
    },

    [actions.DELETE_SUCCESS]: (state, action) => {
      const id = action.payload.id;
      return {
        ...state,
        items: _.omitBy(state.items, (l) => l.id == id),
      };
    },
  },
  {}
);

export default {
  log,
  logs,
};

function* list_logs({ payload = {} }) {
  const data = yield call(api.logs.all, payload);
  yield put(actions.list_success(data));
}

function* get_log({ payload }) {
  const data = yield call(api.logs.get, payload);
  yield put(actions.single_success(data));
}

function* delete_log({ payload }) {
  yield call(api.logs.delete, payload);
  yield put(actions.delete_success(payload));
}

export function* mainSaga() {
  yield takeEvery(actions.LIST_REQUEST, list_logs);
  yield takeEvery(actions.SINGLE_REQUEST, get_log);
  yield takeEvery(actions.DELETE_REQUEST, delete_log);
}
