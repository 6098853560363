import { connect } from "react-redux";
import Billing from "../views/Billing";
import { actions } from "../modules/billing";

const mapStateToProps = (state = {}) => {
  return {
    charges: _.get(state, "billing.list", []),
    pagination: _.get(state, "billing.pagination", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCharges(payload) {
      dispatch(actions.list_stripe_charges_request(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
