import { takeEvery, put, call } from "redux-saga/effects";
import { createAction, createReducer, actionNames } from "../tools";
import api from "../api";
import _ from "lodash";

export const actions = createAction(
  "cirrus/modules/billing",
  ...actionNames("LIST_STRIPE_CHARGES")
);

const defaultState = {
  list: [],
  params: {
    page: 1,
    per_page: 50,
  },
  current: null,
};

export default createReducer(
  {
    [actions.LIST_STRIPE_CHARGES_SUCCESS]: (state, action) => {
      const { pagination, data } = action.payload;
      return {
        ...state,
        list: data,
        pagination,
      };
    },
  },
  defaultState
);

function* list_stripe_charges({ payload }) {
  try {
    const data = yield call(api.billing.list_all_stripe_charges, payload);
    yield put(actions.list_stripe_charges_success(data));
  } catch (e) {
    yield put(actions.list_stripe_charges_error(e));
  }
}

export const routes = {
  *"/billing"() {
    yield put(actions.list_stripe_charges_request());
  },
};

export function* mainSaga() {
  yield takeEvery(actions.LIST_STRIPE_CHARGES_REQUEST, list_stripe_charges);
}
