import PropTypes from "prop-types";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Col,
  Row,
  InputGroupText,
} from "reactstrap";

class LicenseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      used: false,
      startDate: moment().format(moment.HTML5_FMT.DATE),
      duration: 0,
      email: "",
      subscription: "",
      should_update_date: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentWillReceiveProps(props) {
    const { currentLicense } = props;
    this.setState({
      comment: (currentLicense && currentLicense.comment) || "",
      used: currentLicense ? currentLicense.used : false,
      startDate: currentLicense
        ? currentLicense.start_date
        : moment().format(moment.HTML5_FMT.DATE),
      expirationDate: currentLicense
        ? currentLicense.expiration_date
        : moment().format(moment.HTML5_FMT.DATE),
      duration: currentLicense ? currentLicense.days_duration : 0,
      email: currentLicense ? currentLicense.email : "",
      subscription: currentLicense ? currentLicense.subscription_id : "",
      should_update_date: currentLicense
        ? currentLicense.should_update_date
        : true,
      user: currentLicense ? currentLicense.user : "",
    });
    if (props.isOpen) return;
  }
  onSave() {
    const { onSave, currentLicense } = this.props;
    const {
      comment,
      used,
      duration,
      startDate,
      email,
      subscription,
      expirationDate,
      should_update_date,
      user,
    } = this.state;
    let data = {
      params: {
        comment: comment,
        used: used,
        days_duration: duration,
        start_date: startDate,
        expiration_date: expirationDate,
        email: email,
        subscription_id: subscription,
        should_update_date: should_update_date,
        user,
      },
      key: currentLicense && currentLicense.key,
    };
    onSave(data);
    this.props.toggle();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleCheck(e) {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  }
  createSubscriptionOptions() {
    const { subscriptionList } = this.props;
    if (!subscriptionList) {
      return <option></option>;
    }
    let options = [];
    options.push(
      <option key="" value="" disabled defaultValue>
        Select a subscription
      </option>
    );
    for (let subscription of subscriptionList) {
      options.push(
        <option key={subscription.name} value={subscription.id}>
          {subscription.name}{" "}
        </option>
      );
    }
    return options;
  }

  renderShow() {
    const { className, isOpen, toggle, currentLicense } = this.props;
    if (currentLicense) {
      return (
        <Modal isOpen={isOpen} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>Show License</ModalHeader>
          <ModalBody>
            <ul>
              <li>
                <strong>Key: </strong>
                {currentLicense.key}
              </li>
              <li>
                <strong>Email sent to: </strong>
                {currentLicense.email}
              </li>
              <li>
                <strong>User: </strong>
                {currentLicense.user}
              </li>
              <li>
                <strong>Subscription: </strong>
                {currentLicense.subscription}
              </li>
              <li>
                <strong>Used: </strong>
                {currentLicense.used ? "Yes" : "No"}
              </li>
              <li>
                <strong>Batch ID: </strong>
                {currentLicense.batch ? currentLicense.batch : " --- "}
              </li>
              <li>
                <strong>Start Date: </strong>
                {currentLicense.start_date}
              </li>
              <li>
                <strong>Expiration Date: </strong>
                {currentLicense.expiration_date}
              </li>
              <li>
                <strong>First Use Date: </strong>
                {currentLicense.first_use_date}
              </li>
              <li>
                <strong>Should Update Date on Use: </strong>
                {currentLicense.should_update_date ? "Yes" : "No"}
              </li>
              <li>
                <strong>Comment: </strong>
                {currentLicense.comment ? currentLicense.comment : " --- "}
              </li>
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      );
    } else
      return (
        <Modal isOpen={isOpen} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>Show License</ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter>
            <Button color="link" onClick={toggle}>
              Cancel
            </Button>
            <Button color="success" onClick={this.onSave}>
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      );
  }

  renderEdit() {
    const { className, isOpen, toggle, currentLicense } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
          {currentLicense ? "Edit License" : "New License"}
        </ModalHeader>
        <ModalBody>
          <Form action="" method="post">
            <FormGroup>
              <Label for="start-date">Start Date:</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-calendar-full"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={this.state.startDate}
                  onChange={this.handleChange}
                />
              </InputGroup>
              <br />
              <Label for="duration">Expiration Date:</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-calendar-full"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="date"
                  id="expirationDate"
                  name="expirationDate"
                  value={this.state.expirationDate}
                  onChange={this.handleChange}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-at-sign"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  id="email"
                  value={this.state.email}
                  name="email"
                  placeholder="Email sent to"
                  onChange={this.handleChange}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-user"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  id="user"
                  value={this.state.user}
                  name="user"
                  placeholder="User"
                  onChange={this.handleChange}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-cash-dollar"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="select"
                  id="subscription"
                  value={this.state.subscription}
                  name="subscription"
                  placeholder="Subscription"
                  onChange={this.handleChange}
                >
                  {this.createSubscriptionOptions()}
                </Input>
              </InputGroup>
              <br />
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-bubble"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  id="comment"
                  value={this.state.comment}
                  name="comment"
                  placeholder="Write a comment"
                  onChange={this.handleChange}
                />
              </InputGroup>
              <br />
              <InputGroup style={{ paddingLeft: "20px" }}>
                <Label for="used-checkbox">Used</Label>
                <Input
                  type="checkbox"
                  id="used-checkbox"
                  name="used"
                  checked={this.state.used}
                  onChange={this.handleCheck}
                />
              </InputGroup>

              <br />
              <InputGroup style={{ paddingLeft: "20px" }}>
                <Label for="should-update-checkbox">
                  Should Update Date on Use
                </Label>
                <Input
                  type="checkbox"
                  id="should-update-checkbox"
                  name="should_update_date"
                  checked={this.state.should_update_date}
                  onChange={this.handleCheck}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
          <Button color="success" onClick={this.onSave}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { isEdit } = this.props;

    if (isEdit) {
      return this.renderEdit();
    } else {
      return this.renderShow();
    }
  }
}

LicenseModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSave: PropTypes.func,
  currentLicense: PropTypes.object,
  subscriptionList: PropTypes.array,
};

class BulkAddLicenseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      batch: "",
      amount: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      file: null,
      batch: "",
    });
    if (props.isOpen) return;
  }
  onSave(e) {
    e.preventDefault();
    const { onSave } = this.props;
    const { file, batch, amount } = this.state;
    let data = {
      file,
      batch,
      amount,
    };
    onSave(data);
    this.props.toggle();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  renderForm() {
    const { addWithCsv } = this.props;
    if (addWithCsv) {
      return (
        <Form
          action=""
          method="post"
          encType="multipart/form-data"
          onSubmit={this.onSave}
        >
          <FormGroup>
            <Label for="batch">CSV:</Label>
            <InputGroup>
              <Input
                type="file"
                id="file"
                name="fileasd"
                onChange={this.handleFile}
                className="form-control"
              />
            </InputGroup>
            <br />
            <Label for="batch">Batch ID:</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-bubble"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                id="batch"
                value={this.state.batch}
                name="batch"
                placeholder="Type in a batch ID e.g. Batch1"
                onChange={this.handleChange}
              />
            </InputGroup>
          </FormGroup>
        </Form>
      );
    } else {
      return (
        <Form
          action=""
          method="post"
          encType="multipart/form-data"
          onSubmit={this.onSave}
        >
          <FormGroup>
            <Label for="batch">Amount:</Label>
            <InputGroup>
              <Input
                type="number"
                id="amount"
                name="amount"
                onChange={this.handleChange}
                className="form-control"
              />
            </InputGroup>
            <br />
            <Label for="batch">Batch ID:</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-bubble"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                id="batch"
                value={this.state.batch}
                name="batch"
                placeholder="Type in a batch ID e.g. Batch1"
                onChange={this.handleChange}
              />
            </InputGroup>
          </FormGroup>
        </Form>
      );
    }
  }

  render() {
    const { className, isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Add Licenses</ModalHeader>
        <ModalBody>{this.renderForm()}</ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
          <Button color="success" onClick={this.onSave}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

BulkAddLicenseModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSave: PropTypes.func,
  addWithCsv: PropTypes.bool,
};

class DownloadBatchCsvModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batch: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      batch: "",
    });
    if (props.isOpen) return;
  }
  onSave(e) {
    e.preventDefault();
    const { onSave } = this.props;
    const { batch } = this.state;
    let data = {
      batch: batch,
    };
    onSave(data);
    this.props.toggle();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const { className, isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Download CSV</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.onSave}>
            <FormGroup>
              <Label for="batch">Batch ID:</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-bubble"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  id="batch"
                  value={this.state.batch}
                  name="batch"
                  placeholder="Type in a batch ID e.g. Batch1"
                  onChange={this.handleChange}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
          <Button color="success" onClick={this.onSave}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

DownloadBatchCsvModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSave: PropTypes.func,
};

import DeleteConfirmModal from "../components/DeleteConfirmModal";

class Licenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
      deleteModal: false,
      bulkAddModal: false,
      downloadCsvModal: false,
      search: "",
      toggleSort: "desc",
      sort: "",
      isEdit: false,
      addWithCsv: false,
    };
    this.onCreate = this.onCreate.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onBulkAdd = this.onBulkAdd.bind(this);
    this.onDownloadCsv = this.onDownloadCsv.bind(this);
    this.toggleLicenseModal = this.toggleLicenseModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleBulkAddModal = this.toggleBulkAddModal.bind(this);
    this.toggleDownloadCsvModal = this.toggleDownloadCsvModal.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sortTable = this.sortTable.bind(this);
  }

  componentDidMount() {
    this.props.list_subscriptions();
  }

  toggleLicenseModal(index, isEdit) {
    this.props.setLicense(index);
    this.setState({
      editModal: !this.state.editModal,
      isEdit: isEdit,
    });
  }

  toggleDeleteModal(index) {
    this.props.setLicense(index);
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  }

  toggleBulkAddModal(addWithCsv) {
    this.setState({
      bulkAddModal: !this.state.bulkAddModal,
      addWithCsv,
    });
  }

  toggleDownloadCsvModal() {
    this.setState({
      downloadCsvModal: !this.state.downloadCsvModal,
    });
  }

  onCreate(data) {
    const { saveLicense } = this.props;
    saveLicense(data);
  }

  onDelete() {
    const { deleteLicense, currentLicense } = this.props;
    if (!currentLicense) return;
    deleteLicense(currentLicense);
  }

  onBulkAdd(data) {
    const { bulkAddLicenses, bulkAddLicensesCsv } = this.props;
    if (!data) return;
    if (this.state.addWithCsv) {
      bulkAddLicensesCsv(data);
    } else {
      bulkAddLicenses(data);
    }
  }

  onDownloadCsv(data) {
    const { downloadBatchCsv } = this.props;
    if (!data) return;
    downloadBatchCsv(data);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSearch(e) {
    e.preventDefault();
    const { searchLicenses } = this.props;
    const { search } = this.state;
    let data = {
      search: search,
    };
    searchLicenses(data);
  }

  sortTable(name) {
    const { sortLicenses } = this.props;
    const { toggleSort, search } = this.state;

    this.setState({
      toggleSort: toggleSort == "asc" ? "desc" : "asc",
      sort: name,
    });

    const order = toggleSort == "asc" ? "desc" : "asc";

    let data = {
      sort: name,
      order: order,
      search: search,
    };

    sortLicenses(data);
  }

  renderPagination() {
    const { pagination, getLicenses } = this.props;
    const { search, sort, toggleSort } = this.state;
    if (!pagination) return null;
    const { page_number, total_pages } = pagination;
    let paginationItems = [];
    if (total_pages > 4)
      paginationItems.push(
        <PaginationItem key={"first"} active={false}>
          <PaginationLink
            first={true}
            onClick={() =>
              getLicenses({
                page: 1,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {"First"}
          </PaginationLink>
        </PaginationItem>
      );
    let end = page_number + 3 >= total_pages ? total_pages : page_number + 3;
    for (let i = page_number - 3 <= 1 ? 1 : page_number - 3; i <= end; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={page_number == i}>
          <PaginationLink
            onClick={() =>
              getLicenses({
                page: i,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    if (total_pages > 4)
      paginationItems.push(
        <PaginationItem key={"last"} active={false}>
          <PaginationLink
            last={true}
            onClick={() =>
              getLicenses({
                page: total_pages,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {"Last"}
          </PaginationLink>
        </PaginationItem>
      );
    return <Pagination>{paginationItems}</Pagination>;
  }
  render() {
    const {
      className,
      licenses,
      pagination,
      currentLicense,
      usedLicenses,
      subscriptionList,
    } = this.props;
    const {
      editModal,
      deleteModal,
      bulkAddModal,
      downloadCsvModal,
      isEdit,
      addWithCsv,
    } = this.state;
    return (
      <div className="animated fadeIn">
        <LicenseModal
          className={className}
          onSave={this.onCreate}
          isOpen={editModal}
          toggle={this.toggleLicenseModal}
          currentLicense={currentLicense}
          subscriptionList={subscriptionList}
          isEdit={isEdit}
        />
        <BulkAddLicenseModal
          className={className}
          onSave={this.onBulkAdd}
          isOpen={bulkAddModal}
          toggle={this.toggleBulkAddModal}
          addWithCsv={addWithCsv}
        />
        <DownloadBatchCsvModal
          className={className}
          onSave={this.onDownloadCsv}
          isOpen={downloadCsvModal}
          toggle={this.toggleDownloadCsvModal}
        />
        <DeleteConfirmModal
          className={className}
          onDelete={this.onDelete}
          isOpen={deleteModal}
          toggle={this.toggleDeleteModal}
          name="License"
        />
        <Card>
          <CardHeader>
            <h5>Licenses</h5>
            <Button
              color="primary"
              className="right"
              onClick={() => this.toggleLicenseModal(null, true)}
            >
              {" "}
              Add License
            </Button>
            <Button
              color="primary"
              onClick={() => this.toggleBulkAddModal(false)}
            >
              Bulk Add Licenses
            </Button>
            <Button
              color="primary"
              onClick={() => this.toggleBulkAddModal(true)}
            >
              Bulk Add Licenses With Csv
            </Button>
            <Button color="primary" onClick={this.toggleDownloadCsvModal}>
              Download Batch CSV
            </Button>
          </CardHeader>

          <CardBody className="card-body">
            <Form onSubmit={this.onSearch}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        id="search"
                        value={this.state.search}
                        name="search"
                        placeholder="Search"
                        onChange={this.handleChange}
                      />
                      <Button color="disabled" onClick={this.onSearch}>
                        <i className="icon-magnifier"></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <h6>Used Licenses: {usedLicenses ? usedLicenses : 0}</h6>

            <Table responsive striped>
              <thead className="no-top-border">
                <tr>
                  <th
                    name="key"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("key")}
                  >
                    Key
                  </th>
                  <th
                    name="used"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("used")}
                  >
                    Used
                  </th>
                  <th
                    name="start_date"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("start_date")}
                  >
                    Start Date
                  </th>
                  <th
                    name="expiration_date"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("expiration_date")}
                  >
                    Expiration Date
                  </th>
                  <th
                    name="email"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("email")}
                  >
                    Email Sent To
                  </th>
                  <th
                    name="user"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("user")}
                  >
                    User
                  </th>
                  <th
                    name="subscription"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("subscription_id")}
                  >
                    Subscription
                  </th>
                  <th
                    name="batch"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("batch")}
                  >
                    Batch ID
                  </th>
                  <th
                    name="first_use_date"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("first_use_date")}
                  >
                    First Use Date
                  </th>
                  <th
                    name="comment"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("comment")}
                  >
                    Comment
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {licenses.map((license, i) => (
                  <tr key={license.key}>
                    <td>
                      <Button
                        size="sm"
                        color="link"
                        onClick={() => this.toggleLicenseModal(i, false)}
                      >
                        {license.key}
                      </Button>
                    </td>
                    <td>{license.used ? "Yes" : "No"}</td>

                    <td>{license.start_date}</td>
                    <td>{license.expiration_date}</td>
                    <td>{license.email ? license.email : "No Email"}</td>
                    <td>{license.user ? license.user : "No User"}</td>
                    <td>
                      {license.subscription_id
                        ? license.subscription_name
                        : "-"}
                    </td>
                    <td>{license.batch}</td>
                    <td>
                      {license.first_use_date
                        ? license.first_use_date
                        : "Not used"}
                    </td>
                    <td>{license.comment}</td>
                    <td className="actions list-actions">
                      <i
                        className="icon-eye"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.toggleLicenseModal(i, false)}
                      ></i>
                      <i
                        className="icon-pencil"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.toggleLicenseModal(i, true)}
                      ></i>
                      <i
                        className="icon-trash"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.toggleDeleteModal(i)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {this.renderPagination()}
          </CardBody>
        </Card>
      </div>
    );
  }
}

Licenses.propTypes = {
  currentLicense: PropTypes.object,
  className: PropTypes.string,
  getLicenses: PropTypes.func,
  setLicense: PropTypes.func,
  saveLicense: PropTypes.func,
  deleteLicense: PropTypes.func,
  licenses: PropTypes.array,
  pagination: PropTypes.array,
  page: PropTypes.number,
  currentLicense: PropTypes.object,
  usedLicenses: PropTypes.number,
  list_subscriptions: PropTypes.func,
  subscriptionList: PropTypes.array,
};

export default Licenses;
