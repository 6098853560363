import { connect } from "react-redux";
import Plot from "../components/Plot";
import { actions as dashboardsActions } from "../modules/dashboards";
import { actions as logActions } from "../modules/logs";

import _ from "lodash";

const mapStateToProps = (state = {}, ownProps = {}) => {
  return {
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onMakeMainGraph(payload) {
      dispatch(dashboardsActions.make_main_graph(payload));
    },
    tick(payload) {
      dispatch(logActions.tick(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plot);
