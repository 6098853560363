import _ from "lodash";
import Color from "color";

export function createAction(name, ...methods) {
  const action = {};
  // const upperName = name.toUpperCase()
  // const lowerName = name.toLowerCase()
  for (const method of methods) {
    const upper = method.toUpperCase();
    const lower = method.toLowerCase();
    const fullAction = (action[upper] = `${name}/${upper}`);
    action[upper] = fullAction;
    action[lower] = (payload) => ({ type: fullAction, payload });
  }
  return action;
}

export function createReducer(cases, defaultState = {}) {
  return function reducer(state = defaultState, action = {}) {
    if (_.isUndefined(state)) {
      state = _.isUndefined(defaultState) ? {} : defaultState;
    }

    const f = cases[action.type];
    if (f === undefined) return state;
    return f(state, action);
  };
}

export function actionNames(action) {
  return [action, `${action}_REQUEST`, `${action}_SUCCESS`, `${action}_ERROR`];
}

const RGB_COLOR_PALLETTE = [
  [255, 0, 0],
  [254, 100, 46],
  [255, 128, 0],
  [215, 223, 1],
  [128, 255, 0],
  [0, 255, 0],
  [0, 255, 128],
  [0, 255, 255],
  [0, 128, 255],
  [0, 0, 255],
  [128, 0, 255],
  [255, 0, 255],
  [255, 0, 128],
  [255, 0, 64],
  [132, 132, 132],
  [110, 110, 110],
  [138, 8, 8],
  [138, 41, 8],
  [138, 75, 8],
  [134, 138, 8],
  [75, 138, 8],
  [8, 138, 8],
  [8, 138, 75],
  [8, 138, 133],
  [8, 75, 138],
  [8, 8, 138],
  [75, 8, 138],
  [138, 8, 134],
  [138, 8, 75],
  [138, 8, 41],
  [28, 28, 28],
  [0, 0, 0],
];

/*
const COLOR_SEED = Color.rgb(252, 101, 133)
const COLOR_ROTATE = 30
export function getColor(number, seed, rotate) {
  if (!seed) {
    seed = COLOR_SEED
  }

  if (!rotate) {
    rotate = COLOR_ROTATE
  }

  const toRotate = number * rotate  + ((number % 2) * 180)
  const outColor = seed.rotate(toRotate)
  if (number >= 12) {
    outColor.darken(.4)
  }
  return outColor
}*/
export function getColor(number) {
  if (number > 0) number = number - 1;
  number = (number * 3) % 31;
  let color = RGB_COLOR_PALLETTE[number];

  return Color.rgb(color[0], color[1], color[2]);
}

export function getLogKey(name) {
  return `logging_${name}`;
}

export function getLogConfig({ key }) {
  return {
    key,
    name: key,
    duration: {
      value: 5,
      units: "minute",
    },
    interval: {
      value: 3,
      units: "second",
    },
    range: {
      min: "auto",
      max: "auto",
    },
    metersToDisplay: {},
    dashboardKey: "controllers",
  };
}
