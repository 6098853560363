import { router, createHashHistory } from "redux-saga-router";
import _ from "lodash";
import { sagas as moduleSagas, routes as moduleRoutes } from "../modules";

const history = createHashHistory();

const routes = {
  "/"() {
    if (localStorage.getItem("auth")) {
      history.push("/dashboard");
    } else {
      history.push("/login");
    }
  },
  ...moduleRoutes,
};
export default function* mainSaga() {
  yield* moduleSagas();
  yield* router(history, routes);
}
