import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  FormGroup,
  InputGroupAddon,
  Form,
  FormFeedback,
  InputGroupText,
} from "reactstrap";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password2: "",
      email: "",
      name: "",
      key: "",
    };

    this.handlePassword = this.handleChangeValue.bind(this, "password");
    this.handlePassword2 = this.handleChangeValue.bind(this, "password2");
    this.handleEmail = this.handleChangeValue.bind(this, "email");
    this.handleName = this.handleChangeValue.bind(this, "name");
    this.handleKey = this.handleChangeValue.bind(this, "key");
    this.handleClick = this.handleClick.bind(this);
  }

  handleChangeValue(name, e) {
    this.setState({
      [name]: e.target.value,
    });
  }

  isPasswordOK() {
    return this.state.password == this.state.password2;
  }

  isEmailValid() {
    const { email } = this.state;
    return !!(email && email.includes("@"));
  }

  isValid() {
    const { password, password2, email, name } = this.state;
    return !!(
      password &&
      password2 &&
      email &&
      name &&
      this.isPasswordOK() &&
      this.isEmailValid()
    );
  }

  handleClick(e) {
    e.preventDefault();
    const { email, name, password, key } = this.state;
    this.props.createUser({ email, name, password, key });
  }

  registeredRender() {
    return (
      <CardBody className="card-body p-4">
        <h3>Welcome! please try to login</h3>
        <NavLink to={"/login"}>
          <Button color="success">Login</Button>
        </NavLink>
      </CardBody>
    );
  }

  unregisteredRender() {
    let passwordColor = this.isPasswordOK() ? "text-success" : "text-danger";
    const { password, password2, email, name, key } = this.state;

    const isValid = this.isValid();
    const errors = this.props.errors || {};
    const passwordError = _.get(errors, "messages.password");
    const nameError = _.get(errors, "messages.name");
    const emailError = _.get(errors, "messages.email");
    const keyError = _.get(errors, "messages.key");

    return (
      <CardBody className="card-body p-4">
        <h1>Register</h1>
        <p className="text-muted">Create your account</p>
        <Form onSubmit={this.handleClick}>
          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-user"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Full Name"
                value={name}
                onChange={this.handleName}
              />
            </InputGroup>
            <FormFeedback className="text-danger">{nameError}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-at-sign"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Email"
                value={email}
                onChange={this.handleEmail}
              />
            </InputGroup>
            <FormFeedback className="text-danger">{emailError}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                className={passwordColor}
                type="password"
                placeholder="Password"
                value={password}
                onChange={this.handlePassword}
              />
            </InputGroup>
            <FormFeedback className="text-danger">{passwordError}</FormFeedback>
          </FormGroup>
          <InputGroup className="mb-4">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className={passwordColor}
              type="password"
              placeholder="Repeat password"
              value={password2}
              onChange={this.handlePassword2}
            />
          </InputGroup>
          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-key"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="License Key"
                value={key}
                onChange={this.handleKey}
              />
            </InputGroup>
            <FormFeedback className="text-danger">{keyError}</FormFeedback>
          </FormGroup>
          <Button color="success" disabled={!isValid} block>
            Create Account
          </Button>
        </Form>
      </CardBody>
    );
  }

  render() {
    let body;
    if (this.props.registered) {
      body = this.registeredRender();
    } else {
      body = this.unregisteredRender();
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <NavLink to={"/"}>
            <img
              width="100"
              className="login-logo"
              src="img/elite-lab-icon.svg"
              alt="EliteLab® Logo"
            />
          </NavLink>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                {body}
                {/*<CardFooter className="p-4">
                  <Row>
                    <Col xs="12" sm="6">
                      <Button className="btn-facebook" block><span>facebook</span></Button>
                    </Col>
                    <Col xs="12" sm="6">
                      <Button className="btn-twitter" block><span>twitter</span></Button>
                    </Col>
                  </Row>
                </CardFooter>*/}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Register.propTypes = {
  createUser: PropTypes.func,
  errors: PropTypes.object,
  registered: PropTypes.bool,
};

export default Register;
