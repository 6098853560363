import { connect } from "react-redux";
import Changelog from "../views/pages/Changelog";
import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Changelog);
