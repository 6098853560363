import { takeEvery, put, call } from "redux-saga/effects";
import { createAction, createReducer, actionNames } from "../tools";
import api from "../api";
import _ from "lodash";

export const actions = createAction(
  "cirrus/modules/licenses",
  ...actionNames("LIST"),
  ...actionNames("CREATE"),
  ...actionNames("UPDATE"),
  ...actionNames("DELETE"),
  ...actionNames("BULK_ADD"),
  ...actionNames("BULK_ADD_CSV"),
  ...actionNames("DOWNLOAD_CSV"),
  ...actionNames("COUNT_USED"),
  ...actionNames("LIST_SUBSCRIPTIONS"),
  "SET_LICENSE"
);

const defaultState = {
  list: [],
  params: {
    page: 1,
    per_page: 50,
  },
  current: null,
};

export default createReducer(
  {
    [actions.LIST_SUCCESS]: (state, action) => {
      const { pagination, data } = action.payload;
      return {
        ...state,
        list: data,
        pagination,
      };
    },
    [actions.SET_LICENSE]: (state, action) => {
      const index = action.payload;
      let current = state.list[index] || null;
      if (current) {
        current = { ...current, open: true };
      }
      return {
        ...state,
        current,
      };
    },
    [actions.CREATE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        current: { ...payload, open: false },
      };
    },
    [actions.UPDATE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      const id = payload.id;
      let { list } = state;

      const index = _.findIndex(list, (l) => l.id == id);
      const newList = [
        ..._.slice(list, 0, index),
        payload,
        ..._.slice(list, index + 1),
      ];
      list = newList;

      return {
        ...state,
        list,
        current: { ...payload, open: false },
      };
    },
    [actions.DELETE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        current: { ...payload, open: false },
      };
    },
    [actions.COUNT_USED_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        usedLicenses: payload,
      };
    },
    [actions.LIST_SUBSCRIPTIONS_SUCCESS]: (state, action) => {
      return {
        ...state,
        subscriptionList: action.payload,
      };
    },
  },
  defaultState
);

function* create_license({ payload }) {
  try {
    const { data } = yield call(api.licenses.create, payload);
    yield put(actions.list_request());
    yield put(actions.create_success(data));
  } catch (e) {
    yield put(actions.create_error(e));
  }
}

function* update_license({ payload }) {
  try {
    const data = yield call(api.licenses.update, payload);
    yield put(actions.update_success(data));
  } catch (e) {
    yield put(actions.update_error(e));
  }
}

function* delete_license({ payload }) {
  try {
    const { data } = yield call(api.licenses.delete, payload);
    yield put(actions.list_request());
    yield put(actions.delete_success(data));
  } catch (e) {
    yield put(actions.delete_error(e));
  }
}

function* list_licenses({ payload }) {
  try {
    const data = yield call(api.licenses.all, payload);
    yield put(actions.count_used_request());
    yield put(actions.list_success(data));
  } catch (e) {
    yield put(actions.list_error(e));
  }
}

function* bulk_add_licenses_csv({ payload }) {
  try {
    const data = yield call(api.licenses.bulkAddCsv, payload);
    yield put(actions.list_request());
    yield put(actions.bulk_add_csv_success(data));
  } catch (e) {
    yield put(actions.bulk_add_csv_error(e));
  }
}

function* bulk_add_licenses({ payload }) {
  try {
    const data = yield call(api.licenses.bulkAdd, payload);
    yield put(actions.list_request());
    yield put(actions.bulk_add_success(data));
  } catch (e) {
    yield put(actions.bulk_add_error(e));
  }
}

function* download_csv({ payload }) {
  try {
    yield call(api.licenses.downloadCsv, payload);
    yield put(actions.download_csv_success());
  } catch (e) {
    yield put(actions.download_csv_error(e));
  }
}

function* count_used() {
  try {
    const data = yield call(api.licenses.countUsed);
    yield put(actions.count_used_success(data));
  } catch (e) {
    yield put(actions.count_used_error(e));
  }
}

function* list_subscriptions() {
  try {
    const data = yield call(api.licenses.list_subscriptions);
    yield put(actions.list_subscriptions_success(data));
  } catch (e) {
    yield put(actions.list_subscriptions_error(e));
  }
}

export const routes = {
  *"/licenses"() {
    yield put(actions.list_request());
  },
};

export function* mainSaga() {
  yield takeEvery(actions.LIST_REQUEST, list_licenses);
  yield takeEvery(actions.CREATE_REQUEST, create_license);
  yield takeEvery(actions.UPDATE_REQUEST, update_license);
  yield takeEvery(actions.DELETE_REQUEST, delete_license);
  yield takeEvery(actions.BULK_ADD_CSV_REQUEST, bulk_add_licenses_csv);
  yield takeEvery(actions.BULK_ADD_REQUEST, bulk_add_licenses);
  yield takeEvery(actions.DOWNLOAD_CSV_REQUEST, download_csv);
  yield takeEvery(actions.COUNT_USED_REQUEST, count_used);
  yield takeEvery(actions.LIST_SUBSCRIPTIONS_REQUEST, list_subscriptions);
}
