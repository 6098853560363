import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Col,
  Row,
} from "reactstrap";

import DeleteConfirmModal from "../components/DeleteConfirmModal";

class DebugLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      search: "",
      currentDebugLog: null,
    };
    this.onDelete = this.onDelete.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  toggleDeleteModal(debugLog) {
    this.setState({
      deleteModal: !this.state.deleteModal,
      currentDebugLog: debugLog,
    });
  }

  onDelete() {
    const { deleteDebugLog } = this.props;
    const { currentDebugLog } = this.state;
    if (!currentDebugLog) return;
    deleteDebugLog(currentDebugLog);
  }

  onSearch(e) {
    e.preventDefault();
    const { searchDebugLogs } = this.props;
    const { search } = this.state;
    let data = {
      search: search,
    };
    searchDebugLogs(data);
  }
  downloadFile(debugLog) {
    const { downloadDebugLog } = this.props;
    downloadDebugLog(debugLog);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  renderPagination() {
    const { pagination, getDebugLogs } = this.props;
    const { search, sort, toggleSort } = this.state;
    if (!pagination) return null;
    const { page_number, total_pages } = pagination;
    let paginationItems = [];
    if (total_pages > 4)
      paginationItems.push(
        <PaginationItem key={"first"} active={false}>
          <PaginationLink
            first={true}
            onClick={() =>
              getDebugLogs({
                page: 1,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {"First"}
          </PaginationLink>
        </PaginationItem>
      );
    let end = page_number + 3 >= total_pages ? total_pages : page_number + 3;
    for (let i = page_number - 3 <= 1 ? 1 : page_number - 3; i <= end; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={page_number == i}>
          <PaginationLink
            onClick={() =>
              getDebugLogs({
                page: i,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    if (total_pages > 4)
      paginationItems.push(
        <PaginationItem key={"last"} active={false}>
          <PaginationLink
            last={true}
            onClick={() =>
              getDebugLogs({
                page: total_pages,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {"Last"}
          </PaginationLink>
        </PaginationItem>
      );
    return <Pagination>{paginationItems}</Pagination>;
  }

  render() {
    const { className, debugLogs } = this.props;
    const { deleteModal } = this.state;
    return (
      <div className="animated fadeIn">
        <DeleteConfirmModal
          className={className}
          onDelete={this.onDelete}
          isOpen={deleteModal}
          toggle={this.toggleDeleteModal}
          name="DebugLog"
        />
        <Card>
          <CardHeader>
            <h5>DebugLogs</h5>
          </CardHeader>

          <CardBody className="card-body">
            <Form onSubmit={this.onSearch}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        id="search"
                        value={this.state.search}
                        name="search"
                        placeholder="Search"
                        onChange={this.handleChange}
                      />
                      <Button color="disabled" onClick={this.onSearch}>
                        <i className="icon-magnifier"></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Form>

            <Table responsive striped>
              <thead className="no-top-border">
                <tr>
                  <th name="filename">File Name</th>
                  <th name="email">Email</th>
                  <th name="upload_date">Upload Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {debugLogs.map((debugLog, i) => (
                  <tr key={debugLog.key}>
                    <td>
                      <Button
                        size="sm"
                        color="link"
                        onClick={() => this.downloadFile(debugLog)}
                      >
                        {debugLog.filename}
                      </Button>
                    </td>
                    <td>{debugLog.email}</td>
                    <td>{debugLog.inserted_at}</td>
                    <td className="actions list-actions">
                      <i
                        className="icon-download2"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.downloadFile(debugLog)}
                      ></i>
                      <i
                        className="icon-trash"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.toggleDeleteModal(debugLog)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {this.renderPagination()}
          </CardBody>
        </Card>
      </div>
    );
  }
}

DebugLogs.propTypes = {
  currentDebugLog: PropTypes.object,
  className: PropTypes.string,
  getDebugLogs: PropTypes.func,
  setDebugLog: PropTypes.func,
  saveDebugLog: PropTypes.func,
  deleteDebugLog: PropTypes.func,
  debugLogs: PropTypes.array,
  pagination: PropTypes.array,
  page: PropTypes.number,
  currentDebugLog: PropTypes.object,
  usedDebugLogs: PropTypes.number,
  list_subscriptions: PropTypes.func,
  subscriptionList: PropTypes.array,
};

export default DebugLogs;
