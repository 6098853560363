import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class DeleteConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.isOpen) return;
  }

  onDelete() {
    const { onDelete } = this.props;
    onDelete();
    this.props.toggle();
  }

  render() {
    const { className, isOpen, toggle, name } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}> Delete {name} </ModalHeader>
        <ModalBody>
          {name} will be permanently deleted. Are you sure you want to proceed?
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
          <Button color="danger" onClick={this.onDelete}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

DeleteConfirmModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onDelete: PropTypes.func,
  name: PropTypes.string,
};

export default DeleteConfirmModal;
