import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import changelog from "../../changelog.md";

import Config from "Config";
const BASE_URL = Config.baseUrl;

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <NavLink to={"/"}>
            <img
              width="100"
              className="login-logo"
              src="img/elite-lab-icon.svg"
              alt="EliteLab® Logo"
            />
          </NavLink>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup className="mb-0">
                <Card className="p-4">
                  <CardHeader tag="h3">Changelog</CardHeader>
                  <CardBody className="card-body">
                    <ReactMarkdown source={changelog} />
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <br />
        </Container>
      </div>
    );
  }
}

Downloads.propTypes = {};

export default Downloads;
