import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { Button } from "reactstrap";
// import throttleRender from 'react-throttle-render'

import { Scatter, defaults as chartDefaults } from "react-chartjs-2";

import GraphSettings from "../components/GraphSettings";
import { toPlotData } from "../modules/logs";

chartDefaults.global.animation = false;

function toDataset(points, meter) {
  return {
    label: `Temperature ${meter.key}`,
    fill: false,
    lineTension: 0,
    showLine: true,
    backgroundColor: meter.color,
    borderColor: meter.color,
    borderWidth: 3,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: meter.color,
    pointBackgroundColor: meter.colora,
    pointBorderWidth: 1,
    pointHoverRadius: _.map(points || [], (p) => (p && p.comments ? 6 : 3)),
    pointHoverBackgroundColor: meter.color,
    pointHoverBorderColor: meter.color,
    pointHoverBorderWidth: 5,
    pointRadius: _.map(points || [], (p) => (p && p.comments ? 6 : 1)),
    pointHitRadius: 1,
    data: points || [],
  };
}

function mergeOptions(op1, op2) {
  const y1 = op1.yTicks || {};
  const y2 = op2.yTicks || {};
  const yTicks = {};
  if (_.isUndefined(y1.max || y2.max)) {
    yTicks.suggestedMax = _.max([y1.suggestedMax, y2.suggestedMax]);
    yTicks.max = undefined;
  } else {
    yTicks.max = y2.max;
  }

  if (_.isUndefined(y1.min || y2.min)) {
    yTicks.suggestedMin = _.min([y1.suggestedMin, y2.suggestedMin]);
    yTicks.min = undefined;
  } else {
    yTicks.min = y2.min;
  }

  return {
    minX: _.min([op1.minX, op2.minX]),
    maxX: _.max([op1.maxX, op2.maxX]),
    yTicks,
  };
}

const TIME_FORMAT = {
  second: "h:mm:ss",
  minute: "h:mm",
  hour: "h:mm",
};

function toLineOptions({ yTicks = {}, minX, maxX }, duration) {
  const stepSize = (maxX - minX) / 5;
  const firstTime = moment().subtract(duration.value, duration.units);
  return {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          display: true,
          ticks: yTicks,
        },
      ],
      xAxes: [
        {
          display: true,
          ticks: {
            min: minX,
            max: maxX,
            fontSize: 10,
            stepSize: stepSize,
            fixedStepSize: stepSize,
            callback: function (value) {
              const format = TIME_FORMAT[duration.units];
              return moment(firstTime)
                .add(value, duration.units)
                .format(format);
            },
          },
        },
      ],
    },
    tooltips: {
      intersect: false,
      position: "nearest",
      callbacks: {
        label: function ({ index }, { datasets }) {
          const obj = datasets[0].data[index];
          const m = moment(obj && obj.time);
          return `${obj.y} @ ${m.format("hh:mm:ss a")} ${obj.comments || ""}`;
        },
      },
    },
  };
}

function getLineAndOptions(logConfig, meters) {
  const { datasets, options } = _.reduce(
    meters,
    ({ options, datasets }, m) => {
      if (!m.active) {
        return { options, datasets };
      }

      const obj = toPlotData(
        m.logData && m.logData.points,
        logConfig.duration,
        logConfig.range
      );
      const dataset = toDataset(obj.points, m);
      return {
        options: mergeOptions(options, obj),
        datasets: [...datasets, dataset],
      };
    },
    { datasets: [], options: {} }
  );

  const line = { datasets };
  const lineOptions = toLineOptions(options, logConfig.duration);
  return { line, lineOptions };
}

class Plot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfig: false,
    };

    this.toggleShowConfig = this.toggleShowConfig.bind(this);
    this.onUpdateConfig = this.onUpdateConfig.bind(this);
    this.handleMeterButton = this.handleMeterButton.bind(this);
    this.isRenderingMeter = this.isRenderingMeter.bind(this);
    this.handleMakeMainGraph = this.handleMakeMainGraph.bind(this);
  }

  handleMakeMainGraph() {
    const { config, onMakeMainGraph } = this.props;
    const { dashboardKey, key } = config;
    onMakeMainGraph({ dashboardKey, graphKey: key });
  }

  handleMeterButton(m) {
    const { key } = m;
    const { config, onToggleGraphMeter } = this.props;

    onToggleGraphMeter({
      dashboardKey: config.dashboardKey,
      graphKey: config.key,
      meterKey: key,
    });
  }

  componentDidMount() {
    let lastTime;
    const tickTimer = setInterval(() => {
      const { meters, config } = this.props;
      const duration = moment.duration(
        parseInt(config.duration.value, 10) / 2000,
        config.duration.units
      );
      const now = moment();
      if (lastTime && now.isBefore(moment(lastTime).add(duration))) {
        return;
      }
      lastTime = now;

      for (let meter of meters) {
        this.props.tick({
          duration: config && config.duration,
          range: (config && config.range) || {},
          serialId: meter.device.serialId,
          meterId: meter.address,
        });
      }
    }, 191);

    this.setState({
      tickTimer,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.tickTimer);
  }

  toggleShowConfig() {
    this.setState({
      showConfig: !this.state.showConfig,
    });
  }

  onUpdateConfig(config) {
    const { range, duration, interval } = config;
    if (_.isString(range.min)) {
      range.min = "auto";
    }

    if (_.isString(range.max)) {
      range.max = "auto";
    }

    if (_.isString(duration.value)) {
      duration.value = 1;
    }

    if (_.isString(interval.value)) {
      interval.value = 1;
    }

    this.props.onUpdateConfig({
      config: _.cloneDeep(config),
    });
  }

  isRenderingMeter(m) {
    const toRender = this.props.config.metersToDisplay[m.key];
    if (_.isUndefined(toRender)) return true;
    return toRender;
  }

  render() {
    const { meters, size = "big", config, height } = this.props;
    if (!height) return null;
    const toRender = _.filter(meters, this.isRenderingMeter);
    const { line, lineOptions } = getLineAndOptions(config, toRender);

    let makeMainButton = null;
    let titleSize = -25;
    if (size == "sm") {
      titleSize = 35;
      lineOptions.scales.xAxes[0].ticks.fontSize = 7;
      lineOptions.scales.yAxes[0].ticks.fontSize = 7;
      makeMainButton = (
        <Button
          className="btn btn-link make-main-graph"
          style={{ float: "right" }}
          onClick={this.handleMakeMainGraph}
        >
          <i className="icon-launch"></i>
        </Button>
      );
    }

    const styleW = { height: height - titleSize, position: "relative" };
    return (
      <div className="chart-wrapper">
        <GraphSettings
          isOpen={this.state.showConfig}
          toggle={this.toggleShowConfig}
          config={config}
          onSave={this.onUpdateConfig}
          meters={meters}
          onMeterButtonClick={this.handleMeterButton}
          isRenderingMeter={this.isRenderingMeter}
        />
        <Button className="btn btn-link" onClick={this.toggleShowConfig}>
          <i className="icon-cog"></i>
          {config.name}
        </Button>{" "}
        {makeMainButton}
        <div style={styleW}>
          <Scatter data={line} options={lineOptions} />
        </div>
      </div>
    );
  }
}

Plot.propTypes = {
  height: PropTypes.number,
  meters: PropTypes.array,
  size: PropTypes.string,
  config: PropTypes.object,
  onUpdateConfig: PropTypes.func,
  onToggleGraphMeter: PropTypes.func,
  tick: PropTypes.func,
};

export default Plot;
