import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Input,
  InputGroup,
  FormGroup,
  InputGroupAddon,
  Form,
  FormFeedback,
  Label,
  InputGroupText,
} from "reactstrap";
import _ from "lodash";

function targetValue(e) {
  return e.target.value;
}

function boolValue(e, name, state) {
  return !state[name];
}

class Details extends Component {
  constructor(props = {}) {
    super(props);

    const { email, name, is_super, is_active, inserted_at, id } =
      props.user || {};

    this.state = {
      password: "",
      password2: "",
      email: email || "",
      name: name || "",
      is_super: is_super || false,
      is_active: is_active || false,
      inserted_at,
      id,
    };

    this.handlePassword = this.handleChangeValue.bind(this, "password");
    this.handlePassword2 = this.handleChangeValue.bind(this, "password2");
    this.handleIsSuper = this.handleChangeValue.bind(
      this,
      "is_super",
      boolValue
    );
    this.handleEmail = this.handleChangeValue.bind(this, "email");
    this.handleName = this.handleChangeValue.bind(this, "name");
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps(props = {}) {
    const { email, name, is_super, is_active, inserted_at, id } =
      props.user || {};

    this.setState({
      id,
      email: this.state.email || email || "",
      name: this.state.name || name || "",
      is_super,
      is_active,
      inserted_at,
    });
  }

  handleChangeValue(name, f, e) {
    let value;
    if (!e) {
      value = targetValue(f);
    } else {
      value = f(e, name, this.state);
    }

    this.setState({
      [name]: value,
    });
  }

  isPasswordOK() {
    return this.state.password == this.state.password2;
  }

  isEmailValid() {
    const { email } = this.state;
    return !!(email && email.includes("@"));
  }

  isValid() {
    const { password, password2, email, name } = this.state;
    const { requiresPassword } = this.props.requiresPassword;
    if (requiresPassword || password || password2) {
      return !!(
        password &&
        password2 &&
        email &&
        name &&
        this.isPasswordOK() &&
        this.isEmailValid()
      );
    }

    return !!(email && name && this.isEmailValid());
  }

  handleClick() {
    this.props.handleClick(this.state);
  }

  renderAdmin() {
    const { admin } = this.props;
    if (!admin) return;

    const { is_super } = this.state;

    return (
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            checked={is_super || false}
            onChange={this.handleIsSuper}
          />{" "}
          Is Admin
        </Label>
      </FormGroup>
    );
  }

  renderPasswordInputs() {
    const { password, password2 } = this.state;
    const { admin } = this.props;
    if (admin) return;

    let passwordColor = this.isPasswordOK() ? "text-success" : "text-danger";

    const errors = this.props.errors || {};
    const passwordError = _.get(errors, "messages.password");

    return (
      <span>
        <FormGroup>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className={passwordColor}
              type="password"
              placeholder="Password"
              value={password}
              onChange={this.handlePassword}
            />
          </InputGroup>
          <FormFeedback className="text-danger">{passwordError}</FormFeedback>
        </FormGroup>

        <InputGroup className="mb-4">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            className={passwordColor}
            type="password"
            placeholder="Repeat password"
            value={password2}
            onChange={this.handlePassword2}
          />
        </InputGroup>
      </span>
    );
  }

  render() {
    const { email, name, id } = this.state;

    const isValid = this.isValid();
    const errors = this.props.errors || {};
    const nameError = _.get(errors, "messages.name");
    const emailError = _.get(errors, "messages.email");

    return (
      <Form>
        <FormGroup>
          <InputGroup className="mb-3">
            {/*<InputGroupAddon addonType="prepend"><InputGroupText><i className="icon-user"></i></InputGroupText></InputGroupAddon>*/}
            <Input
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={this.handleName}
            />
          </InputGroup>
          <FormFeedback className="text-danger">{nameError}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-at-sign"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Email"
              value={email}
              onChange={this.handleEmail}
            />
          </InputGroup>
          <FormFeedback className="text-danger">{emailError}</FormFeedback>
        </FormGroup>
        {this.renderPasswordInputs()}
        {this.renderAdmin()}

        <FormGroup>
          <FormFeedback className="text-danger">{errors.message}</FormFeedback>
        </FormGroup>
        <Button
          color="success"
          disabled={!isValid}
          onClick={this.handleClick}
          block
        >
          {id ? "Save" : "Create"}
        </Button>
      </Form>
    );
  }
}

Details.propTypes = {
  handleClick: PropTypes.func,
  errors: PropTypes.object,
  user: PropTypes.object,
  requiresPassword: PropTypes.bool,
  admin: PropTypes.bool,
};

export default Details;
