import { connect } from "react-redux";
import Account from "../views/Account";
import { actions } from "../modules/account";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  const current_key = _.get(state, "account.license");
  const licenses = _.get(state, "account.licenses", []);
  const current_license = _.find(licenses, (l) => l.key == current_key) || {};
  return { ...state.account, current_license };
};

const mapDispatchToProps = (dispatch) => {
  return {
    request_me_info(payload) {
      dispatch(actions.me_info_request(payload));
    },
    update_me_info(payload) {
      dispatch(actions.update_me_info_request(payload));
    },
    list_subscriptions(payload) {
      dispatch(actions.list_subscriptions_request(payload));
    },
    list_licenses() {
      dispatch(actions.list_licenses_request());
    },
    set_subscription(payload) {
      dispatch(actions.set_subscription_request(payload));
    },
    cancel_subscription(payload) {
      dispatch(actions.cancel_subscription_request(payload));
    },
    add_card(payload) {
      dispatch(actions.add_customer_request(payload));
    },
    list_charges() {
      dispatch(actions.list_charges_request());
    },
    update_current_license(payload) {
      dispatch(actions.update_license(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
