import React, { Component } from "react";
import {
  Badge,
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      large: false,
      small: false,
      primary: false,
      success: false,
      warning: false,
      danger: false,
      info: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Button onClick={this.toggle}>New User</Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>New User</ModalHeader>
          <ModalBody>
            <Form action="" method="post">
              <FormGroup row>
                <Col xs="12" md="1">
                  <img
                    src={"img/avatars/default.svg"}
                    className="img-avatar lg"
                  />
                </Col>
                <Col xs="12" md="11" className="flex-vcenter">
                  <label htmlFor="file-input" className="custom-file-upload">
                    <i className="fa fa-cloud-upload"></i> Choose Image
                  </label>
                  <Input type="file" id="file-input" name="file-input" />
                </Col>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <Input
                    type="text"
                    id="username2"
                    name="username2"
                    placeholder="Username"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-user"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <Input
                    type="email"
                    id="email2"
                    name="email2"
                    placeholder="Email"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-envelope"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <Input
                    type="password"
                    id="password2"
                    name="password2"
                    placeholder="Password"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-asterisk"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.toggle}>
              Create User
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Card>
          <CardHeader>
            <i className="fa fa-users"></i> Users
          </CardHeader>
          <CardBody className="card-body">
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Date registered</th>
                  <th>Role</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Mike Maibach</td>
                  <td>mike@labsociety.com</td>
                  <td>2017/01/01</td>
                  <td>Admin</td>
                  <td>
                    <Badge color="success">Active</Badge>
                  </td>
                </tr>
                <tr>
                  <td>Randy Tomlinson</td>
                  <td>randy@labsociety.com</td>
                  <td>2017/02/13</td>
                  <td>Admin</td>
                  <td>
                    <Badge color="secondary">Inactive</Badge>
                  </td>
                </tr>
                <tr>
                  <td>Adam Koe</td>
                  <td>adamk@labsociety.com</td>
                  <td>2017/03/01</td>
                  <td>Manager</td>
                  <td>
                    <Badge color="success">Active</Badge>
                  </td>
                </tr>
                <tr>
                  <td>Taylor Martucci</td>
                  <td>taylor@labsociety.com</td>
                  <td>2017/05/21</td>
                  <td>User</td>
                  <td>
                    <Badge color="success">Active</Badge>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Pagination>
              <PaginationItem disabled>
                <PaginationLink previous href="#">
                  Prev
                </PaginationLink>
              </PaginationItem>
              <PaginationItem active>
                <PaginationLink href="#">1</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">2</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">3</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">4</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink next href="#">
                  Next
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Admin;
