import { takeEvery, put, call } from "redux-saga/effects";
import { createAction, createReducer, actionNames } from "../tools";
import api from "../api";
import _ from "lodash";

export const actions = createAction(
  "cirrus/modules/subscriptions",
  ...actionNames("LIST"),
  ...actionNames("DELETE"),
  ...actionNames("LIST_SUBSCRIPTIONS"),
  ...actionNames("UPDATE"),
  ...actionNames("CREATE"),
  "SET_SUBSCRIPTION"
);

const defaultState = {
  list: [],
  params: {
    page: 1,
    per_page: 50,
  },
  current: null,
};

export default createReducer(
  {
    [actions.LIST_SUCCESS]: (state, action) => {
      return {
        ...state,
        list: action.payload,
      };
    },
    [actions.DELETE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        current: { ...payload, open: false },
      };
    },
    [actions.UPDATE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        current: { ...payload, open: false },
      };
    },
    [actions.SET_SUBSCRIPTION]: (state, action) => {
      const index = action.payload;
      let current = state.list[index] || null;
      if (current) {
        current = { ...current, open: true };
      }
      return {
        ...state,
        current,
      };
    },
    [actions.CREATE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        current: { ...payload, open: false },
      };
    },
  },
  defaultState
);

function* deleteSubscription({ payload }) {
  try {
    const { data } = yield call(api.subscriptions.delete, payload);
    yield put(actions.list_request());
    yield put(actions.delete_success(data));
  } catch (e) {
    yield put(actions.delete_error(e));
  }
}

function* listSubscriptions({ payload }) {
  try {
    const data = yield call(api.subscriptions.list, payload);
    yield put(actions.list_success(data));
  } catch (e) {
    yield put(actions.list_error(e));
  }
}

function* createSubscription({ payload }) {
  try {
    const { data } = yield call(api.subscriptions.create, payload);
    yield put(actions.list_request());
    yield put(actions.create_success(data));
  } catch (e) {
    yield put(actions.create_error(e));
  }
}

function* updateSubscription({ payload }) {
  try {
    const data = yield call(api.subscriptions.update, payload);
    yield put(actions.update_success(data));
    yield put(actions.list_request());
  } catch (e) {
    yield put(actions.update_error(e));
  }
}

export const routes = {
  *"/subscriptions"() {
    yield put(actions.list_request());
  },
};

export function* mainSaga() {
  yield takeEvery(actions.LIST_REQUEST, listSubscriptions);
  yield takeEvery(actions.DELETE_REQUEST, deleteSubscription);
  yield takeEvery(actions.UPDATE_REQUEST, updateSubscription);
  yield takeEvery(actions.CREATE_REQUEST, createSubscription);
}
