import PropTypes from "prop-types";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      toggleSort: "desc",
      sort: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sortTable = this.sortTable.bind(this);
  }
  onSearch(e) {
    e.preventDefault();
    const { getCharges } = this.props;
    const { search } = this.state;
    let data = {
      search: search,
    };
    getCharges(data);
  }

  sortTable(name) {
    const { getCharges } = this.props;
    const { toggleSort, search } = this.state;

    this.setState({
      toggleSort: toggleSort == "asc" ? "desc" : "asc",
      sort: name,
    });

    const order = toggleSort == "asc" ? "desc" : "asc";

    let data = {
      sort: name,
      order: order,
      search: search,
    };

    getCharges(data);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  renderPagination() {
    const { pagination, getCharges } = this.props;
    const { search, sort, toggleSort } = this.state;
    if (!pagination) return null;
    const { page_number, total_pages } = pagination;
    let paginationItems = [];
    if (total_pages > 4)
      paginationItems.push(
        <PaginationItem key={"first"} active={false}>
          <PaginationLink
            first={true}
            onClick={() =>
              getCharges({
                page: 1,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {"First"}
          </PaginationLink>
        </PaginationItem>
      );
    let end = page_number + 3 >= total_pages ? total_pages : page_number + 3;
    for (let i = page_number - 3 <= 1 ? 1 : page_number - 3; i <= end; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={page_number == i}>
          <PaginationLink
            onClick={() =>
              getCharges({
                page: i,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    if (total_pages > 4)
      paginationItems.push(
        <PaginationItem key={"last"} active={false}>
          <PaginationLink
            last={true}
            onClick={() =>
              getCharges({
                page: total_pages,
                order: toggleSort ? toggleSort : null,
                search: search ? search : null,
                sort: sort ? sort : null,
              })
            }
          >
            {"Last"}
          </PaginationLink>
        </PaginationItem>
      );
    return <Pagination>{paginationItems}</Pagination>;
  }

  render() {
    const { charges } = this.props;
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <h5>Billing</h5>
          </CardHeader>
          <CardBody className="card-body">
            <Form onSubmit={this.onSearch}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        id="search"
                        value={this.state.search}
                        name="search"
                        placeholder="Search"
                        onChange={this.handleChange}
                      />
                      <Button color="disabled" onClick={this.onSearch}>
                        <i className="icon-magnifier"></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Table responsive striped>
              <thead className="no-top-border">
                <tr>
                  <th
                    name="name"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("name")}
                  >
                    Name
                  </th>
                  <th
                    name="email"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("email")}
                  >
                    Email
                  </th>
                  <th
                    name="inserted_at"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("inserted_at")}
                  >
                    Timestamp
                  </th>
                  <th
                    name="description"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("description")}
                  >
                    Description
                  </th>
                  <th
                    name="amount"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortTable("amount")}
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {charges.map((c) => (
                  <tr key={c.id}>
                    <td>{c.name}</td>
                    <td>{c.email}</td>
                    <td>{c.inserted_at}</td>
                    <td>{c.description}</td>
                    <td>{c.amount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {this.renderPagination()}
          </CardBody>
        </Card>
      </div>
    );
  }
}

Billing.propTypes = {
  className: PropTypes.string,
  getCharges: PropTypes.func,
  charges: PropTypes.array,
  pagination: PropTypes.array,
  page: PropTypes.number,
};

export default Billing;
