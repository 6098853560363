import "babel-polyfill";

import React from "react";
import { render } from "react-dom";
import {
  HashRouter,
  Route,
  Switch,
  //Redirect
} from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import mainSaga from "./sagas";
import reducer from "./reducers";

import "bootstrap/dist/css/bootstrap.min.css";
import "typeface-montserrat";
import "../scss/style.scss";

// Temp fix for reactstrap
import "../scss/core/_dropdown-menu-right.scss";
import "../icomoon/style.scss";

// Views
import Page404 from "./views/pages/Page404";
import Page500 from "./views/pages/Page500";

import Admin from "./views/Admin";

// Containers
import Users from "./containers/Users";
//Register temporarily disabled because of mismatch from app
import Register from "./containers/Registration";
import Login from "./containers/Login";
import Lab from "./containers/Lab";
import Account from "./containers/Account";
import Licenses from "./containers/Licenses";
import ResetPassword from "./containers/ResetPassword";
import NewPassword from "./containers/NewPassword";
import VerifyEmail from "./containers/VerifyEmail";
import NoMatch from "./containers/NoMatch";
import Downloads from "./containers/Downloads";
import Dashboard from "./containers/Dashboard";
import DebugLogs from "./containers/DebugLogs";
import Subscriptions from "./containers/Subscriptions";
import Changelog from "./containers/Changelog";
import LoginRecords from "./containers/LoginRecords";
import Billing from "./containers/Billing";

import { actions as logActions } from "./modules/logs";

const logger = createLogger({
  predicate: (getState, action) => action.type !== logActions.TICK,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducer,
  // applyMiddleware(logger),
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(mainSaga);

render(
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        {/* <Redirect exact from="/" to="/login" /> */}

        {/*<Route exact path="/register" name="Register Page" component={Register} />*/}
        <Route
          exact
          path="/reset-password"
          name="Reset Password Page"
          component={ResetPassword}
        />
        <Route
          exact
          path="/new-password/:token"
          name="New Password Page"
          component={NewPassword}
        />
        <Route
          exact
          path="/verify-email/:token"
          name="Verify Email Page"
          component={VerifyEmail}
        />
        <Route
          exact
          path="/downloads"
          name="Downloads Page"
          component={Downloads}
        />
        <Route
          exact
          path="/changelog"
          name="Changelog Page"
          component={Changelog}
        />
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/500" name="Page 500" component={Page500} />
        <Route path="/admin" name="Admin" component={Admin} />

        {/* TODO: add 404
          NOTE: when using Lab Wrapper everything falls inside,
            it requires some refactoring to get 404 routes */}
        <Lab>
          <Route path="/dashboard" name="Dashboard" component={Dashboard} />
          <Route path="/account" name="Account" component={Account} />
          <Route exact path="/users" name="Users" component={Users} />
          <Route exact path="/licenses" name="Licenses" component={Licenses} />
          <Route
            exact
            path="/debug-logs"
            name="DebugLogs"
            component={DebugLogs}
          />
          <Route
            exact
            path="/subscriptions"
            name="Subscriptions"
            component={Subscriptions}
          />
          <Route
            exact
            path="/login-records"
            name="Login Records"
            component={LoginRecords}
          />
          <Route exact path="/billing" name="Billing" component={Billing} />
        </Lab>
        <Route name="Page Not Found" component={NoMatch} />
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);
