import { connect } from "react-redux";
import VerifyEmail from "../views/pages/VerifyEmail";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return state.auth || {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
