import { connect } from "react-redux";
import Register from "../views/pages/Register";
import { actions } from "../modules/auth";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return state.auth || {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUser(payload) {
      dispatch(actions.create_user_request(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
