import { connect } from "react-redux";
import Login from "../views/pages/Login";
import { actions } from "../modules/auth";

import _ from "lodash";

const mapStateToProps = (state = {}) => {
  return state.auth || {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    login(payload) {
      dispatch(actions.login_request(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
