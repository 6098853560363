import { takeEvery, put, call } from "redux-saga/effects";
import { createAction, actionNames, createReducer } from "../tools";
import { actions as alerts } from "./alerts";
import { actions as configActions } from "./configuration";
import api from "../api";

export const actions = createAction(
  "cirrus/modules/account",
  ...actionNames("ME_INFO"),
  ...actionNames("UPDATE_ME_INFO"),
  ...actionNames("LIST_SUBSCRIPTIONS"),
  ...actionNames("LIST_LICENSES"),
  ...actionNames("SET_SUBSCRIPTION"),
  ...actionNames("ADD_CUSTOMER"),
  ...actionNames("LIST_CHARGES"),
  ...actionNames("CANCEL_SUBSCRIPTION"),
  "UPDATE_LICENSE"
);

function unlockButton(state) {
  return {
    ...state,
    lockButtons: false,
  };
}

function lockButton(state) {
  return {
    ...state,
    lockButtons: true,
  };
}

export default createReducer(
  {
    [actions.ME_INFO_SUCCESS]: (state, action) => {
      const { user, stripe_customer } = action.payload;
      return {
        ...state,
        ...user,
        stripe_customer,
      };
    },
    [actions.LIST_SUBSCRIPTIONS_SUCCESS]: (state, action) => {
      return {
        ...state,
        subscriptions_list: action.payload,
      };
    },
    [actions.LIST_LICENSES_SUCCESS]: (state, action) => {
      return {
        ...state,
        licenses: (action.payload && action.payload.data) || [],
      };
    },
    [actions.SET_SUBSCRIPTION_REQUEST]: lockButton,
    [actions.CANCEL_SUBSCRIPTION_REQUEST]: lockButton,

    [actions.CANCEL_SUBSCRIPTION_ERROR]: unlockButton,
    [actions.CANCEL_SUBSCRIPTION_SUCCESS]: unlockButton,
    [actions.SET_SUBSCRIPTION_ERROR]: unlockButton,
    [actions.SET_SUBSCRIPTION_SUCCESS]: unlockButton,
    [actions.LIST_CHARGES_SUCCESS]: (state, action) => {
      return {
        ...state,
        charges: action.payload || [],
      };
    },
    [actions.UPDATE_LICENSE]: (state, action) => {
      const { license } = action.payload;
      return {
        ...state,
        license: license || "",
      };
    },
  },
  { lockButtons: false }
);

function* list_subscriptions() {
  try {
    const data = yield call(api.payments.list_subscriptions);
    yield put(actions.list_subscriptions_success(data));
  } catch (e) {
    yield put(actions.list_subscriptions_error(e));
  }
}

function* request_me_info() {
  try {
    const data = yield call(api.users.me);
    yield put(actions.me_info_success(data));
  } catch (e) {
    yield put(actions.me_info_error(e));
  }
}

function* update_me_info({ payload }) {
  try {
    const data = yield call(api.users.update_me, payload);
    yield put(actions.me_info_success(data));
    yield put(alerts.success({ message: "Information updated" }));
  } catch (e) {
    yield put(alerts.error({ message: "Information not updated" }));
    yield put(actions.update_me_info_error(e));
  }
}

function* get_user_licenses() {
  try {
    const data = yield call(api.config.get_licenses_from_user);
    yield put(actions.list_licenses_success(data));
    yield put(configActions.get_licenses_success(data));
    //yield put(configActions.update_current_license(data.data))
  } catch (e) {
    yield put(actions.list_licenses_error(e));
  }
}

function* set_subscription({ payload }) {
  try {
    yield call(api.payments.set_subscription, payload);
    yield put(alerts.success({ message: "Your plan is updated!" }));
    yield put(actions.set_subscription_success());
    yield put(actions.list_charges_request());
    yield put(actions.list_licenses_request());
  } catch (e) {
    yield put(alerts.error({ message: "Unable to change plan" }));
    yield put(actions.set_subscription_error(e));
  }
}

function* cancel_subscription({ payload }) {
  try {
    yield call(api.payments.rm_subscription, payload);
    yield put(
      alerts.warning({ message: "Your subscription have been cancelled" })
    );
    yield put(actions.cancel_subscription_success());
    yield put(actions.list_licenses_request());
  } catch (e) {
    yield put(alerts.error({ message: "Unable to cancel your plan" }));
    yield put(actions.cancel_subscription_error(e));
  }
}

function* add_customer({ payload }) {
  try {
    yield call(api.payments.add_customer, payload);
    yield put(alerts.success({ message: "Card updated" }));
    yield put(actions.me_info_request());
  } catch (e) {
    yield put(alerts.error({ message: "Unable to Add card" }));
  }
}

function* list_charges() {
  try {
    const data = yield call(api.payments.charges);
    yield put(actions.list_charges_success(data));
  } catch (e) {
    yield put(actions.list_charges_error(e));
  }
}

export function* mainSaga() {
  yield takeEvery(actions.ME_INFO_REQUEST, request_me_info);
  yield takeEvery(actions.UPDATE_ME_INFO_REQUEST, update_me_info);
  yield takeEvery(actions.LIST_SUBSCRIPTIONS_REQUEST, list_subscriptions);
  yield takeEvery(actions.LIST_LICENSES_REQUEST, get_user_licenses);
  yield takeEvery(actions.SET_SUBSCRIPTION_REQUEST, set_subscription);
  yield takeEvery(actions.ADD_CUSTOMER_REQUEST, add_customer);
  yield takeEvery(actions.LIST_CHARGES_REQUEST, list_charges);
  yield takeEvery(actions.CANCEL_SUBSCRIPTION_REQUEST, cancel_subscription);
}
