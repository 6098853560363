import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

import {
  Button,
  ButtonGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
} from "reactstrap";
import { GithubPicker } from "react-color";

import Plot from "../containers/Plot";
import ControllerAndRamp from "../components/ControllerAndRamp";

function TitleAndTabSelector({
  onLogClick,
  isLogging,
  onToggleName,
  logName,
  toggleMeterSelector,
  dashboard,
  toggleAddComment,
}) {
  const style = { float: "right", marginRight: 10 };

  return (
    <Row noGutters className="dashboard-title">
      <Col xs="12" md="5">
        <h5 className="controller-name">{dashboard.name}</h5>
      </Col>
      <Col xs="12" md={{ size: 7 }}>
        <Button
          onClick={toggleMeterSelector}
          size="sm"
          style={{ float: "right" }}
        >
          <i className="icon-screen"></i>Dashboard Settings
        </Button>
      </Col>
    </Row>
  );
}

TitleAndTabSelector.propTypes = {
  device: PropTypes.object,
  activeTab: PropTypes.number,
  changePage: PropTypes.func,
  meter: PropTypes.object,
  isLogging: PropTypes.bool,
  onLogClick: PropTypes.func,
  onToggleName: PropTypes.func,
  logName: PropTypes.string,
  toggleMeterSelector: PropTypes.func,
  dashboard: PropTypes.object,
  toggleAddComment: PropTypes.func,
};

const COLOR_PALLETTE = [
  "#ff0000",
  "#FE642E",
  "#FF8000",
  "#D7DF01",
  "#80FF00",
  "#00FF00",
  "#00FF80",
  "#00FFFF",
  "#8A0808",
  "#8A2908",
  "#8A4B08",
  "#868A08",
  "#4B8A08",
  "#088A08",
  "#088A4B",
  "#088A85",
  "#0080FF",
  "#0000FF",
  "#8000FF",
  "#FF00FF",
  "#FF0080",
  "#FF0040",
  "#848484",
  "#6E6E6E",
  "#084B8A",
  "#08088A",
  "#4B088A",
  "#8A0886",
  "#8A084B",
  "#8A0829",
  "#1C1C1C",
  "#000000",
];

class Color extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleClick() {
    this.setState({
      open: !this.state.open,
    });
  }

  onChange(e) {
    this.props.onChange(e);
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    const { value, active } = this.props;
    const innactive = active ? null : <div className="innactive-color"></div>;
    const background = active ? value : "#AAA";
    const color = active ? value : "#AAA";

    if (!active) return null;

    return (
      <div className="Color">
        <div className="wrap-current-color" onClick={this.handleClick}>
          <div
            className="current-color"
            style={{ backgroundColor: background }}
          >
            {innactive}
          </div>
        </div>
        {this.state.open ? (
          <div className="wrap-picker">
            <div className="cover" onClick={this.handleClick}></div>
            <GithubPicker
              colors={COLOR_PALLETTE}
              width={213}
              onChange={this.onChange}
              color={color}
              triangle="hide"
            />
          </div>
        ) : null}
      </div>
    );
  }
}

Color.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  active: PropTypes.bool,
};

function ButtonDetails({ active, powerLimit, currentValue, setPoint, meter }) {
  if (!active) return null;

  let powerLimitEl = "";
  if (powerLimit && powerLimit.value !== 100) {
    powerLimitEl = (
      <span className="power-limit">
        <i className="icon-at-sign" /> {powerLimit.value}%
      </span>
    );
  }

  let activeRampEl = "";
  if (meter.runningRampName) {
    activeRampEl = (
      <span className="running-ramp" style={{ fontSize: "smaller" }}>
        {`[${meter.runningRampName} - Step ${meter.running_step + 1}]`}
      </span>
    );
  }

  return (
    <span className="button-details" style={{ paddingLeft: 10 }}>
      {currentValue} <i className="icon-arrow-right" />{" "}
      {setPoint && setPoint.value} {powerLimitEl}
      {activeRampEl}
    </span>
  );
}

ButtonDetails.propTypes = {
  active: PropTypes.bool,
  powerLimit: PropTypes.object,
  currentValue: PropTypes.number,
  setPoint: PropTypes.object,
  meter: PropTypes.object,
};

function MeterButtons({ meters, onChangeColor, toggleMeter, name, onResync }) {
  const buttons = meters.map((m) => {
    const serialId = m.device.serialId;
    const meterId = m.address;
    const key = `${serialId}-${meterId}`;
    const color = m.active ? "primary" : "secondary";
    const icon = m.device.syncing ? "icon-history" : "icon-sync";
    const syncColor = m.device.syncing ? "warning" : "info";
    const syncButton = (
      <Button
        className="no-text"
        color={syncColor}
        onClick={() => onResync({ serialId })}
        size="sm"
      >
        {" "}
        <i className={icon}></i>
      </Button>
    );
    const defaultName = `${m.address} (${m.device.serialId})`;
    const meterName = `${m.device.name || m.device.model} - ${
      m.name || defaultName
    } - ${m.units}`;
    return (
      <Row key={key} style={{ marginBottom: 7 }}>
        <Col md="8" style={{ paddingRight: 0 }}>
          <Button
            color={color}
            size="sm"
            block
            onClick={() => toggleMeter({ serialId, meterId, name })}
            style={{ whiteSpace: "wrap" }}
          >
            {meterName}
          </Button>
        </Col>
        <Col md="2" style={{ paddingRight: 0 }}>
          <Color
            active={m.active}
            onChange={(color) =>
              onChangeColor({ serialId, meterId, value: color.hex })
            }
            value={m.color}
          />
        </Col>
        <Col md="1">{syncButton}</Col>
      </Row>
    );
  });

  return <div>{buttons}</div>;
}

MeterButtons.propTypes = {
  meters: PropTypes.array,
  onChangeColor: PropTypes.func,
  toggleMeter: PropTypes.func,
  name: PropTypes.string,
  onResync: PropTypes.func,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const { loggingId, loggingLog, onAddDashboard, dashboard } = props;
    const logName = loggingId ? loggingLog.name : "Log";

    this.state = {
      activeTab: 1,
      editModal: false,
      isCommentOpen: false,
      logName,
      isMeterSelectorOpen: false,
    };

    this.renderRightPane = this.renderRightPane.bind(this);
    this.handleOnLogClick = this.handleOnLogClick.bind(this);
    this.onLogNameChange = this.onLogNameChange.bind(this);
    this.editLogName = this.editLogName.bind(this);
    this.toggleAddComment = this.toggleAddComment.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
    this.addComment = this.addComment.bind(this);
    this.toggleMeterSelector = this.toggleMeterSelector.bind(this);
    this.measureHeight = _.throttle(this.measureHeight.bind(this), 500);

    if (_.isUndefined(dashboard.name)) {
      onAddDashboard();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.loggingId && this.props.loggingId !== prevProps.loggingId) {
      this.setState({
        logName: this.props.loggingLog.name || this.state.logName,
      });
    }
  }

  componentDidMount() {
    const { devicesIds, onUpdateDevice, refreshDevices } = this.props;
    //refreshDevices()

    const devicesTimer = setInterval(() => {
      for (let serialId of devicesIds) {
        onUpdateDevice({ serialId });
      }
    }, 1500);

    this.measureHeight();
    this.setState({
      devicesTimer,
    });
    window.addEventListener("resize", this.measureHeight);
  }

  componentWillUnmount() {
    clearInterval(this.state.tickTimer);
    clearInterval(this.state.devicesTimer);
    this.measureHeight.cancel();
    window.removeEventListener("resize", this.measureHeight);
  }

  measureHeight() {
    const { isTrial } = this.props;
    const extra = isTrial ? 41 : 0;
    this.measureHeight.flush();
    const height = document.documentElement.clientHeight - 160 - extra;
    this.setState({
      height,
    });
  }

  onChangeNumGraphs(numGraphs) {
    const { onChangeNumGraphs, dashboard } = this.props;
    onChangeNumGraphs({
      numGraphs,
      key: dashboard.key,
    });
  }

  onLogNameChange(e) {
    this.setState({
      logName: e.target.value,
    });
  }

  editLogName() {
    this.setState({
      logName: "Log",
      editModal: !this.state.editModal,
    });
  }

  renderRightPane() {
    const { meters, toggleMeterCollapse } = this.props;
    const grouped = _.groupBy(meters || [], "readOnly");
    const readOnly = grouped["true"] || [];
    const others = grouped["false"] || [];
    const ordered = [...readOnly, ...others];

    const controllers = ordered.map((m) => {
      if (!m.active) return;
      return (
        <ControllerAndRamp
          key={m.key}
          meter={m}
          toggleCollapse={toggleMeterCollapse}
        />
      );
    });

    return (
      <Col className="live-controller-wrapper" style={{ position: "relative" }}>
        <div
          className="live-controllers"
          style={{
            position: "absolute",
            height: "calc(100% - 50px)",
            width: "calc(100% - 15px)",
            overflowY: "auto",
          }}
        >
          {controllers}
        </div>
      </Col>
    );
  }

  handleOnLogClick() {
    const { startLog, stopLog, meters, loggingId } = this.props;
    if (loggingId) {
      stopLog({ id: loggingId });
      return;
    }

    const toSend = (meters || [])
      .filter((m) => m.active)
      .map((m) => {
        return {
          meterId: m.address,
          serialId: m.device.serialId,
        };
      });

    if (!toSend.length) return;

    startLog({
      name: this.state.logName,
      meters: toSend,
    });

    this.setState({
      editModal: !this.state.editModal,
    });
  }

  addComment() {
    const { onAddComment, loggingId, meters } = this.props;
    const { comments } = this.state;
    const meter = _.find(meters, (m) => m.active);
    if (!comments) return;
    if (!meter) return;

    if (loggingId) {
      onAddComment({
        id: loggingId,
        comments: this.state.comments,
        serialId: meter.serialId,
        meterId: meter.address,
      });
    }

    this.setState({
      comments: "",
      isCommentOpen: false,
    });
  }

  toggleAddComment() {
    this.setState({
      isCommentOpen: !this.state.isCommentOpen,
    });
  }

  toggleMeterSelector() {
    this.setState({
      isMeterSelectorOpen: !this.state.isMeterSelectorOpen,
    });
  }

  onCommentChange(e) {
    this.setState({
      comments: e.target.value,
    });
  }

  renderPlots() {
    const sizes = {
      1: ["lg"],
      2: ["sm", "lg"],
      3: ["sm", "sm", "lg"],
      4: ["sm", "sm", "sm", "lg"],
    };

    const colSizes = {
      1: [12],
      2: [12, 12],
      3: [6, 6, 12],
      4: [4, 4, 4, 12],
    };

    const plotHeights = {
      1: [100],
      2: [20, 80],
      3: [20, 20, 80],
      4: [20, 20, 20, 80],
    };

    const { meters, dashboard, onUpdateConfig, onToggleGraphMeter } =
      this.props;
    const { height } = this.state;
    if (!dashboard.name) return null;

    const numGraphs = dashboard.graphsOrdering.length;
    const currentColSizes = colSizes[numGraphs];
    const currentSizes = sizes[numGraphs];
    const heights = plotHeights[numGraphs];

    const plots = _.map(dashboard.graphsOrdering, (key, i) => {
      const colSize = currentColSizes[i];
      const size = currentSizes[i];
      const graphConfig = dashboard[key];
      return (
        <Col key={key} md={{ size: colSize }}>
          <Plot
            height={(heights[i] / 100) * height}
            meters={meters}
            size={size}
            config={graphConfig}
            onUpdateConfig={onUpdateConfig}
            onToggleGraphMeter={onToggleGraphMeter}
            tick={this.props.tick}
          />
        </Col>
      );
    });

    const mainPlot = plots.pop();
    return (
      <Fragment>
        <Row>{plots}</Row>
        <Row>{mainPlot}</Row>
      </Fragment>
    );
  }

  render() {
    return null;
    const {
      meters,
      onChangeColor,
      toggleMeter,
      loggingId,
      name,
      dashboard,
      onResync,
    } = this.props;
    const numGraphs = _.get(dashboard, "graphsOrdering.length", 1);
    const isLogging = !!loggingId;

    return (
      <div className="animated fadeIn d-flex" id="dashboard">
        <Modal isOpen={this.state.editModal} toggle={this.editLogName}>
          <ModalHeader toggle={this.editLogName}>Log Name?</ModalHeader>
          <ModalBody>
            <Input
              type="text"
              value={this.state.logName}
              onChange={this.onLogNameChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleOnLogClick}>
              Start
            </Button>{" "}
            <Button color="secondary" onClick={this.editLogName}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.isCommentOpen} toggle={this.toggleAddComment}>
          <ModalHeader toggle={this.toggleAddComment}>
            Some comment?
          </ModalHeader>
          <ModalBody>
            <Input
              type="text"
              value={this.state.comments || ""}
              onChange={this.onCommentChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addComment}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleAddComment}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isMeterSelectorOpen}
          toggle={this.toggleMeterSelector}
        >
          <ModalHeader toggle={this.toggleMeterSelector}>
            Dashboard Settings
          </ModalHeader>
          <ModalBody>
            <h6>Number of graphs</h6>
            <ButtonGroup style={{ marginBottom: 25 }}>
              <Button
                color="success"
                onClick={() => this.onChangeNumGraphs(1)}
                active={numGraphs === 1}
              >
                1
              </Button>
              <Button
                color="success"
                onClick={() => this.onChangeNumGraphs(2)}
                active={numGraphs === 2}
              >
                2
              </Button>
              <Button
                color="success"
                onClick={() => this.onChangeNumGraphs(3)}
                active={numGraphs === 3}
              >
                3
              </Button>
              <Button
                color="success"
                onClick={() => this.onChangeNumGraphs(4)}
                active={numGraphs === 4}
              >
                4
              </Button>
            </ButtonGroup>
            <h6>Meters to Display</h6>
            <MeterButtons
              meters={meters}
              onChangeColor={onChangeColor}
              toggleMeter={toggleMeter}
              name={name}
              onResync={onResync}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleMeterSelector}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Col style={{ backgroundColor: "white", paddingTop: 10 }}>
          <TitleAndTabSelector
            activeTab={this.state.activeTab}
            onLogClick={this.handleOnLogClick}
            onToggleName={this.editLogName}
            logName={this.state.logName}
            isLogging={isLogging}
            toggleMeterSelector={this.toggleMeterSelector}
            dashboard={dashboard}
            toggleAddComment={this.toggleAddComment}
          />
          {this.renderPlots()}
        </Col>
        {this.renderRightPane()}
      </div>
    );
  }
}

Dashboard.propTypes = {
  meters: PropTypes.array,
  onSaveLogConfig: PropTypes.func,
  toggleMeter: PropTypes.func,
  toggleMeterCollapse: PropTypes.func,
  onChangeColor: PropTypes.func,
  onUpdateDevice: PropTypes.func,
  devicesIds: PropTypes.array,
  tick: PropTypes.func,
  startLog: PropTypes.func,
  stopLog: PropTypes.func,
  loggingId: PropTypes.number,
  loggingLog: PropTypes.object,
  onAddComment: PropTypes.func,
  name: PropTypes.string,
  dashboard: PropTypes.object,
  onChangeNumGraphs: PropTypes.func,
  onToggleGraphMeter: PropTypes.func,
  onUpdateConfig: PropTypes.func,
  onAddDashboard: PropTypes.func,
  onResync: PropTypes.func,
  refreshDevices: PropTypes.func,
  resyncBluetooth: PropTypes.func,
  bluetooth: PropTypes.object,
};

export default Dashboard;
