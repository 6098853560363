import { takeEvery, put, call, take } from "redux-saga/effects";
import _ from "lodash";

import { createAction, createReducer, actionNames } from "../tools";
import api, { setToken } from "../api";
// import * as allActions from '../actions'
import { push } from "./history";
import { actions as configActions } from "./configuration";

export const actions = createAction(
  "cirrus/modules/auth",
  ...actionNames("CREATE_USER"),
  ...actionNames("LOGIN"),
  ...actionNames("LOGOUT"),
  ...actionNames("RESET_PASSWORD"),
  ...actionNames("NEW_PASSWORD"),
  ...actionNames("CHANGE_PASSWORD"),
  ...actionNames("VERIFY_EMAIL"),
  "LOAD_TOKEN"
);

function errorReducer(state, action) {
  const { response } = action.payload;
  let message = "Some error ocurred, please try again later";
  const errors = _.get(response, "data.errors");
  if (errors) {
    message = _.reduce(
      errors,
      (r, m, name) => {
        r.push(`${name}: ${m}`);
        return r;
      },
      []
    ).join("; ");
  }

  return {
    ...state,
    errors: {
      message,
      messages: errors,
      response,
    },
    loading: false,
    emailAccepted: false,
    passwordChanged: false,
    verified: false,
  };
}

export default createReducer(
  {
    [actions.CREATE_USER_REQUEST]: () => {
      return {
        loading: true,
      };
    },
    [actions.CREATE_USER_SUCCESS]: (state) => {
      return {
        ...state,
        loading: false,
        errors: null,
        registered: true,
      };
    },
    [actions.CREATE_USER_ERROR]: errorReducer,
    [actions.LOGIN_ERROR]: errorReducer,
    [actions.LOGIN_REQUEST]: () => {
      return {
        loading: true,
      };
    },
    [actions.LOGIN_SUCCESS]: (state, action) => {
      const { email, token, is_super } = action.payload;
      return {
        ...state,
        loading: false,
        errors: null,
        user: { email, token, is_super },
      };
    },
    [actions.LOGOUT_REQUEST]: (state) => {
      return {
        ...state,
        user: undefined,
      };
    },
    [actions.RESET_PASSWORD_ERROR]: errorReducer,
    [actions.RESET_PASSWORD_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        errors: null,
        emailAccepted: true,
      };
    },

    [actions.NEW_PASSWORD_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        errors: null,
        tokenAccepted: true,
        token: action.payload.token,
      };
    },
    [actions.CHANGE_PASSWORD_ERROR]: errorReducer,
    [actions.CHANGE_PASSWORD_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        errors: null,
        passwordChanged: true,
      };
    },

    [actions.VERIFY_EMAIL_REQUEST]: () => {
      return {
        loading: true,
      };
    },

    [actions.VERIFY_EMAIL_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        verified: true,
      };
    },
    [actions.VERIFY_EMAIL_ERROR]: (state, action) => {
      return {
        ...state,
        loading: false,
        verified: false,
      };
    },
  },
  {
    loading: false,
    registered: false,
    tokenAccepted: false,
    emailAccepted: false,
    passwordChanged: false,
    token: "",
    verified: false,
  }
);

function* create_user({ payload }) {
  try {
    const { key } = payload;
    let data;
    if (!key) {
      data = yield call(api.auth.create_user, payload);
    } else {
      data = yield call(api.auth.create_user_with_license, payload);
    }
    yield put(actions.create_user_success(data));
    // yield put(push('/login'))
  } catch (e) {
    yield put(actions.create_user_error(e));
  }
}

function* login({ payload }) {
  const { email } = payload;
  try {
    const { token, is_super } = yield call(api.auth.login, payload);
    const auth = { token, email, is_super };
    localStorage.setItem("auth", JSON.stringify(auth));
    yield put(actions.load_token(auth));
    // #if taget==client
    // yield put(allActions.config.change({name: 'token', section:'hidden', value: token}))
    // #endif
    yield put(push("/"));
  } catch (e) {
    yield put(actions.login_error(e));
  }
}

function* logout({ payload }) {
  localStorage.removeItem("auth");
  const token = payload;
  if (!token) return;
  try {
    // # if target=client
    // yield put(allActions.config.change({name: 'token', section:'hidden', value: ''}))
    // # endif
    yield call(api.auth.logout, token);
  } catch (e) {
    console.log("Error while loging out", e);
  }
}

function* reset_password({ payload }) {
  const { email } = payload;
  try {
    yield call(api.auth.reset_password, email);
    yield put(actions.reset_password_success());
  } catch (e) {
    console.log("Error while resetting password", e);
    yield put(actions.reset_password_error());
  }
}

function* new_password({ payload }) {
  const { token } = payload;
  try {
    const data = yield call(api.auth.check_token, token);
    yield put(actions.new_password_success({ token }));
  } catch (e) {
    console.log("Error getting reset password token", e);
    yield put(actions.new_password_error());
  }
}

function* change_password({ payload }) {
  try {
    const data = yield call(api.auth.change_password, payload);
    yield put(actions.change_password_success(data));
  } catch (e) {
    console.log("Error while setting new password", e);
    yield put(actions.change_password_error(e));
  }
}

function* loadToken({ payload }) {
  let data = payload;
  if (!data) {
    data = localStorage.getItem("auth");
    if (!data) return;
    data = JSON.parse(data);
  }

  const { token } = data;
  setToken(token);
  yield put(configActions.request());
  //yield take(configActions.REQUEST_SUCCESS)
  yield put(actions.login_success(data));
}

function* verify_email({ payload }) {
  const { token } = payload;
  try {
    const data = yield call(api.auth.verify_email, token);
    yield put(actions.verify_email_success(data));
  } catch (e) {
    console.log("Error while verifying email", e);
    yield put(actions.verify_email_error());
  }
}

export const routes = {
  *"/new-password/:token"({ token }) {
    yield put(actions.new_password_request({ token }));
  },
  *"/verify-email/:token"({ token }) {
    yield put(actions.verify_email_request({ token }));
  },
};

export function* mainSaga() {
  yield takeEvery(actions.CREATE_USER_REQUEST, create_user);
  yield takeEvery(actions.LOGIN_REQUEST, login);
  yield takeEvery(actions.LOGOUT_REQUEST, logout);
  yield takeEvery(actions.LOAD_TOKEN, loadToken);
  yield takeEvery(actions.RESET_PASSWORD_REQUEST, reset_password);
  yield takeEvery(actions.NEW_PASSWORD_REQUEST, new_password);
  yield takeEvery(actions.CHANGE_PASSWORD_REQUEST, change_password);
  yield takeEvery(actions.VERIFY_EMAIL_REQUEST, verify_email);
  yield put(actions.load_token());
}
