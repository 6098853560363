import { takeEvery, put, call } from "redux-saga/effects";
import { createAction, createReducer, actionNames } from "../tools";
import api from "../api";
import _ from "lodash";

import { actions as dailyRecordActions } from "./dailyRecords";

export const actions = createAction(
  "cirrus/modules/login-records",
  ...actionNames("LIST"),
  ...actionNames("COUNT"),
  ...actionNames("DELETE"),
  "SET_CURRENT_LOGIN_RECORD"
);

const defaultState = {
  list: [],
  params: {
    page: 1,
    per_page: 50,
  },
  current: null,
};

export default createReducer(
  {
    [actions.LIST_SUCCESS]: (state, action) => {
      const { pagination, data } = action.payload;
      return {
        ...state,
        list: data,
        pagination,
      };
    },
    [actions.COUNT_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        totalLoginRecords: payload,
      };
    },
    [actions.DELETE_SUCCESS]: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        current: { ...payload, open: false },
      };
    },
    [actions.SET_CURRENT_LOGIN_RECORD]: (state, action) => {
      const index = action.payload;
      let current = state.list[index] || null;
      if (current) {
        current = { ...current, open: true };
      }
      return {
        ...state,
        current,
      };
    },
  },
  defaultState
);

function* list_login_records({ payload }) {
  try {
    const data = yield call(api.loginRecords.all, payload);
    yield put(actions.count_request());
    yield put(dailyRecordActions.list_request());
    yield put(actions.list_success(data));
  } catch (e) {
    yield put(actions.list_error(e));
  }
}

function* count() {
  try {
    const data = yield call(api.loginRecords.count);
    yield put(actions.count_success(data));
  } catch (e) {
    yield put(actions.count_error(e));
  }
}

function* delete_login_record({ payload }) {
  try {
    const { data } = yield call(api.loginRecords.delete, payload);
    yield put(actions.list_request());
    yield put(actions.delete_success(data));
  } catch (e) {
    yield put(actions.delete_error(e));
  }
}

export const routes = {
  *"/login-records"() {
    yield put(actions.list_request());
    yield put(dailyRecordActions.get_last_30_days_request());
  },
};

export function* mainSaga() {
  yield takeEvery(actions.LIST_REQUEST, list_login_records);
  yield takeEvery(actions.COUNT_REQUEST, count);
  yield takeEvery(actions.DELETE_REQUEST, delete_login_record);
}
