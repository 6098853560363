import { createAction } from "./tools";
export const ERROR = "ERROR";
export const CREATE_DEVICE_REQUEST = "CREATE_DEVICE_REQUEST";
export const UPDATE_DEVICE_REQUEST = "UPDATE_DEVICE_REQUEST";
export const DEVICES_REQUEST = "DEVICES_REQUEST";
export const DEVICES_SUCCESS = "DEVICES_SUCCESS";
export const UPDATE_DEVICE_VALUE = "UPDATE_DEVICE_VALUE";
export const SINGLE_DEVICE_SUCCESS = "SINGLE_DEVICE_SUCCESS";

export const DELETE_DEVICE_REQUEST = "DELETE_DEVICE_REQUEST";

export const TEMPERATURE_REQUEST = "TEMPERATURE_REQUEST";
export const TEMPERATURE_SUCCESS = "TEMPERATURE_SUCCESS";

export const SETPOINT_REQUEST = "SETPOINT_REQUEST";
export const SETPOINT_SUCCESS = "SETPOINT_SUCCESS";
export const SET_SETPOINT_REQUEST = "SET_SETPOINT_REQUEST";
export const SET_SETPOINT_SUCCESS = "SET_SETPOINT_SUCCESS";

export const config = createAction(
  "CONFIG",
  "REQUEST",
  "REQUEST_SUCCESS",
  "CHANGE",
  "CHANGE_SUCCESS",
  "CHANGE_JSON"
);

export const ramps = createAction(
  "RAMPS",
  "LIST_REQUEST",
  "LIST_SUCCESS",
  "LIST_ERROR",
  "SINGLE_REQUEST",
  "SINGLE_SUCCESS",
  "SINGLE_ERROR",
  "EDIT_ID",
  "SAVE_REQUEST",
  "UPDATE_REQUEST",
  "DELETE_REQUEST",
  "DELETE_SUCCESS",
  "CHANGE_VALUE",
  "NEW"
);

export const rampSteps = createAction(
  "RAMP_STEPS",
  "ADD",
  "REMOVE",
  "CHANGE_VALUE"
);

export const devices = createAction(
  "DEVICES",
  "LIST_REQUEST",
  "LIST_SUCCESS",
  "SINGLE_REQUEST",
  "SINGLE_SUCCESS",
  "TEMPERATURE_REQUEST",
  "TEMPERATURE_SUCCESS",
  "TEMPERATURE_ERROR",
  "ACTIVE_DEVICE",
  "START_RAMP_REQUEST",
  "START_RAMP_SUCCESS",
  "CHANGE_RAMP_STATUS_REQUEST",
  "CHANGE_RAMP_STATUS_SUCCESS"
);

function changeStatusRamp(status) {
  return function (payload) {
    return devices.change_ramp_status_request({ ...payload, status });
  };
}

devices.continue_ramp_request = changeStatusRamp(0);
devices.pause_ramp_request = changeStatusRamp(1);
devices.next_step_ramp_request = changeStatusRamp(2);
devices.last_step_ramp_request = changeStatusRamp(3);
devices.stop_ramp_request = changeStatusRamp(4);

export const logs = createAction(
  "LOGS",
  "LIST_REQUEST",
  "LIST_SUCCESS",
  "SINGLE_REQUEST",
  "SINGLE_SUCCESS",
  "DELETE_REQUEST",
  "DELETE_SUCCESS",
  "START_REQUEST",
  "START_SUCCESS",
  "STOP_REQUEST",
  "STOP_SUCCESS"
);

export function error(payload) {
  return { type: ERROR, payload };
}

export function createDevice({ serialId, name = "", setupName = "" }) {
  return { type: CREATE_DEVICE_REQUEST, serialId, name, setupName };
}

export function updateDevice({ serialId, name = "", setupName = "" }) {
  return { type: UPDATE_DEVICE_REQUEST, serialId, name, setupName };
}

export function updateDeviceValue({ serialId, value, name }) {
  return { type: UPDATE_DEVICE_VALUE, serialId, value, name };
}

export function deleteDevice(serialId) {
  return { type: DELETE_DEVICE_REQUEST, serialId };
}

export function requestSetPoint({ serialId, meterId }) {
  return { type: SETPOINT_REQUEST, serialId, meterId };
}

export function loadSetPoint({ serialId, meterId, value }) {
  return { type: SETPOINT_SUCCESS, serialId, meterId, value };
}

export function setSetPoint({ serialId, meterId, value }) {
  return { type: SET_SETPOINT_REQUEST, serialId, meterId, value };
}

export function setSetPointSuccess({ serialId, meterId, value }) {
  return { type: SET_SETPOINT_SUCCESS, serialId, meterId, value };
}

export function requestDevices() {
  return { type: DEVICES_REQUEST };
}

export function loadDevices(payload) {
  return { type: DEVICES_SUCCESS, payload };
}

export function requestTemperature({ serialId, meterId }) {
  return {
    type: TEMPERATURE_REQUEST,
    serialId,
    meterId,
  };
}

export function storeTemperature({ serialId, meterId, value }) {
  return {
    type: TEMPERATURE_SUCCESS,
    serialId,
    meterId,
    value,
  };
}
